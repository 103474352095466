import React from 'react'
import PropTypes from 'prop-types'
import { ICON_NAMES } from '../../../../../../constants'
import SydButton from '../../../../../commonDesign/Button'

const EditTagButtonCell = ({ onClick, row }) => {
  return (
    <SydButton
      size='sm'
      onClick={() => onClick(row.original)}
      icon={ICON_NAMES.edit}
    >
      Edit
    </SydButton>
  )
}

EditTagButtonCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  row: {
    original: PropTypes.object
  }
}

export default EditTagButtonCell
