import React, { useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { useUpdateWealthOwnerMutation } from '../../../../../api/users'
import SectionScreen from '../SectionScreen'
import { useWealthOwnerDetails, useSectionEditing } from '../ClientDetailsFormContext'
import EditButton from '../EditButton'
import SectionHeader from '../../shared/SectionHeader'
import PersonalSpace from '../../shared/PersonalSpace'
import WealthOwnerInfoForm from './WealthOwnerInfoForm'
import WealthOwnerInfoDisplay from './WealthOwnerInfoDisplay'

const useStyles = makeStyles((theme) => ({
  userInfoSection: {
    position: 'relative',
    padding: '10px'
  }
}))

function WealthOwnerInfoSection () {
  const classes = useStyles()
  const { wealthOwner, editSection, refetchWealthOwner } = useWealthOwnerDetails()
  const editing = useSectionEditing('user_info')
  const formRef = useRef()
  const { mutateAsync: saveWealthOwner } = useUpdateWealthOwnerMutation()
  const onSave = useCallback(async (e) => {
    const onValid = async (form) => {
      try {
        await saveWealthOwner({
          user: {
            userId: wealthOwner.userId,
            externalUserId: wealthOwner.externalUserId,
            firstName: form.firstName || undefined,
            lastName: form.lastName || undefined
          }
        })
        await refetchWealthOwner()
      } catch (err) {
        console.error('error trying to save a wealth owner', err)
        alert('There was an unexpected problem saving the request.')
        throw err
      }
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [formRef, wealthOwner, saveWealthOwner, refetchWealthOwner])

  return (
    <SectionScreen sectionName='user_info' className={classes.userInfoSection}>
      <SectionHeader text='Wealth Owner User Information'>
        <div>
          <EditButton
            policy='admin_edit_wo_users'
            editing={editing}
            onClick={() => editSection({
              section: 'user_info',
              saveDescription: 'Save User Information',
              onSave
            })}
          />
        </div>
      </SectionHeader>
      {editing ? (
        <WealthOwnerInfoForm ref={formRef} wealthOwner={wealthOwner} />
      ) : (
        <WealthOwnerInfoDisplay wealthOwner={wealthOwner} />
      )}
      <PersonalSpace />
    </SectionScreen>
  )
}

export default WealthOwnerInfoSection
