import { useEffect, useMemo, useState } from 'react'
import { isEmpty, isObject } from 'lodash'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { DATE_RANGE_CUSTOM_OPTION_KEYS, datePresetOptions, generateCustomOption, parseCalendarYearDateRangeRawValue, parseCustomDateRangeRawValue } from '../../molecules/RelativeDateSelect'
import PerformanceCellPicker, { CELL_TYPES } from './PerformancePresentationTable/Cells'
import { createColumnMapper, mapFormatString, mapHeaderAccessor, mapSortFunction, mapTitle } from './columnUtility'

const defaultColumnConfig = {
  columns: [
    {
      id: 'title',
      header: '',
      align: 'left',
      columns: [
        { id: 'levelName', accessor: 'levelName', header: '', format: 'title', minWidth: 400, benchmark: 'balance.benchmarkLongName' }
      ]
    },
    {
      id: 'Balance',
      header: 'Balance',
      align: 'center',
      columns: [
        { id: 'endingValue', accessor: 'balance.endingValue', header: 'Value', format: 'human', align: 'right' },
        { id: 'allocation', accessor: 'allocation', header: '%', format: 'allocation', align: 'right' }
      ]
    },
    {
      id: 'QTD',
      header: 'Quarter To Date',
      columns: [
        { id: 'QTDNetGain', accessor: 'QTD.netChange', header: 'Net Gain', format: 'marketValue', align: 'right' },
        { id: 'QTDReturn', accessor: 'QTD.annualizedReturnNOF', header: 'Return', format: 'returns', align: 'right', benchmark: 'QTD.annualizedBenchmark' }
      ]
    }],
  defaultSort: [
    { id: 'levelName', desc: false }
  ]
}

export const findFirstDateRangeColumnOccurrenceIndex = (
  columns,
  dateRangeOptions = datePresetOptions
) => {
  const dateRangeKeys = dateRangeOptions.map(({ key }) => key)
  const firstPerfColumnOccurrenceIndex = columns.findIndex(({ id }) =>
    dateRangeKeys.find((dateRangeKey) => id?.startsWith(dateRangeKey))
  )
  return firstPerfColumnOccurrenceIndex
}

const replaceColumnDateRangeAccessor =
  (dateRangeAccessor, dateRangeKey) => (column) => {
    return Object.entries(column).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          typeof value === 'string' && value !== dateRangeKey
            ? value.replace(new RegExp(dateRangeAccessor), dateRangeKey)
            : value
      }),
      {}
    )
  }

export const findAndReplacePerformanceAccessors = (columnConfig, dateRanges, dateRangeOptions) => {
  const columns = [...columnConfig.columns]
  const startDateRangeColIndex = findFirstDateRangeColumnOccurrenceIndex(columns)
  if (startDateRangeColIndex === -1) return columnConfig

  const dateRangeColumns = columns.slice(startDateRangeColIndex)

  const transformedDateRangeColumns = dateRangeColumns.map((dateRangeColumn, index) => {
    const dateRangeAccessor = dateRangeColumn.id
    let dateRangeKey = dateRanges[index]
    let dateRangeOption = dateRangeOptions.find(
      ({ key, isCustom }) =>
        key === dateRangeKey || (isCustom && dateRangeKey?.startsWith(key))
    )
    if (!dateRangeOption) return dateRangeColumn

    if (dateRangeOption.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM) {
      const { key, index: customKeyIndex, startDate, endDate } = parseCustomDateRangeRawValue(dateRangeKey)
      dateRangeKey = `${key}_${customKeyIndex}`
      dateRangeOption = generateCustomOption(dateRangeOption, startDate, endDate)
    }

    if (dateRangeOption.value === DATE_RANGE_CUSTOM_OPTION_KEYS.CALENDAR_YEAR) {
      const { key, index: calendarYearKeyIndex } = parseCalendarYearDateRangeRawValue(dateRangeKey)
      dateRangeKey = `${key}_${calendarYearKeyIndex}`
      dateRangeOption = { ...dateRangeOption, key: dateRangeKey }
    }

    const replaceDateAccessor = replaceColumnDateRangeAccessor(dateRangeAccessor, dateRangeKey)
    const columnsTransformed = replaceDateAccessor({
      ...dateRangeColumn,
      id: dateRangeKey,
      header: dateRangeOption.label,
      Header: typeof dateRangeColumn.Header === 'string'
        ? dateRangeOption.label
        : dateRangeColumn.Header,
      columns: dateRangeColumn.columns?.map(replaceDateAccessor)
    })
    return columnsTransformed
  })

  return {
    ...columnConfig,
    columns: [
      ...columns.splice(0, startDateRangeColIndex),
      ...transformedDateRangeColumns
    ]
  }
}

function createAccessor (accessor) {
  if (typeof accessor === 'function') {
    return accessor
  }
  if (accessor?.includes('.')) {
    return (row) => accessor.split('.').reduce((p, c) => p ? p[c] : p, row)
  }
  return accessor
}

function createBenchmarkAccessor (accessor, options) {
  if (accessor?.includes('.')) {
    return (row, column) => {
      const value = accessor.split('.').reduce((p, c) => {
        if (!p) return p
        if (p instanceof Array) {
          return p
            .sort((a, b) => (a?.benchmarkOrdinal < b?.benchmarkOrdinal ? -1 : 1))
            .map((i) => i[c])
        }
        return p[c]
      }, row)
      if (!value) return ''
      if (value instanceof Array) {
        return value.map((val) =>
          (options.formatter ?? identity)(val, column?.format)
        )
      }
      return (options.formatter ?? identity)(value, column?.format)
    }
  }
  if (!accessor) {
    return () => ''
  }
  return (row, column) => {
    const value = row[accessor]
    if (!value) return ''
    return (options.formatter ?? identity)(value, column?.format)
  }
}

const mapHeader = ({ component, tooltipTitle, ...column }) => {
  return {
    ...column,
    ...(tooltipTitle
      ? { Header: PerformanceCellPicker, cellType: 'tooltipTitle', tooltipTitle }
      : {}),
    ...(component && Object.keys(CELL_TYPES).includes(component)
      ? { Header: PerformanceCellPicker, cellType: component, tooltipTitle }
      : {})
  }
}

const identity = x => x

const getCellComponentType = (cellComponent) => {
  if (isObject(cellComponent)) {
    const { type, ...payload } = cellComponent
    if (type && Object.keys(CELL_TYPES).includes(type)) {
      return { type, payload }
    }
  }
  return { type: cellComponent, payload: {} }
}

const mapCellAccessor = ({ cellComponent, cellTooltipTitle, ...column }, options) => {
  let cellProps = {}
  if (cellTooltipTitle) {
    cellProps = {
      Cell: PerformanceCellPicker,
      cellType: 'baseCell',
      cellTooltipTitle
    }
  } else if (cellComponent && Object.keys(CELL_TYPES).includes(cellComponent?.type || cellComponent)) {
    const component = getCellComponentType(cellComponent)
    cellProps = {
      Cell: PerformanceCellPicker,
      cellType: component?.type,
      payload: component?.payload,
      formatter: options?.formatter ?? identity
    }
  } else {
    cellProps = {
      Cell: ({ value, column }) => {
        const formatter = options?.formatter ?? identity
        return formatter(value, column.format)
      }
    }
  }
  return {
    ...column,
    accessor: createAccessor(column.accessor, options),
    ...cellProps
  }
}

const mapBenchmarkAccessor = (column, options) => ({
  ...column,
  benchmark: createBenchmarkAccessor(column.benchmark, options)
})

const mapResolvedDates = (column, options) => ({
  ...column,
  resolvedDates: options.normalizedDateRanges
})

const mappingOps = [
  mapTitle,
  mapFormatString,
  mapHeaderAccessor,
  mapHeader,
  mapCellAccessor,
  mapBenchmarkAccessor,
  mapSortFunction,
  mapResolvedDates
]

const mapColumn = createColumnMapper(mappingOps)

const replaceColumnAccessors = (columnConfig, substitutionOptions) => {
  const accessorMap = substitutionOptions.reduce(
    (acc, { defaultValue, lastSelectedValue, selectedValue }) => {
      if (!selectedValue) return acc
      return {
        ...acc,
        [lastSelectedValue || defaultValue]: selectedValue
      }
    },
    {}
  )
  if (isEmpty(accessorMap)) {
    return columnConfig
  }
  const replaceAccessors = (columns) => {
    return columns.map((column) => {
      if (column.columns) {
        return {
          ...column,
          columns: replaceAccessors(column.columns)
        }
      }
      const { accessor } = column
      if (!accessor) return column

      const accessorKeys = Object.keys(accessorMap)
      const currAccessor = accessorKeys.find((key) => accessor.includes(key))
      if (!currAccessor) return column

      const newAccessor = accessor.split('.').reduce((acc, curr) => {
        const prefix = acc ? acc + '.' : ''

        const replacedAccessor = curr === currAccessor
          ? accessorMap[currAccessor]
          : curr

        return `${prefix}${replacedAccessor}`
      }, '')

      return {
        ...column,
        accessor: newAccessor || column.accessor
      }
    })
  }
  return {
    ...columnConfig,
    columns: replaceAccessors(columnConfig.columns)
  }
}

const usePerformanceColumns = ({
  columnConfig: _columnConfig = defaultColumnConfig,
  title,
  groupingDateRanges,
  setPerformanceDateRanges,
  performanceDateRangeOptions,
  normalizedDateRanges,
  groupingColumnConfigurations
}) => {
  const [columnConfig, setColumnConfig] = useState(_columnConfig)

  useEffect(() => {
    setColumnConfig(_columnConfig)
  }, [_columnConfig])

  useEffect(() => {
    if (!isEmpty(groupingDateRanges)) {
      setColumnConfig((prevColumnConfig) => {
        let columnConfigTransformed = findAndReplacePerformanceAccessors(
          prevColumnConfig,
          groupingDateRanges,
          datePresetOptions
        )
        if (groupingColumnConfigurations.enabled) {
          columnConfigTransformed = replaceColumnAccessors(
            columnConfigTransformed,
            groupingColumnConfigurations.substitutionOptions
          )
        }
        return columnConfigTransformed
      })
      setPerformanceDateRanges([...groupingDateRanges])
    }
  }, [
    groupingDateRanges,
    setPerformanceDateRanges,
    performanceDateRangeOptions,
    groupingColumnConfigurations
  ])

  const { formatter } = useFormattingContext()
  const { columns, defaultSort } = useMemo(() => {
    const { columns, defaultSort } = columnConfig
    const mapped = columns.map((c, index) => mapColumn(c, {
      title,
      formatter,
      normalizedDateRanges
    }, index))

    return {
      columns: mapped,
      defaultSort
    }
  }, [columnConfig, title, formatter, normalizedDateRanges])

  return {
    columns,
    defaultSort
  }
}

export default usePerformanceColumns
