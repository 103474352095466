import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import AbundanceEngineContextProvider from '../../../abundanceEngine/components/AbundanceParameterContextProvider'
import AbundanceParser from '../../../abundanceEngine/AbundanceParser'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%'
  }
}))

function TearSheetView ({ jsx, bindings }) {
  const classes = useStyles()

  return (
    <AbundanceEngineContextProvider additionalParams={bindings}>
      <AbundanceParser
        jsx={jsx}
        bindings={bindings}
        className={classes.root}
      />
    </AbundanceEngineContextProvider>
  )
}

TearSheetView.propTypes = {
  jsx: PropTypes.string,
  bindings: PropTypes.object
}

export default TearSheetView
