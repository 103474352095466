import React from 'react'
import ErrorBoundary from '../../../../../molecules/ErrorBoundary'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import KdpSection from './KdpSection'

function KdpTool () {
  return (
    <ErrorBoundary name='Asset KDP Tab'>
      <FadeIn>
        <Card>
          <KdpSection />
        </Card>
      </FadeIn>
    </ErrorBoundary>
  )
}

KdpTool.propTypes = {
}

export default KdpTool
