import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getMedia, postNamedCommand, postNamedQuery, saveMedia, uploadDocument } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useMediaUrl = (mediaId, options = {}) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: options?.enabled ?? true,
    queryKey: [QUERY_KEYS.getMedia, userId, mediaId, options?.publicFallback],
    queryFn: async () => {
      if (!mediaId) return options?.publicFallback ?? null

      try {
        const { data } = await getMedia(mediaId)
        return data?.downloadUrl ?? options?.publicFallback ?? null
      } catch (err) {
        if (options?.publicFallback) {
          return options.publicFallback
        } else {
          throw err
        }
      }
    }
  })
}

export const useListImages = (query) => {
  return useQuery({
    enabled: !!query,
    queryKey: ['media/list-images', JSON.stringify(query || {})],
    keepPreviousData: true,
    queryFn: async () => {
      const { data } = await postNamedQuery('media', 'list-images', query)

      return data
    }
  })
}

export const useGetImageById = (imageId) => {
  return useQuery({
    enabled: !!imageId,
    queryKey: ['media/get-image-by-id', imageId],
    queryFn: async () => {
      const { data } = await postNamedQuery('media', 'get-image-by-id', {
        imageId
      })

      return data
    }
  })
}

export const useSaveMediaMutation = () => {
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await saveMedia(command)

      return data
    }
  })
}

export const useRenameImage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('media', 'rename-image', command)

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['media/list-images'], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['media/get-image-by-id', data.image.imageId], exact: true }).catch(console.error)
    }
  })
}

export const useRemoveImage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('media', 'remove-image', command)

      return data
    },
    onSuccess: (_, command) => {
      queryClient.invalidateQueries({ queryKey: ['media/list-images'], refetchType: 'all' }).catch(console.error)
      queryClient.invalidateQueries({ queryKey: ['media/get-image-by-id', command.imageId], exact: true }).catch(console.error)
    }
  })
}

export const useListImagePurposes = () => {
  return useQuery({
    queryKey: ['media/list-image-purposes'],
    keepPreviousData: true,
    queryFn: async () => {
      const { data } = await postNamedQuery('media', 'list-image-purposes', {})

      return data
    }
  })
}

export const useGetImagesByLevel = (levelTypeId, levelId) => {
  return useQuery({
    enabled: !!levelTypeId && !!levelId,
    queryKey: ['media/list-images-by-level', levelTypeId, levelId],
    queryFn: async () => {
      const { data } = await postNamedQuery('media', 'get-images-by-level', {
        levelTypeId,
        levelId
      })

      return data
    }
  })
}

export const useAssociateImage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('media', 'associate-image', command)

      return data
    },
    onSuccess: (_, command) => {
      queryClient.invalidateQueries({ queryKey: ['media/list-images-by-level', command.levelTypeId, command.levelId], exact: true }).catch(console.error)
    }
  })
}

export const useRemoveImageAssociation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('media', 'remove-image-association', command)

      return data
    },
    onSuccess: (_, command) => {
      queryClient.invalidateQueries({ queryKey: ['media/list-images-by-level', command.levelTypeId, command.levelId], exact: true }).catch(console.error)
    }
  })
}

const sleep = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout))
export const useImageUpload = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (command) => {
      const { data } = await postNamedCommand('media', 'create-image-upload', {
        fileName: command.fileName,
        displayName: command.displayName
      })

      const xhr = new XMLHttpRequest()
      await uploadDocument(data.uploadUrl, command.file, (event) => {
        if (event.total === event.loaded) {
          console.log(event)
        }
      }, () => {
        console.log('aborted')
      }, xhr)

      const { data: confirmResult } = await postNamedCommand('media', 'confirm-image-upload', {
        imageId: data.image.imageId
      })

      if (options?.waitForProcessingComplete) {
        try {
          for (let i = 0; i < 3; ++i) {
            await sleep(500 + ((i + 1) * 0.5))
            const poll = await postNamedQuery('media', 'get-image-by-id', {
              imageId: confirmResult.image.imageId
            })
            if (poll.data?.image?.lastProcesedDate) {
              break
            }
          }
        } catch (err) {
          console.error(err)
        }
      }

      return confirmResult
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['media/list-images'], refetchType: 'all' }).catch(console.error)
    }
  })
}
