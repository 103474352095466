import React, { useState } from 'react'
import SydInput from '../../../commonDesign/SydInput'
import { useVaultContext } from '../VaultContextProvider'
import { useDebouncedCallback } from '../../../../hooks'

const SearchFilter = () => {
  const { setFilters, filters } = useVaultContext()
  const [searchString, setSearchString] = useState('')

  const debouncedContextUpdate = useDebouncedCallback((string) => {
    setFilters({ ...filters, textSearch: string })
  })

  return (
    <>
      <SydInput
        size='sm'
        label='Search'
        placeholder='Search'
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value)
          debouncedContextUpdate(e.target.value)
        }}
      />
    </>
  )
}
export default SearchFilter
