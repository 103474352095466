import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import ErrorBoundary from '../../../molecules/ErrorBoundary'
import FormattingProvider from '../../../organisms/FormattingProvider'
import { useHideSideMenuEffect } from '../../../../redux/slices/appContext'
import NotAvailable from '../../../organisms/NotAvailable'
import { useIsMediaManagementActive } from '../../../../hooks/featureChecks'
import IndexView from './IndexView'

const MediaIndex = () => {
  const { path } = useRouteMatch()
  useHideSideMenuEffect([])
  const isDataHomeActive = useIsMediaManagementActive()

  if (!isDataHomeActive) {
    return <NotAvailable />
  }

  return (
    <ErrorBoundary name='Media Admin'>
      <FormattingProvider>
        <Switch>
          <SecureRoute path={path} exact>
            <IndexView root={path} />
          </SecureRoute>
        </Switch>
      </FormattingProvider>
    </ErrorBoundary>
  )
}

export default MediaIndex
