import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import SydButton from '../../../../commonDesign/Button'
import SydModalActions from '../../../../commonDesign/SydModal/SydModalActions'
import { useRemoveImage } from '../../../../../api/media'

const useSubmitter = (onComplete) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: removeImage } = useRemoveImage()
  const onSubmit = useCallback(async (image) => {
    const command = {
      imageId: image.imageId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await removeImage(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to remove image')
      }
      onComplete?.(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [removeImage, setProcessing, onComplete, setError])

  return {
    submitter: onSubmit,
    processing,
    error
  }
}

function DeleteImageForm ({ image, onCancel, onComplete }) {
  const { submitter, processing } = useSubmitter(onComplete)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h3'>Are you sure you want to delete this image?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2'>{image.displayName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h4'><em>This action cannot be undone.</em></Typography>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            priority='warning'
            onClick={() => submitter(image)}
          >
            Delete
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

DeleteImageForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  image: PropTypes.shape({
    imageId: PropTypes.string,
    displayName: PropTypes.string
  })
}

export default DeleteImageForm
