import React from 'react'
import PropTypes from 'prop-types'
import ClientLevelPicker from './ClientLevelPicker'
import AccountLevelPicker from './AccountLevelPicker'
import AssetLevelPicker from './AssetLevelPicker'

function LevelPickers ({ levelTypeId, selectedLevel, onLevelSelected }) {
  if (levelTypeId === 1) {
    return (
      <AccountLevelPicker
        selected={selectedLevel?.levelId}
        onSelect={(account) => onLevelSelected({ levelTypeId, levelId: account?.accountId })}
      />
    )
  }

  if (levelTypeId === 3) {
    return (
      <AssetLevelPicker
        selected={selectedLevel?.levelId}
        onSelect={(asset) => onLevelSelected({ levelTypeId, levelId: asset?.assetId })}
      />
    )
  }

  if (levelTypeId === 201) {
    return (
      <ClientLevelPicker
        selected={selectedLevel?.levelId}
        onSelect={(client) => onLevelSelected({ levelTypeId, levelId: client?.clientId })}
      />
    )
  }

  return null
}

LevelPickers.propTypes = {
  levelTypeId: PropTypes.number,
  selectedLevel: PropTypes.object,
  onLevelSelected: PropTypes.func
}

export default LevelPickers
