import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useSearchClients } from '../../../../../api/clients'
import FlexRow from '../../../../molecules/FlexRow'
import FastAvatar from '../../../../molecules/FastAvatar'
import Card from '../../../../molecules/Card'

function ClientHeader ({ clientId }) {
  const query = useMemo(() => {
    return {
      filters: {
        clientId: [clientId]
      },
      includes: {
        avatars: true
      },
      take: 1
    }
  }, [clientId])
  const { data, isLoading } = useSearchClients(query)
  const client = useMemo(() => {
    return (data?.data || []).at(0)
  }, [data])

  if (isLoading) {
    return null
  }

  return (
    <Card>
      <FlexRow style={{ gap: '20px' }}>
        <FastAvatar avatarUrl={client?.avatarUrl} abbreviation={client.clientAbbreviation} size='md' />
        <div>
          <Typography variant='h2'>{client.longName}</Typography>
          {client.longName !== client.shortName ? (
            <Typography variant='h4'>{client.shortName}</Typography>
          ) : null}
        </div>
      </FlexRow>
    </Card>
  )
}

ClientHeader.propTypes = {
  clientId: PropTypes.number
}

export default ClientHeader
