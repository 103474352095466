import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useAssetTearSheetList, useAssetTearSheetViewById } from '../../../../../../../api/abundanceEngine'
import Loading from '../../../../../../molecules/Loading'
import TearSheetProvider from '../../../../../tearSheet/TearSheetProvider'
import TearSheetView from '../../../../../tearSheet/TearSheetView'
import SydSelectBox from '../../../../../../commonDesign/SydSelectBox'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../../../../commonDesign/Button'
import Card from '../../../../../../molecules/Card'
import EmptySection from '../../../../../../atoms/EmptySection'
import { useAssignAssetTearSheet } from '../../../../../../../api/accounts'

function ModifyTearSheetAssignmentForm ({ asset, tearSheet, onCancel, onComplete }) {
  const { data: _options, isLoading: optionsLoading } = useAssetTearSheetList(3)
  const [selectedTearSheet, setSelectedTearSheet] = useState(tearSheet?.tearSheetId || null)
  const [processing, setProcessing] = useState(false)
  const { data: view, isFetching: previewLoading } = useAssetTearSheetViewById(selectedTearSheet)
  const { mutateAsync: assignTearSheet } = useAssignAssetTearSheet()
  const handleSubmit = useCallback(async (tearSheetId) => {
    try {
      setProcessing(true)
      await assignTearSheet({
        assetId: asset.assetId,
        tearSheetId: +tearSheetId
      })
      onComplete(+tearSheetId)
    } catch (err) {
      console.error(err)
    } finally {
      setProcessing(false)
    }
  }, [asset, setProcessing, assignTearSheet, onComplete])

  const tearSheetValue = useMemo(() => {
    return {
      levelTypeId: 3,
      levelId: asset.assetId
    }
  }, [asset])
  const options = useMemo(() => {
    return (_options?.tearSheets || []).map(ts => ({
      label: ts.name,
      value: ts.tearSheetId
    }))
  }, [_options])

  if (optionsLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <SydSelectBox
          options={options}
          value={selectedTearSheet}
          onChange={setSelectedTearSheet}
        />
      </Grid>
      <Grid item xs={9}>
        <Card>
          {previewLoading ? (
            <Loading />
          ) : selectedTearSheet === null ? (
            <EmptySection title='Select a Tear Sheet' description='' />
          ) : (
            <TearSheetProvider value={tearSheetValue}>
              <TearSheetView
                jsx={view?.jsxView}
                bindings={{
                  assetId: asset.assetId,
                  view
                }}
              />
            </TearSheetProvider>
          )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            disabled={!selectedTearSheet}
            processing={processing}
            onClick={() => handleSubmit(selectedTearSheet)}
          >
            Assign Tear Sheet
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyTearSheetAssignmentForm.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number
  }),
  tearSheet: PropTypes.object,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func
}

export default ModifyTearSheetAssignmentForm
