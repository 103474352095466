import React, { useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import Card from '../../../../molecules/Card'
import PersonalSpace from '../../../../molecules/PersonalSpace'
import { useListImagePurposes } from '../../../../../api/media'
import Loading from '../../../../molecules/Loading'
import LevelPicker from './LevelPicker'
import LevelDetails from './LevelDetails'

const useStyles = makeStyles((theme) => ({
}))

function AssocationsTab () {
  const { data: p, isLoading } = useListImagePurposes()
  const classes = useStyles()
  const [selectedLevel, setSelectedLevel] = useState(null)

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Card variant='clear' className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={4} xl={2} lg={3} md={4}>
          <LevelPicker purposes={p?.purposes || []} onChange={setSelectedLevel} />
        </Grid>
        <Grid item xs={8} xl={10} lg={9} md={4}>
          <LevelDetails level={selectedLevel} purposes={p?.purposes || []} />
        </Grid>
      </Grid>
      <PersonalSpace />
    </Card>
  )
}

export default AssocationsTab
