import React, { useCallback, useMemo } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Box, CircularProgress } from '@material-ui/core'
import DragAndDropInputArea from '../../../molecules/DragAndDropInputArea'
import { DOCUMENT_VAULT_ACCEPTED_FILES } from '../../../../constants'
import SnackAlert from '../../../molecules/SnackAlert/SnackAlert'
import SydButton from '../../../commonDesign/Button'
import { defaultCreateDocumentLabel } from '../constants'
import DocumentVaultUploadFilePreview from './VaultUploadsPreview'
import { useFileEvents, useValidator } from './hooks'

const DocumentVaultFileUpload = ({
  children,
  containerStyle,
  onFinish,
  persistDropRejected,
  showUploadButton,
  labels: _labels,
  hasChildren,
  levelTypeId,
  levelId,
  defaultVisibility
}) => {
  const {
    files,
    setFiles,
    onFileAccepted,
    uploadDocumentsMutation: {
      isLoading: isLoadingUpload,
      isError: isUploadError,
      error: uploadError
    }
  } = useFileEvents(levelTypeId, levelId)
  const labels = useMemo(() => ({ ...defaultCreateDocumentLabel, ..._labels }), [_labels])

  const containerStyles = useMemo(
    () => ({
      position: 'absolute',
      inset: 0,
      ...((hasChildren && isEmpty(files)) || !hasChildren
        ? containerStyle
        : {})
    }),
    [files, hasChildren, containerStyle]
  )

  const onDragAndDropValidator = useValidator(files)

  const onFinishHandler = useCallback((uploadedDocuments) => {
    setFiles([])
    onFinish(uploadedDocuments)
  }, [onFinish, setFiles])

  return (
    <>
      <DragAndDropInputArea
        validator={onDragAndDropValidator}
        acceptedFileTypes={DOCUMENT_VAULT_ACCEPTED_FILES}
        onDropAccepted={(files) => {
          return onFileAccepted(files, defaultVisibility)
        }}
        containerStyle={containerStyles}
        persistDropRejected={persistDropRejected}
      >
        {({ openUploadFileWindow }) => (
          <>
            {isLoadingUpload && (
              <Box
                position='absolute'
                top={0}
                left={0}
                right={0}
                bottom={0}
                display='flex'
                alignItems='center'
                justifyContent='center'
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  backdropFilter: 'blur(4px)'
                }}
                zIndex={1000}
              >
                <CircularProgress color='primary' />
              </Box>
            )}

            {showUploadButton && isEmpty(files) && (
              <Box display='flex' width='100%' justifyContent='end' position='relative' zIndex={1000}>
                <Box position='absolute' right={0} top='-40px'>
                  <SydButton size='sm' variant='primary' icon={labels.addButton?.icon} onClick={openUploadFileWindow}>
                    {labels.addButton?.label}
                  </SydButton>
                </Box>
              </Box>
            )}

            {isEmpty(files) ? children : (
              <DocumentVaultUploadFilePreview
                levelId={levelId}
                levelTypeId={levelTypeId}
                files={files}
                onFinish={onFinishHandler}
                onCancel={() => setFiles([])}
                fileRejections={[]}
                openUploadFileWindow={openUploadFileWindow}
                showFileRejectionsMessage={false}
                showPreviewPlaceHolder={false}
              />
            )}
          </>
        )}
      </DragAndDropInputArea>

      {isUploadError && (
        <SnackAlert
          alert={{
            openAlert: true,
            alertMessage: uploadError?.response?.message ?? 'Error uploading file(s).  Please try again',
            alertSeverity: 'error'
          }}
        />
      )}
    </>
  )
}

DocumentVaultFileUpload.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  containerStyle: PropTypes.object,
  onFinish: PropTypes.func,
  persistDropRejected: PropTypes.bool,
  hasChildren: PropTypes.bool,
  levelTypeId: PropTypes.number,
  levelId: PropTypes.number,
  labels: PropTypes.shape({
    addButton: PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string
    })
  }),
  showUploadButton: PropTypes.bool,
  defaultVisibility: PropTypes.string
}

DocumentVaultFileUpload.defaultProps = {
  children: undefined,
  clientId: undefined,
  containerStyle: undefined,
  onFinish: noop,
  openUploadFileWindow: noop,
  persistDropRejected: true,
  hasChildren: false,
  levelTypeId: 201,
  levelId: undefined,
  showUploadButton: false,
  defaultVisibility: 'public'
}

export default DocumentVaultFileUpload
