import { useMutation, useQuery } from '@tanstack/react-query'
import { useAppContext } from '../redux/slices/appContext'
import { deleteDocument, editDocument, editDocuments, getDocument, postNamedCommand, postNamedQuery } from '../service'
import { QUERY_KEYS } from './queryKeys'

export const useSearchVaultDocuments = (query, options = {}) => {
  const { userId } = useAppContext()
  const { mapper, enabled = true } = options
  return useQuery({
    cacheTime: 1000 * 5, // 5 sec
    queryKey: [QUERY_KEYS.vaultDocuments, userId, query],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'searchDocuments', query)
      return data
    },
    select: mapper,
    enabled
  })
}

export const useAssignedAttachmentsByClient = (levelTypeId, levelId, clientId) => {
  const { userId } = useAppContext()
  return useQuery({
    enabled: !!(levelTypeId && levelId),
    queryKey: ['get-client-attachments-by-assignment', userId, levelTypeId, levelId, clientId],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'get-client-attachments-by-assignment', {
        levelTypeId,
        levelId,
        clientId
      })

      return data
    }
  })
}

export const useDocumentDownload = () => {
  const { clientId } = useAppContext()
  return useMutation({
    mutationFn: async (doc, isPreview = false) => {
      try {
        const { data } = await getDocument(clientId, doc.documentId, isPreview)
        if (isPreview) {
          window.open(data.downloadUrl)
        } else {
          const link = document.createElement('a')
          link.href = data.downloadUrl
          link.download = data.name
          document.body.appendChild(link)
          link.click()
          link.remove()
        }

        return data
      } catch (error) {
        console.error(error)
      }
    }
  })
}

export const useAddAttachment = () => {
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, documentId }) => {
      const { data } = await postNamedCommand('vaultV2', 'create-attachment', {
        attachment: {
          levelTypeId,
          levelId,
          documentId
        }
      })

      return data
    }
  })
}

export const useAddMultipleAttachments = () => {
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, documents }) => {
      const attachments = documents.map(d => ({
        levelTypeId,
        levelId,
        documentId: d.documentId
      }))
      const { data } = await postNamedCommand('vaultV2', 'create-attachments', {
        attachments
      })
      return data
    }
  })
}

export const useRemoveAttachment = () => {
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, documentId }) => {
      const { data } = await postNamedCommand('vaultV2', 'remove-attachment', {
        attachment: {
          levelTypeId,
          levelId,
          documentId
        }
      })

      return data
    }
  })
}

export const useFinalizeDocumentUploads = () => {
  return useMutation({
    mutationFn: async ({ levelId, levelTypeId, files }) => {
      const documents = files.map(f => ({
        documentId: f.document?.documentId,
        ...f.document,
        status: f.status?.uploaded ? 'uploaded' : f.status?.aborted ? 'failed' : 'pending',
        tags: f.tags ?? []
      }))

      const { data } = await editDocuments({
        levelTypeId,
        levelId,
        documents
      })

      return data
    }
  })
}

export const useFirmDocuments = () => {
  const appContext = useAppContext()
  return useQuery({
    queryKey: ['list-firm-documents', appContext.firmId, appContext.userId],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'listDocuments', {
        levelTypeId: 1000000,
        levelId: [appContext.firmId],
        includeUrl: true,
        includeThumbnailUrl: true,
        contentDisposition: 'inline'
      })

      return data
    }
  })
}

export const useListDocuments = (queryParams, options = {}) => {
  const appContext = useAppContext()
  const { mapper, enabled = true, ...otherOptions } = options

  return useQuery({
    queryKey: ['list-documents', appContext.firmId, queryParams],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'listDocuments', queryParams)
      return data
    },
    select: data => mapper ? mapper(data) : data,
    enabled,
    ...otherOptions
  })
}

export const useGetDocument = (queryParams, options = {}) => {
  const appContext = useAppContext()
  const { mapper } = options

  return useQuery({
    queryKey: ['get-document', appContext.firmId, queryParams],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'getDocument', queryParams)
      return data
    },
    select: data => mapper ? mapper(data) : data
  })
}

export const useListTags = (queryParams, _options = {}) => {
  const { firmId } = useAppContext()
  const { mapper, ...options } = _options
  return useQuery({
    queryKey: ['list-tags', firmId, queryParams],
    queryFn: async () => {
      const { data } = await postNamedQuery('vaultV2', 'listTags', {
        ...queryParams
      })
      return data
    },
    select: data => mapper ? mapper(data) : data,
    ...options
  })
}

export const useListDocumentsGroupedByTags = (queryParams, options = {}, batchSize = 5) => {
  const { firmId } = useAppContext()
  const { mapper, ...otherOptions } = options
  return useQuery({
    queryKey: ['list-documents', 'grouped-by-tags', firmId, queryParams],
    queryFn: async () => {
      const filters = queryParams?.filters ?? {}
      const { data } = await postNamedQuery('vaultV2', 'listTags', {
        filters
      })

      if (!filters.tagIds?.length) {
        data.tags.push({
          id: null,
          name: 'Untagged'
        })
      }

      const batchProcessTags = async (tags) => {
        const results = []

        for (let i = 0; i < tags.length; i += batchSize) {
          const batch = tags.slice(i, i + batchSize).map(tag => {
            return postNamedQuery('vaultV2', 'listDocuments', {
              ...queryParams,
              filters: {
                ...queryParams.filters,
                tagIds: tag.id ? [{ op: 'eq', value: tag.id }] : [{ op: 'isNull', value: true }]
              }
            }).then(response => {
              tag.documents = response.data?.documents ?? []
              return tag
            })
          })
          const batchResults = await Promise.all(batch)
          results.push(...batchResults)
        }

        return results
      }

      // Process all tags in batches
      await batchProcessTags(data.tags)
      return data
    },
    select: data => mapper ? mapper(data) : data,
    ...otherOptions
  })
}

export const useUploadDocumentsMutation = () => {
  return useMutation({
    mutationFn: async ({ levelId, levelTypeId, visibility, documents: _documents }) => {
      const documents = _documents.map((file) => ({
        name: file.name,
        type: file.type,
        size: file.size,
        status: file.document?.status ?? 'pending',
        tags: file.tags ?? []
      }))

      const { data } = await postNamedCommand('vaultV2', 'createDocuments', {
        levelTypeId,
        levelId,
        visibility,
        documents
      })
      return data
    }
  })
}

export const useEditDocumentsMutation = () => {
  return useMutation({
    mutationFn: async ({ levelId, levelTypeId, documents: _documents }) => {
      const documents = _documents.map((file) => ({
        ...file,
        documentId: file.document?.documentId,
        status: file.document?.status ?? 'pending',
        tags: file.tags ?? [],
        visibility: file.document.visibility ?? 'visible'
      })).filter(x => !!x.documentId)

      const { data } = await editDocuments({
        levelTypeId,
        levelId,
        documents
      })
      return data
    }
  })
}

export const useEditDocumentMutation = () => {
  return useMutation({
    mutationFn: async ({ levelId, levelTypeId, document }) => {
      const { data } = await editDocument({
        levelTypeId,
        levelId,
        document
      })
      return data
    }
  })
}

export const useDeleteDocumentMutation = () => {
  return useMutation({
    mutationFn: async ({ documentId }) => {
      const { data } = deleteDocument({
        documentId
      })
      return data
    }
  })
}

export const useDeleteTag = () => {
  return useMutation({
    mutationFn: async (tagId) => {
      const { data } = await postNamedCommand('vaultV2', 'delete-tag', {
        tagId
      })
      return data
    }
  })
}

export const useUpdateTag = () => {
  return useMutation({
    mutationFn: async (_tag) => {
      const { id, ...tag } = _tag
      if (!id || id === 'new') {
        const { data } = await postNamedCommand('vaultV2', 'create-tag', { tag })
        return data
      }
      const { data } = await postNamedCommand('vaultV2', 'edit-tag', {
        tag: _tag
      })
      return data
    }
  })
}
