import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useListImages } from '../../../api/media'
import { useMediaScope } from './useMediaScope'

function MediaImage ({
  levelTypeId,
  purposeCode,
  style,
  className,
  alt,
  levelId
}) {
  const mediaScope = useMediaScope({ levelTypeId, levelId })
  const query = useMemo(() => {
    return {
      purpose: {
        purposeCode,
        ...mediaScope
      },
      take: 1
    }
  }, [purposeCode, mediaScope])
  const { data: images, isLoading } = useListImages(query)

  if (isLoading) return null
  if (!images?.data?.length) return null

  const image = images?.data?.at?.(0)

  return (
    <img
      src={image.imageUrl}
      alt={alt || image.description}
      style={style}
      className={className}
    />
  )
}

MediaImage.propTypes = {
  levelTypeId: PropTypes.number.isRequired,
  purposeCode: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  alt: PropTypes.string,
  levelId: PropTypes.number
}

export default MediaImage
