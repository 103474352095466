import React, { useCallback, useState } from 'react'
import { Menu, MenuItem, makeStyles, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    alignItems: 'center'
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.layout.radius.circle,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.layout.shadow.normal
  }
}))

function ImageActionMenu ({ image, size, onDelete }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const open = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(e.target)
  }, [setAnchorEl])
  const close = useCallback(() => setAnchorEl(null), [])
  const withClose = useCallback((fn) => () => {
    fn()
    close()
  }, [close])

  return (
    <FadeIn className={classes.entryActions}>
      <IconButton aria-controls='more-opts' aria-haspopup='true' onClick={open}>
        <Icon name={ICON_NAMES.vertDots} customSize={size} additionalClasses={classes.icon} />
      </IconButton>
      <Menu
        id='image-actions'
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
      >
        <MenuItem onClick={withClose(() => onDelete?.(image))}>
          <div className={classes.menuItem}>
            <Icon name='delete' />
            <div>Delete Image</div>
          </div>
        </MenuItem>
      </Menu>
    </FadeIn>
  )
}

ImageActionMenu.propTypes = {
  image: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDelete: PropTypes.func
}

ImageActionMenu.defaultProps = {
  size: '24px'
}

export default ImageActionMenu
