import React from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import VisibilityIcon from '../../VaultCard/VisibilityIcon'
import { useVaultContext } from '../../VaultContextProvider'

const VisibilityCell = ({ cell }) => {
  const { labels } = useVaultContext()

  return (
    <Box style={{ marginLeft: 0 }}>
      <Box ml='-18px' display='flex' gridGap='8px' alignItems='center'>
        <VisibilityIcon visibility={cell.value} labels={labels} inline iconSize='14px' />
        {labels?.visibility?.[cell.value]?.label ?? ''}
      </Box>
    </Box>
  )
}

VisibilityCell.propTypes = {
  cell: PropTypes.object
}
export default VisibilityCell
