import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useAssetSearchDeprecated } from '../../../../api/coreData'
import FlexRow from '../../../molecules/FlexRow'

function AssetSubjectHeader ({ assetId }) {
  const q = useMemo(() => ({
    filters: { assetId },
    take: 1
  }), [assetId])
  const { data, isLoading } = useAssetSearchDeprecated(q)
  const asset = useMemo(() => {
    const d = data || []
    return d?.length ? d.at(0) : null
  }, [data])

  if (isLoading) return null
  if (!asset) return null

  return (
    <FlexRow>
      <div>
        <Typography variant='h2'>{asset.assetName}</Typography>
        <Typography variant='h4'>{asset.assetIdentifier}</Typography>
      </div>
    </FlexRow>
  )
}

AssetSubjectHeader.propTypes = {
  assetId: PropTypes.string
}

export default AssetSubjectHeader
