import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  TEXT_VARIANTS,
  TEXT_ALIGNMENTS
} from '../constants'
import { selectProps } from '.'

export const tableCell = {
  cell: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.any
  ]),
  isExpanded: PropTypes.bool,
  tooltip: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    format: PropTypes.string,
    skipFormat: PropTypes.bool
  }),
  hideChevron: PropTypes.bool,
  rowIndex: PropTypes.number,
  deepLevel: PropTypes.number,
  isExpandible: PropTypes.bool,
  isLastRow: PropTypes.bool,
  onExpandRowClick: PropTypes.func,
  isRowLoading: PropTypes.bool,
  withBorder: PropTypes.bool,
  isOnRootRow: PropTypes.bool,
  isLoading: PropTypes.bool,
  cellFormatter: PropTypes.func,
  isFirstCell: PropTypes.bool,
  isLastCell: PropTypes.bool,
  cellOptions: PropTypes.object
}

export const tableCellDefaults = {
  cell: '',
  tooltip: {},
  hideChevron: false,
  withBorder: false,
  isExpanded: false,
  isOnRootRow: false,
  isLoading: false,
  cellFormatter: null,
  isFirstCell: false,
  isLastCell: false,
  cellOptions: {}
}

export const rowProps = {
  rowIndex: PropTypes.number,
  onClick: PropTypes.func,
  onExpandRow: PropTypes.func,
  isRoot: PropTypes.bool,
  isLastRow: PropTypes.bool,
  someLoading: PropTypes.bool,
  someExpanded: PropTypes.bool,
  rowIndexes: PropTypes.arrayOf(PropTypes.number),
  showEmptyRowOnExpandIfEmpty: PropTypes.bool,
  isLoading: PropTypes.bool
}

export const rowDefaults = {
  rowIndex: 0,
  onClick: noop,
  onExpandRow: noop,
  isRoot: false,
  isLastRow: false,
  someLoading: false,
  someExpanded: false,
  rowIndexes: [],
  showEmptyRowOnExpandIfEmpty: true,
  isLoading: false
}

export const CollapsibleTableColumnPropTypes = PropTypes.shape({
  hidden: PropTypes.bool,
  name: PropTypes.string,
  isExpandible: PropTypes.bool,
  showTooltip: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.string,
  alignment: PropTypes.string,
  colSpan: PropTypes.number,
  isSelect: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(TEXT_VARIANTS)),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectProps: {
    ...selectProps,
    // defines the type of select to be used
    selectType: PropTypes.string,
    // key primarily used to match a selector with a column in a table
    sourceKey: PropTypes.string
  },
  // Extra CSS properties applied at cell level
  extraStyles: PropTypes.any
})

export const CollapsibleTableColumnsPropTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    CollapsibleTableColumnPropTypes,
    PropTypes.any
  ])
)

export const CollapsibleTableRowPropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.node,
  PropTypes.shape({
    hidden: PropTypes.bool,
    text: PropTypes.string,
    parentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
    colSpan: PropTypes.number,
    isLoading: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isExpandible: PropTypes.bool,
    alignment: PropTypes.string,
    rows: PropTypes.array,
    type: PropTypes.string,
    columns: CollapsibleTableColumnsPropTypes,
    classType: PropTypes.string,
    childClassType: PropTypes.string,
    hideIfExpanded: PropTypes.bool,
    assetIds: PropTypes.shape({
      subclassTagId: PropTypes.string,
      assetClassTagId: PropTypes.string
    }),
    variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showTooltip: PropTypes.bool,
    tooltips: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          format: PropTypes.string
        }),
        PropTypes.any
      ])
    ),
    // Extra CSS properties applied at cell level
    extraStyles: PropTypes.any,
    // data needed for the useCSVDownload hook
    csvData: PropTypes.any
  }),
  PropTypes.any
])

export const CollapsibleTableRowsPropTypes = PropTypes.arrayOf(
  CollapsibleTableRowPropTypes
)

export const insertColumnsAtShape = {
  // Index used to insert or append a new table specification, the table spec will take the place of the specified index and in none of the cases will overlap the previous configuration.
  insertAtIndex: PropTypes.number.isRequired,
  rows: CollapsibleTableRowsPropTypes,
  columns: CollapsibleTableColumnsPropTypes,
  finalRow: PropTypes.arrayOf(PropTypes.any)
}

export const insertColumnsAtDefaults = {
  insertAtIndex: 0,
  rows: [],
  columns: [],
  finalRow: []
}

export const dateRangeFilterShape = {
  startDate: PropTypes.string,
  endDate: PropTypes.string
}

export const defaultColumns = [
  { hidden: true },
  { name: '' },
  { colSpan: 2, name: 'Total Value' },
  {
    colSpan: 2,
    isSelect: true,
    selectProps: {
      selectType: 'date',
      sourceKey: 'balanceInformation2Column',
      selectedValue: {
        operation: 'subtract',
        unitType: 'month',
        unitValue: 3,
        suffix: '_L3'
      }
    }
  },
  {
    colSpan: 2,
    isSelect: true,
    selectProps: {
      selectType: 'date',
      sourceKey: 'balanceInformation3Column',
      selectedValue: {
        operation: 'subtract',
        unitType: 'month',
        unitValue: 12,
        suffix: '_L12'
      }
    }
  }
]

export const simpleTableRowCellProps = {
  alignment: PropTypes.oneOf(Object.values(TEXT_ALIGNMENTS)),
  avatarSrc: PropTypes.string,
  color: PropTypes.string,
  editableProps: PropTypes.any,
  hasLink: PropTypes.bool,
  isDate: PropTypes.bool,
  isEditableActions: PropTypes.bool,
  isPublicAvatar: PropTypes.bool,
  to: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  withAvatar: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  showTooltip: PropTypes.bool,
  hidden: PropTypes.bool,
  tooltips: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isExpandible: PropTypes.bool,
  isExpanded: PropTypes.bool,
  colSpan: PropTypes.number
}

export const simpleTableRowCellDefaults = {
  alignment: TEXT_ALIGNMENTS.right,
  avatarSrc: '',
  color: '',
  editableProps: {},
  hasLink: false,
  isDate: false,
  isEditableActions: false,
  isPublicAvatar: false,
  to: '',
  value: '',
  variant: TEXT_VARIANTS.body1,
  withAvatar: false,
  text: '',
  type: '',
  showTooltip: false,
  hidden: false,
  tooltips: [],
  width: undefined,
  isExpandible: false,
  isExpanded: false,
  colSpan: 1
}

export const simpleTableLabelProps = {
  alignment: PropTypes.oneOf(Object.values(TEXT_ALIGNMENTS)),
  colSpan: PropTypes.number,
  hidden: PropTypes.bool,
  isSelect: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectProps,
  sorteable: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(TEXT_VARIANTS)),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export const simpleTableLabelDefaults = {
  alignment: TEXT_ALIGNMENTS.right,
  colSpan: 1,
  hidden: false,
  isSelect: false,
  name: '',
  selectProps: undefined,
  sorteable: false,
  variant: TEXT_VARIANTS.subtitle2,
  width: undefined
}
