import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import FlexRow from '../../../../../molecules/FlexRow'
import CodeTag from '../../../../../atoms/CodeTag'
import ThingSlot from './ThingSlot'

const useStyles = makeStyles((theme) => ({
  thingCategory: {
    '& .__category-header': {
      paddingTop: theme.layout.padding.p5,
      paddingRight: theme.layout.padding.p0,
      paddingBottom: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p0,
      marginBottom: theme.layout.margin.m5,
      borderBottom: `1px solid ${theme.palette.gray.darker}`
    },
    '& .__category-description': {
      fontStyle: 'italic',
      fontWeight: theme.typography.weights.light
    }
  }
}))

function ThingCategory ({ category, things, onEdit }) {
  const classes = useStyles()

  return (
    <div className={classes.thingCategory}>
      <Grid container spacing={2}>
        <Grid item xs={12} className='__category-header'>
          <FlexRow style={{ alignItems: 'center' }}>
            <Typography variant='h3'>{category.name}</Typography>
            <CodeTag>{category.code}</CodeTag>
          </FlexRow>
          <div className='__category-description'>{category.description}</div>
        </Grid>
        {(category.things ?? []).map(thingConfig => (
          <Grid item xs={12} lg={6} key={thingConfig.code}>
            <ThingSlot thingConfiguration={thingConfig} things={things} onEdit={onEdit} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

ThingCategory.propTypes = {
  category: PropTypes.shape({
    thingCategoryId: PropTypes.number,
    levelTypeId: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    configuration: PropTypes.object,
    things: PropTypes.array
  }),
  things: PropTypes.arrayOf(PropTypes.shape({
    thingId: PropTypes.number,
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    category: PropTypes.string,
    ordinal: PropTypes.number,
    data: PropTypes.shape({
      contentType: PropTypes.string
    })
  })),
  onEdit: PropTypes.func
}

export default ThingCategory
