import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import NullValue from '../../../../../molecules/NullValue'
import FlexRow from '../../../../../molecules/FlexRow'
import CodeTag from '../../../../../atoms/CodeTag'
import EditButton from '../../../shared/EditButton'

const useStyles = makeStyles((theme) => ({
  thing: {
    '& .__thing-content': {
      paddingTop: theme.layout.padding.p5,
      paddingRight: theme.layout.padding.p20,
      paddingBottom: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p20,
      border: `1px solid ${theme.palette.gray.darker}`,
      borderRadius: theme.layout.radius.tight,
      fontWeight: theme.typography.weights.light,
      backgroundColor: theme.palette.gray.light
    },
    '& .__thing-header': {
      paddingRight: theme.layout.padding.p0,
      paddingBottom: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p0
    },
    '& .__thing-description': {
      fontStyle: 'italic',
      fontWeight: theme.typography.weights.light
    },
    '& .__thing-title': {
      fontWeight: theme.typography.weights.bold
    }
  }
}))

function ThingSlot ({ thingConfiguration, things, onEdit }) {
  const classes = useStyles()
  const match = useMemo(() => {
    return things.find?.(t => t.code === thingConfiguration.code && t.category === thingConfiguration.category)
  }, [thingConfiguration, things])

  return (
    <div className={classes.thing}>
      <FlexRow className='__thing-header' style={{ justifyContent: 'space-between' }}>
        <div>
          <FlexRow>
            <Typography className='__thing-title' variant='h4'>{thingConfiguration.name}</Typography>
            <CodeTag>{thingConfiguration.code}</CodeTag>
          </FlexRow>
          <div className='__thing-description'>{thingConfiguration.description}</div>
        </div>
        <div>
          <EditButton
            disabled={thingConfiguration.configuration.contentType !== 'markdown'}
            policy='admin_edit_assetdetails'
            onClick={() => onEdit(match, thingConfiguration)}
          >
            Edit
          </EditButton>
        </div>
      </FlexRow>
      {match ? (
        <div className='__thing-content'>
          {thingConfiguration.configuration.contentType === 'markdown' ? (
            <ReactMarkdown children={match.content} remarkPlugins={[remarkGfm]} />
          ) : (
            <pre>{match.content}</pre>
          )}
        </div>
      ) : (
        <div className='__thing-content'>
          <NullValue value='Not Assigned' />
        </div>
      )}
    </div>
  )
}

ThingSlot.propTypes = {
  thingConfiguration: PropTypes.shape({
    thingConfigurationId: PropTypes.number,
    levelTypeId: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    configuration: PropTypes.shape({
      contentType: PropTypes.oneOf(['markdown', 'html'])
    }),
    category: PropTypes.string
  }),
  things: PropTypes.arrayOf(PropTypes.shape({
    thingId: PropTypes.number,
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    category: PropTypes.string,
    ordinal: PropTypes.number,
    data: PropTypes.shape({
      contentType: PropTypes.string
    })
  })),
  onEdit: PropTypes.func
}

export default ThingSlot
