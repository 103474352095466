import React, { useRef } from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import { useSectionEditing, useAssetDetails } from '../../AssetProvider'
import ModifyDisplayName from '../dialogs/ModifyDisplayName'
import ModifyDisplaySymbol from '../dialogs/ModifyDisplaySymbol'
import AttributesSectionDisplay from './Display'

function AttributesSection () {
  const { editing, asset } = useAssetDetails()
  const sectionIsEditing = useSectionEditing('general')
  const editDisplayName = useRef(null)
  const editDisplaySymbol = useRef(null)

  return (
    <FadeIn>
      <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
        <SectionHeader text='Asset Information' />
        <AttributesSectionDisplay
          asset={asset}
          onEditDisplayName={() => editDisplayName.current.open(asset)}
          onEditDisplaySymbol={() => editDisplaySymbol.current.open(asset)}
        />
      </SectionScreen>
      <ModifyDisplayName ref={editDisplayName} />
      <ModifyDisplaySymbol ref={editDisplaySymbol} />
    </FadeIn>
  )
}

AttributesSection.propTypes = {
}

export default AttributesSection
