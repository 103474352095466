import { useEffect, useMemo, useState } from 'react'
import { isObject } from 'lodash'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import PerformanceCellPicker, { CELL_TYPES } from './PerformancePresentationTable/Cells'
import { createColumnMapper, mapFormatString, mapHeaderAccessor, mapSortFunction, mapTitle } from './columnUtility'

const defaultColumnConfig = {
  columns: [
    {
      id: 'title',
      header: '',
      align: 'left',
      columns: [
        { id: 'levelName', accessor: 'levelName', header: '', format: 'title', minWidth: 400, benchmark: 'balance.benchmarkLongName' }
      ]
    },
    {
      id: 'Balance',
      header: 'Balance',
      align: 'center',
      columns: [
        { id: 'endingValue', accessor: 'balance.endingValue', header: 'Value', format: 'human', align: 'right' },
        { id: 'allocation', accessor: 'allocation', header: '%', format: 'allocation', align: 'right' }
      ]
    },
    {
      id: 'QTD',
      header: 'Quarter To Date',
      columns: [
        { id: 'QTDNetGain', accessor: 'QTD.netChange', header: 'Net Gain', format: 'marketValue', align: 'right' },
        { id: 'QTDReturn', accessor: 'QTD.annualizedReturnNOF', header: 'Return', format: 'returns', align: 'right', benchmark: 'QTD.annualizedBenchmark' }
      ]
    }],
  defaultSort: [
    { id: 'levelName', desc: false }
  ]
}

function createAccessor (accessor) {
  if (typeof accessor === 'function') {
    return accessor
  }
  if (accessor?.includes('.')) {
    return (row) => accessor.split('.').reduce((p, c) => p ? p[c] : p, row)
  }
  return accessor
}

function createBenchmarkAccessor (accessor, options) {
  if (accessor?.includes('.')) {
    return (row, column) => {
      const value = accessor.split('.').reduce((p, c) => {
        if (!p) return p
        if (p instanceof Array) {
          return p
            .sort((a, b) => (a?.benchmarkOrdinal < b?.benchmarkOrdinal ? -1 : 1))
            .map((i) => i[c])
        }
        return p[c]
      }, row)
      if (!value) return ''
      if (value instanceof Array) {
        return value.map((val) =>
          (options.formatter ?? identity)(val, column?.format)
        )
      }
      return (options.formatter ?? identity)(value, column?.format)
    }
  }
  if (!accessor) {
    return () => ''
  }
  return (row, column) => {
    const value = row[accessor]
    if (!value) return ''
    return (options.formatter ?? identity)(value, column?.format)
  }
}

const mapHeader = ({ component, tooltipTitle, ...column }) => {
  return {
    ...column,
    ...(tooltipTitle
      ? { Header: PerformanceCellPicker, cellType: 'tooltipTitle', tooltipTitle }
      : {}),
    ...(component && Object.keys(CELL_TYPES).includes(component)
      ? { Header: PerformanceCellPicker, cellType: component, tooltipTitle }
      : {})
  }
}

const identity = x => x

const getCellComponentType = (cellComponent) => {
  if (isObject(cellComponent)) {
    const { type, ...payload } = cellComponent
    if (type && Object.keys(CELL_TYPES).includes(type)) {
      return { type, payload }
    }
  }
  return { type: cellComponent, payload: {} }
}

const mapCellAccessor = ({ cellComponent, cellTooltipTitle, ...column }, options) => {
  let cellProps = {}
  if (cellTooltipTitle) {
    cellProps = {
      Cell: PerformanceCellPicker,
      cellType: 'baseCell',
      cellTooltipTitle
    }
  } else if (cellComponent && Object.keys(CELL_TYPES).includes(cellComponent?.type || cellComponent)) {
    const component = getCellComponentType(cellComponent)
    cellProps = {
      Cell: PerformanceCellPicker,
      cellType: component?.type,
      payload: component?.payload,
      formatter: options?.formatter ?? identity
    }
  } else {
    cellProps = {
      Cell: ({ value, column }) => {
        const formatter = options?.formatter ?? identity
        return formatter(value, column.format)
      }
    }
  }
  return {
    ...column,
    accessor: createAccessor(column.accessor, options),
    ...cellProps
  }
}

const mapBenchmarkAccessor = (column, options) => ({
  ...column,
  benchmark: createBenchmarkAccessor(column.benchmark, options)
})

const mapResolvedDates = (column, options) => ({
  ...column,
  resolvedDates: options.normalizedDateRanges
})

const mappingOps = [
  mapTitle,
  mapFormatString,
  mapHeaderAccessor,
  mapHeader,
  mapCellAccessor,
  mapBenchmarkAccessor,
  mapSortFunction,
  mapResolvedDates
]

const mapColumn = createColumnMapper(mappingOps)

const usePerformanceColumns = ({
  columnConfig: _columnConfig = defaultColumnConfig,
  title,
  normalizedDateRanges
}) => {
  const [columnConfig, setColumnConfig] = useState(_columnConfig)

  useEffect(() => {
    setColumnConfig(_columnConfig)
  }, [_columnConfig])

  const { formatter } = useFormattingContext()
  const { columns, defaultSort } = useMemo(() => {
    const { columns, defaultSort } = columnConfig
    const mapped = columns.map((c, index) => mapColumn(c, {
      title,
      formatter,
      normalizedDateRanges
    }, index))

    return {
      columns: mapped,
      defaultSort
    }
  }, [columnConfig, title, formatter, normalizedDateRanges])

  return {
    columns,
    defaultSort
  }
}

export default usePerformanceColumns
