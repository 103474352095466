import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useAssetTearSheetViewById } from '../../../../../../../api/abundanceEngine'
import Loading from '../../../../../../molecules/Loading'
import TearSheetProvider from '../../../../../tearSheet/TearSheetProvider'
import TearSheetView from '../../../../../tearSheet/TearSheetView'

function PreviewDialogForm ({ asset, tearSheet }) {
  const { data: view, isLoading } = useAssetTearSheetViewById(tearSheet.tearSheetId)
  const tearSheetValue = useMemo(() => {
    return {
      levelTypeId: 3,
      levelId: asset.assetId
    }
  }, [asset])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TearSheetProvider value={tearSheetValue}>
          <TearSheetView
            jsx={view?.jsxView}
            bindings={{
              assetId: asset.assetId,
              view
            }}
          />
        </TearSheetProvider>
      </Grid>
    </Grid>
  )
}

PreviewDialogForm.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number
  }),
  tearSheet: PropTypes.object
}

export default PreviewDialogForm
