import ImageList from './ImageList'
import MediaImage from './MediaImage'
import MediaBackground from './MediaBackground'

const ImageAssocationModule = {
  ImageList,
  MediaImageList: ImageList,
  MediaImage,
  MediaBackground
}

export default ImageAssocationModule
