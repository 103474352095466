import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import Icon from '../../../../atoms/Icon'
import { ICON_NAMES } from '../../../../../constants'

const DocumentThumbnailCell = ({ cell }) => {
  const [thumbnailSrc, setThumbnailSrc] = useState(cell.value)

  if (!thumbnailSrc) return null

  return (
    <Box width='40px' height='52px' display='flex' justifyContent='center' alignItems='center' border={1} borderColor='#ccc' borderRadius='4px' style={{ marginLeft: 0 }}>
      {!thumbnailSrc
        ? <Icon name={ICON_NAMES.document} color='#ccc' customSize='26px' />
        : (
          <img
            onError={(e) => setThumbnailSrc('')}
            width='40px'
            height='52px'
            src={thumbnailSrc}
            alt='File thumbnail'
          />
        )}
    </Box>
  )
}

DocumentThumbnailCell.propTypes = {
  cell: PropTypes.object
}
export default DocumentThumbnailCell
