import { createContext, useContext, useMemo, useState } from 'react'

export const SydMenuContext = createContext({
  selectedValue: null
})

export const useSydMenuContext = () => {
  const tabGroupContext = useContext(SydMenuContext)

  return tabGroupContext
}

export const useSydMenuValues = (menuOptions, _defaultValue) => {
  const { options, defaultValue } = useMemo(() => {
    const entries = Object.entries(menuOptions)
    const options = entries.map(([key, value]) => ({
      key,
      value
    })).sort((a, b) => a?.value?.order - b?.value?.order)

    const defaultValue = _defaultValue ?? options.find((val) => !val?.value?.disabled)?.key

    return {
      options,
      defaultValue
    }
  }, [_defaultValue, menuOptions])
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  return useMemo(() => ({
    selectedValue,
    selectValue: setSelectedValue,
    options
  }), [selectedValue, setSelectedValue, options])
}
