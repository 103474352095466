import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppContext } from '../../../redux/slices/appContext'
import {
  useComponentsOfChangeQuery, useNormalizeDates
} from '../../../api/coreData'
import PresentationTable from '../PresentationTable'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import TooltipTitleCell from '../PerformanceTableV2/PerformancePresentationTable/Cells/TooltipTitleCell'
import {
  DATE_RANGE_CUSTOM_OPTION_KEYS,
  defaultCustomOptions,
  defaultOptions,
  useRelativeDateRanges
} from '../../molecules/RelativeDateSelect'
import PerformanceCellPicker from '../PerformanceTableV2/PerformancePresentationTable/Cells'
import { findAndReplacePerformanceAccessors } from '../PerformanceTableV2/usePerformanceColumns'
import { useWidgetContext } from '../../molecules/WidgetWrapper'

dayjs.extend(utc)

const DEFAULT_FORMAT_VALUE = {
  return: 'returns',
  default: 'human'
}

const getCellFormat = (table, format) => {
  // build on the default to allow someone to configure overrides only
  const effectiveFormat = {
    ...DEFAULT_FORMAT_VALUE,
    ...format
  }
  // Find the format string from row's {type} property
  const formatString =
    table?.cell?.row?.original?.type in effectiveFormat
      ? effectiveFormat[table?.cell?.row?.original.type]
      : effectiveFormat.default

  return formatString
}

const mapComponentAliases = (components, aliases) => {
  return components.map((component) => ({
    ...component,
    name: aliases[component.type] || component.name
  }))
}

const useComponentsOfChange = ({
  defaultFilter,
  dateRanges,
  selectedComponents,
  dateRangeOptions,
  selectedComponentsAliases
}) => {
  const { clientId, availableDates, loadingAvailableDates } = useAppContext()

  const { dateRanges: relativeDateRanges } = useRelativeDateRanges(
    dateRanges,
    availableDates
  )

  const normalizedDatesQuery = useMemo(() => {
    return {
      query: {
        dateRanges: relativeDateRanges.reduce((acc, { key, dateRange }) => {
          return { ...acc, [key]: { key, ...dateRange } }
        }, {})
      },
      queryOptions: {
        mapper: (data) => data
      }
    }
  }, [relativeDateRanges])

  const { data: normalizedDateRanges, isLoading: isLoadingNormalizedDates } =
    useNormalizeDates(
      normalizedDatesQuery.query,
      normalizedDatesQuery.queryOptions
    )

  const query = useMemo(() => {
    if (loadingAvailableDates || isLoadingNormalizedDates) return null

    return {
      levelFilters: {
        levelTypes: ['client'],
        clientIds: [clientId],
        ...(defaultFilter || {})
      },
      dateRange: normalizedDateRanges,
      components: selectedComponents
    }
  }, [
    clientId,
    defaultFilter,
    selectedComponents,
    normalizedDateRanges,
    loadingAvailableDates,
    isLoadingNormalizedDates
  ])

  const { data, isLoading } = useComponentsOfChangeQuery(query, {
    enabled: !!query,
    mapper: (data) => mapComponentAliases(data, selectedComponentsAliases)
  })

  const relativeDateRangeOptions = useMemo(() => {
    return relativeDateRanges.map(({ key, label: dateRangeLabel, dateRange }) => {
      let label = dateRangeLabel
      if (key.startsWith(DATE_RANGE_CUSTOM_OPTION_KEYS.CUSTOM)) {
        const startDate = dayjs.utc(dateRange.startDate).format('MM/DD/YYYY')
        const endDate = dayjs.utc(dateRange.endDate).format('MM/DD/YYYY')
        label = `${startDate} - ${endDate}`
      }
      return {
        dateRange,
        label,
        key
      }
    })
  }, [relativeDateRanges])

  return {
    data: data ?? [],
    isLoading,
    options: dateRangeOptions,
    relativeDateRangeOptions,
    normalizedDateRanges
  }
}

const useColumns = ({
  title,
  dateRanges,
  options,
  /** Formatting configuration */
  format,
  /** Should the user be able to change the date ranges */
  canChangeDateRange,
  tooltipTitles,
  dateRangeColumnProps,
  normalizedDateRanges
}) => {
  /** Get the formatter from the formatting context and then use it to format the rows specifically to the type that
   *  they need to be formatted from the @param format */
  const { formatter } = useFormattingContext()

  const cellFormatter = useCallback(
    (table) => {
      const value = table?.cell?.value
      const formatString = getCellFormat(table, format)
      return formatter(value, formatString)
    },
    [formatter, format]
  )

  const columns = useMemo(() => {
    return [
      {
        Header: title,
        accessor: 'name',
        width: 100,
        Cell: (row) => {
          const type = row?.cell?.row?.original?.type
          const tooltipTitle = tooltipTitles[type]
          return (
            <TooltipTitleCell column={{ tooltipTitle, header: row.value }} />
          )
        }
      },
      ...dateRanges.map((dr, i) => ({
        ...(canChangeDateRange
          ? {
            Header: PerformanceCellPicker,
            cellType: 'dateRangeSelector',
            resolvedDates: {
              performanceRanges: normalizedDateRanges
            },
            minWidth: 200
          }
          : { Header: dr.label }),
        // id: dr.key,
        index: i + 1, // the first column shows the name of the component
        accessor: dr.key,
        header: dr.label,
        Cell: (row) => cellFormatter(row),
        alignRight: true,
        width: 100,
        ...(dateRangeColumnProps || {})
      }))
    ]
  }, [title, dateRanges, tooltipTitles, canChangeDateRange, cellFormatter, dateRangeColumnProps, normalizedDateRanges])

  return columns
}

const ComponentsOfChangeTableV2 = forwardRef(({
  title,
  defaultFilter: _defaultFilter,
  dateRanges,
  selectedComponents,
  dateRangeOptions,
  canChangeDateRange,
  selectedComponentsAliases,
  tooltipTitles,
  format,
  dateRangeColumnProps,
  variant
}, ref) => {
  const { subscribedFilters } = useWidgetContext()

  const defaultFilter = useMemo(() => {
    return Object.assign({}, _defaultFilter, subscribedFilters?.levelFilters)
  }, [_defaultFilter, subscribedFilters?.levelFilters])

  const { data, options, isLoading, relativeDateRangeOptions, normalizedDateRanges } =
    useComponentsOfChange({
      defaultFilter,
      dateRanges,
      selectedComponents,
      dateRangeOptions,
      selectedComponentsAliases
    })

  const columns = useColumns({
    title,
    format,
    options,
    tooltipTitles,
    canChangeDateRange,
    dateRanges: relativeDateRangeOptions,
    dateRangeColumnProps,
    normalizedDateRanges
  })

  const [columConfig, setColumnConfig] = useState(undefined)

  useEffect(() => {
    if (!isEmpty(dateRanges)) {
      const columnConfigTransformed = findAndReplacePerformanceAccessors(
        { columns },
        dateRanges,
        relativeDateRangeOptions
      )
      setColumnConfig(columnConfigTransformed)
    }
  }, [dateRanges, columns, relativeDateRangeOptions])

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        variant={variant}
        ref={ref}
        data={data}
        columns={columConfig ? columConfig.columns : columns}
        loading={isLoading}
      />
    </PresentationTable.Wrapper>
  )
})

export const componentsOfChangeTableProps = {
  title: PropTypes.string,
  variant: PropTypes.string,
  defaultFilter: PropTypes.object,
  dateRanges: PropTypes.arrayOf(PropTypes.string),
  selectedComponents: PropTypes.object,
  dateRangeOptions: PropTypes.array,
  format: PropTypes.object,
  canChangeDateRange: PropTypes.bool,
  selectedComponentsAliases: PropTypes.object,
  tooltipTitles: PropTypes.object,
  dateRangeColumnProps: PropTypes.object
}

export const componentsOfChangeTableDefaultProps = {
  title: 'Household Activity Summary',
  defaultFilter: {},
  dateRanges: undefined,
  selectedComponents: {
    return: { returnType: 'cumulative' }
  },
  format: DEFAULT_FORMAT_VALUE,
  canChangeDateRange: false,
  selectedComponentsAliases: {},
  tooltipTitles: {},
  dateRangeOptions: [...defaultOptions, ...defaultCustomOptions],
  dateRangeColumnProps: {
    alignRight: true
  }
}

ComponentsOfChangeTableV2.propTypes = componentsOfChangeTableProps
ComponentsOfChangeTableV2.defaultProps = componentsOfChangeTableDefaultProps

export default ComponentsOfChangeTableV2
