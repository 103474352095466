import PropTypes from 'prop-types'
import React from 'react'
import DocumentVaultFileUpload from '../VaultFileUpload'

const fileUploadStyles = {
  border: 'none',
  position: 'relative',
  cursor: 'inherit',
  padding: '0 10px',
  display: 'block',
  marginLeft: '-10px',
  marginRight: '-10px',
  minWidth: '100%',
  width: 'auto'
}

const VaultCollectionContent = ({
  levelId,
  levelTypeId,
  children,
  canUpload,
  onRefetch,
  showUploadButton,
  labels,
  defaultVisibility
}) => {
  if (canUpload && levelId && levelTypeId) {
    return (
      <DocumentVaultFileUpload
        containerStyle={fileUploadStyles}
        showBackButton
        backButtonLabel='Back'
        onFinish={onRefetch}
        onFilesChange={onRefetch}
        persistDropRejected
        levelId={levelId}
        levelTypeId={levelTypeId}
        showUploadButton={showUploadButton}
        labels={labels}
        defaultVisibility={defaultVisibility}
      >
        {children}
      </DocumentVaultFileUpload>
    )
  }
  return (
    <>
      {children}
    </>
  )
}

VaultCollectionContent.propTypes = {
  levelId: PropTypes.number,
  levelTypeId: PropTypes.number,
  children: PropTypes.node,
  canUpload: PropTypes.bool,
  onRefetch: PropTypes.func,
  showUploadButton: PropTypes.bool,
  labels: PropTypes.object,
  defaultVisibility: PropTypes.string
}

export default VaultCollectionContent
