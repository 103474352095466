import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { Blade } from '../../../shared/Blade'
import { useSearchAccounts } from '../../../../../../api/accounts'
import Loading from '../../../../../molecules/Loading'
import MiniTable from '../../../shared/MiniTable'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import { usePickerStyles } from './usePickerStyles'

const columns = [
  { accessor: 'accountId', header: 'ID' },
  { accessor: 'accountName', header: 'Name' }
]
const accountKeyAccessor = account => account?.accountId

function AccountLevelPicker ({ onSelect, selected }) {
  const classes = usePickerStyles()
  const theme = useTheme()
  const [filter, setFilter] = useState('')
  const query = useMemo(() => {
    return {
      textSearch: {
        accountName: filter || undefined,
        accountNumber: filter || undefined
      },
      filters: {
        isClosed: false
      },
      take: 100
    }
  }, [filter])
  const { data: accounts, isLoading } = useSearchAccounts(query)
  const [_accounts, _selected] = useMemo(() => {
    const _accounts = accounts?.data || []
    return [_accounts, _accounts.find(x => x.accountId === selected)]
  }, [selected, accounts])

  return (
    <Blade scrollable={false} style={{ borderRadius: '4px', borderColor: theme.palette.gray.dark, borderWidth: '1px' }}>
      <div className={classes.wrapper}>
        <div className={classes.topBar}>
          <div>
            <DebouncedInput className={classes.search} onChange={setFilter} delay={100} />
          </div>
        </div>
        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : _accounts?.length ? (
          <div className={classes.table}>
            <div>
              <MiniTable
                data={_accounts}
                columns={columns}
                keyAccessor={accountKeyAccessor}
                onSelect={onSelect}
                selected={_selected}
              />
            </div>
          </div>
        ) : (
          <div className={classes.noData}>
            No Accounts Available
          </div>
        )}
      </div>
    </Blade>
  )
}

AccountLevelPicker.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.any
}

AccountLevelPicker.defaultProps = {}

export default AccountLevelPicker
