import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../molecules/RoundedModal'
import DeleteImageForm from './form'

const DeleteImageDialog = React.forwardRef(function DeleteImageDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (image) => setDialogState({ image, open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Delete Image'
      open={dialogState.open}
      onClose={close}
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<DeleteImageForm image={dialogState.image} onComplete={handleComplete} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

DeleteImageDialog.propTypes = {
  onComplete: PropTypes.func
}

DeleteImageDialog.defaultProps = {
  onComplete: () => {}
}

export default DeleteImageDialog
