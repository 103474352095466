import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import LevelHeader from '../LevelHeader'
import AttachImageDialog from '../AttachImageDialog'
import { useRemoveImageAssociation } from '../../../../../api/media'
import ImageDetails from '../ImageDetails'
import LevelImages from './LevelImages'

const useStyles = makeStyles(theme => ({
  levelDetails: {
    width: '100%',
    overflowX: 'none'
  }
}))

function LevelDetails ({ level, purposes, hideHeader }) {
  const classes = useStyles()
  const attachImageRef = useRef(null)
  const selectedPurposes = useMemo(() => {
    if (!level) return []
    return purposes.find(x => x.levelTypeId === level.levelTypeId)?.purposes || []
  }, [purposes, level])
  const { mutateAsync: removeImageAssociation } = useRemoveImageAssociation()
  const onDelete = useCallback(async (imageAssociation) => {
    try {
      await removeImageAssociation(imageAssociation)
    } catch (err) {
      console.error(err)
    }
  }, [removeImageAssociation])
  const [selectedImage, setSelectedImage] = useState(null)

  if (!level) {
    return null
  }

  return (
    <FadeIn className={classes.levelDetails}>
      <Grid container spacing={3}>
        {hideHeader ? null : (
          <Grid item xs={12}>
            <LevelHeader {...level} />
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <LevelImages
            {...level}
            purposes={selectedPurposes}
            onAttachImage={(purpose) => attachImageRef.current.open(purpose, level.levelTypeId, level.levelId)}
            onRemoveImageAttachment={({ purposeId, imageId }) => onDelete({ purposeId, imageId, ...level })}
            onSelectImage={setSelectedImage}
            selectedImage={selectedImage}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageDetails image={selectedImage} disableActions />
        </Grid>
      </Grid>
      <AttachImageDialog ref={attachImageRef} />
    </FadeIn>
  )
}

LevelDetails.propTypes = {
  level: PropTypes.shape({
    levelTypeId: PropTypes.number,
    levelId: PropTypes.number
  }),
  purposes: PropTypes.array,
  hideHeader: PropTypes.bool
}

export default LevelDetails
