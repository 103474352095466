import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import SydSelect from '../../../../commonDesign/SydSelect'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import LevelPickers from './LevelPickers'

const useStyles = makeStyles(theme => ({
  levelPicker: {
    width: '100%',
    overflowX: 'none'
  }
}))

function LevelPicker ({ purposes, onChange }) {
  const classes = useStyles()
  const defaultValue = useMemo(() => (purposes || []).at(0)?.levelTypeId, [purposes])
  const [levelTypeId, setLevelTypeId] = useState(defaultValue)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const _setSelectedLevel = useCallback((level) => {
    setSelectedLevel(level)
    onChange(level)
  }, [setSelectedLevel, onChange])
  const options = useMemo(() => {
    return (purposes || []).map(p => ({
      label: p.levelName,
      value: p.levelTypeId
    }))
  }, [purposes])

  if (!purposes?.length) {
    return null
  }

  return (
    <FadeIn className={classes.levelPicker}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SydSelect
            size='sm' options={options} value={levelTypeId} onChange={(id) => setLevelTypeId(id.target.value)} style={{ minWidth: 0 }}
          />
        </Grid>
        <Grid item xs={12}>
          <LevelPickers levelTypeId={levelTypeId} selectedLevel={selectedLevel} onLevelSelected={_setSelectedLevel} />
        </Grid>
      </Grid>
    </FadeIn>
  )
}

LevelPicker.propTypes = {
  purposes: PropTypes.array,
  onChange: PropTypes.func
}

export default LevelPicker
