import { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  DOCUMENT_VAULT_REJECTION_MESSAGES,
  MAX_FILES_DOCUMENT_VAULT,
  MAX_FILES_SIZE_DOCUMENT_VAULT
} from '../../../../constants'
import { getFileExtension } from '../../../../utils'
import { uploadDocument } from '../../../../service'
import { useDeleteDocumentMutation, useUploadDocumentsMutation } from '../../../../api/documentVault'

export const useValidator = (files) => {
  return useCallback((file) => {
    const { name, extension } = getFileExtension(file.name, true)
    if (isEmpty(name) || isEmpty(extension)) {
      return DOCUMENT_VAULT_REJECTION_MESSAGES.NO_EXTENSION
    }
    if (file.size > MAX_FILES_SIZE_DOCUMENT_VAULT) {
      return DOCUMENT_VAULT_REJECTION_MESSAGES.MAX_FILE_SIZE
    }
    if (files.length >= MAX_FILES_DOCUMENT_VAULT) {
      return DOCUMENT_VAULT_REJECTION_MESSAGES.MAX_FILE_LENGTH
    }
    return null
  }, [files.length])
}

export const filterFileEntries = (files, newFileEntries) => {
  const prevFilesNames = files.map((file) => file.name)
  return newFileEntries.filter((file) => !prevFilesNames.includes(file.name))
}

export const useFileEvents = (levelTypeId = 201, levelId, autoUpload = false) => {
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false)

  /** Updates a file in the array of files */
  const editFile = useCallback((id, mutator) => {
    setFiles(prev => {
      const fileIndex = prev.findIndex(x => x.documentSpecification?.id === id)
      if (fileIndex === -1) return prev

      const copy = [...prev]
      copy[fileIndex] = mutator(copy[fileIndex], fileIndex, prev)

      return copy
    })
  }, [])

  /** Updates the progress of a file in a set of files being uploaded */
  const onProgress = useCallback((id) => (e) => {
    const progress = (e.loaded / e.total) * 100
    editFile(id, f => ({
      ...f,
      status: {
        ...f.status,
        uploaded: progress === 100,
        progress
      }
    }))
  }, [editFile])

  /** Called when the file upload is aborted */
  const onAbort = useCallback((id) => () => {
    editFile(id, f => ({
      ...f,
      status: {
        ...f.status,
        progress: 0,
        aborted: true
      }
    }))
  }, [editFile])

  const uploadFile = useCallback(async (id, file) => {
    try {
      const xhr = new XMLHttpRequest()
      await uploadDocument(
        file.url,
        file.file,
        onProgress(id),
        onAbort(id),
        xhr
      )
    } catch (err) {
      console.error(err)
      editFile(id, f => ({
        ...f,
        status: { ...f.status, error: err }
      }))
    }
  }, [editFile, onProgress, onAbort])

  const uploadFiles = useCallback(async (files) => {
    try {
      const tasks = files.map(f => uploadFile(f.document.documentId, f))
      await Promise.all(tasks)
    } catch (err) {
      console.error(err)
    }
  }, [uploadFile])

  const uploadDocumentsMutation = useUploadDocumentsMutation()
  const onFileAccepted = useCallback(async (newFiles, visibility) => {
    try {
      const newFileEntries = filterFileEntries(files, newFiles)
      if (newFileEntries.length) {
        const { documents } = await uploadDocumentsMutation.mutateAsync({
          levelTypeId,
          levelId,
          visibility,
          documents: newFileEntries
        })

        const newFiles = newFileEntries.map((fileEntry) => {
          const createdDoc = documents.find((documentSpec) =>
            documentSpec?.document?.requestedName === fileEntry.name)

          return {
            name: createdDoc?.document?.name ?? fileEntry.name,
            file: fileEntry,
            type: fileEntry.type,
            size: fileEntry.size,
            ...createdDoc
          }
        })

        setFiles((prevFiles) => [...prevFiles, ...newFiles])
        setUploading(false)
        if (autoUpload) {
          uploadFiles(newFiles).catch(console.error)
        }
      }
    } catch (err) {
      setUploading(false)
      console.error(err)
    }
  }, [files, uploadDocumentsMutation, levelTypeId, levelId, autoUpload, uploadFiles])

  const deleteDocumentMutation = useDeleteDocumentMutation()
  const onDeleteFile = useCallback(async ({ documentId }) => {
    try {
      await deleteDocumentMutation.mutateAsync({ documentId })
      setFiles((prevFiles) => prevFiles.filter((file) => file.document.documentId !== documentId))
    } catch (err) {
      console.error(err)
      throw err
    }
  }, [deleteDocumentMutation])

  return {
    uploadDocumentsMutation,
    deleteDocumentMutation,
    onFileAccepted,
    onDeleteFile,
    files,
    setFiles,
    editFile,
    uploading
  }
}
