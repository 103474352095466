import React, { useMemo, useRef, useState } from 'react'
import { Grid, LinearProgress, makeStyles } from '@material-ui/core'
import Card from '../../../../molecules/Card'
import { useListImages } from '../../../../../api/media'
import ImageList from '../ImageList'
import FlexRow from '../../../../molecules/FlexRow'
import SydButton from '../../../../commonDesign/Button'
import AddImageDialog from '../AddImageDialog'
import DebouncedInput from '../../../../molecules/DebouncedInput'
import PersonalSpace from '../../../../molecules/PersonalSpace'
import ImageDetails from '../ImageDetails'
import FadeIn from '../../../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  loader: {
    minHeight: '5px'
  }
}))

function ImagesTab () {
  const addRef = useRef(null)
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const query = useMemo(() => ({
    search
  }), [search])
  const { data: images, isFetching } = useListImages(query)
  const [selectedImage, setSelectedImage] = useState(null)

  return (
    <Card variant='clear' className={classes.card}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FlexRow>
            <DebouncedInput onChange={setSearch} style={{ height: '40px' }} />
            <SydButton
              variant='outline'
              size='md'
              onClick={() => addRef.current.open()}
              icon='image'
            >
              Add Image
            </SydButton>
          </FlexRow>
        </Grid>
        <Grid item xs={12}>
          {isFetching ? (
            <FadeIn className={classes.loader} delay={0.5}>
              <LinearProgress variant='indeterminate' />
            </FadeIn>
          ) : (
            <div className={classes.loader} />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <ImageList
            images={images?.data || []}
            onSelect={setSelectedImage}
            selectedImage={selectedImage}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageDetails
            image={selectedImage}
            onImageDeleted={() => setSelectedImage(null)}
          />
        </Grid>
      </Grid>
      <AddImageDialog ref={addRef} />
      <PersonalSpace />
    </Card>
  )
}

export default ImagesTab
