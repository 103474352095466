import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import Icon from '../../../atoms/Icon'

const useStyles = makeStyles((theme) => ({
  thumbnailImg: {
    display: 'inline-block',
    borderRadius: theme.layout.radius.tight,
    maxWidth: '150px',
    maxHeight: '150px',
    width: '150px'
  },
  thumbnailFrame: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.layout.gap.g5,
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    padding: theme.layout.padding.p10,
    outline: '1px solid transparent',
    transition: 'outline-color .2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      outlineColor: `color-mix(in srgb, ${theme.palette.primary.main} 80%, transparent)`
    },
    overflow: 'hidden',
    '&.__selected': {
      outline: `2px solid ${theme.palette.primary.main}`
    }
  },
  thumbnailName: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

function ImageThumbnail ({ thumbnailUrl, description, displayName, onClick, selected, icon }) {
  const classes = useStyles()

  if (!thumbnailUrl) {
    return (
      <Tooltip title={description || displayName} placement='right' onClick={onClick}>
        <div className={clsx(classes.thumbnailFrame, { __selected: selected })}>
          <Icon name={icon} customSize={150} alt={description || displayName} color='rgba(0,0,0,.15)' />
          <div className={classes.thumbnailName}>{displayName}</div>
        </div>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={description || displayName} placement='right' onClick={onClick}>
      <div className={clsx(classes.thumbnailFrame, { __selected: selected })}>
        <img className={classes.thumbnailImg} src={thumbnailUrl} alt={description || displayName} />
        <div className={classes.thumbnailName}>{displayName}</div>
      </div>
    </Tooltip>
  )
}

ImageThumbnail.propTypes = {
  thumbnailUrl: PropTypes.string,
  description: PropTypes.string,
  displayName: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  icon: PropTypes.string
}

ImageThumbnail.defaultProps = {
  icon: 'image'
}

export default ImageThumbnail
