import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useBoolean } from '../../../hooks'
import SearchBarInput, { searchBarInputProps } from './SearchBarInput'
import SearchBarOptions from './SearchBarOptions'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  }
}))

const SearchBar = forwardRef(({
  loading,
  name,
  error,
  onChange,
  variant,
  placeholder,
  className,
  onBlur,
  options,
  onClear,
  onSelectOption,
  ...props
}, ref) => {
  const classes = useStyles()
  const searchInputInnerRef = useRef()
  const [query, setQuery] = useState()
  const [touched, setTouched] = useBoolean()
  const [showOptions, setShowOptions] = useBoolean()

  useImperativeHandle(ref, () => searchInputInnerRef.current, [])

  const onFocus = useCallback(() => {
    setTouched.on()
    if (!isEmpty(options)) {
      setShowOptions.on()
    }
  }, [options, setTouched, setShowOptions])

  useEffect(() => {
    if (loading) {
      setShowOptions.off()
    } else if (touched) {
      setShowOptions.on()
    }
  }, [loading, setShowOptions, touched])

  const onClickOption = useCallback(
    (event, option) => {
      setQuery(option.label)
      setShowOptions.off()
      onSelectOption(event, option)
    },
    [onSelectOption, setShowOptions]
  )

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const [option] = options
      onClickOption(event, option)
    }
  }, [options, onClickOption])

  return (
    <div className={classes.container}>
      <SearchBarInput
        ref={searchInputInnerRef}
        name={name}
        error={error}
        value={query}
        onBlur={onBlur}
        onClear={onClear}
        loading={loading}
        variant={variant}
        onFocus={onFocus}
        setValue={setQuery}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        searchInputClassName={className}
      />
      <SearchBarOptions
        anchorRef={searchInputInnerRef}
        disableAutoFocus
        options={options}
        open={showOptions}
        setOpen={setShowOptions}
        onSelectOption={onClickOption}
        {...props}
      />
    </div>
  )
})

SearchBar.propTypes = {
  error: PropTypes.bool,
  onClear: PropTypes.func,
  name: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  onSelectOption: PropTypes.func,
  InputProps: searchBarInputProps
}

export default SearchBar
