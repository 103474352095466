import { makeStyles } from '@material-ui/styles'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import CheckBoxItem from '../molecules/CheckBoxItem'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const mapItemsToKeyValuePair = (items) => {
  return items.reduce((acc, item) => ({ ...acc, [item.id]: { ...item } }), {})
}

const useCheckBoxList = ({ items: _items }) => {
  const [items, setItems] = useState({})

  useEffect(() => {
    setItems(mapItemsToKeyValuePair(_items))
  }, [_items])

  const onChange = useCallback((id, checked) => {
    setItems((prevItems) => {
      const item = { ...(prevItems[id] || {}), id, checked }
      return { ...prevItems, [id]: item }
    })
  }, [])

  return { items: Object.entries(items), onChange }
}

const CheckBoxList = forwardRef(({
  defaultItems,
  renderCheckboxBody,
  itemContainerClassName,
  onChange: _onChange,
  onItemChange: _onItemChange
}, ref) => {
  const classes = useStyles()
  const { items, onChange } = useCheckBoxList({ items: defaultItems })

  useImperativeHandle(ref, () => ({
    value: items
  }))

  useEffect(() => {
    if (_onChange) _onChange(items)
  }, [_onChange, items])

  const onItemChange = useCallback(
    (id, event, payload) => {
      const checked = event.target.checked
      if (_onItemChange) _onItemChange(id, checked, payload)
      onChange(id, checked)
    },
    [_onItemChange, onChange]
  )

  return (
    <div className={classes.container}>
      {items.map(([id, { checked, payload, disabled }]) => {
        return (
          <CheckBoxItem
            key={id}
            checked={checked}
            disabled={disabled}
            containerClassName={itemContainerClassName}
            onChange={(event) => onItemChange(id, event, payload)}
          >
            {renderCheckboxBody && renderCheckboxBody(id, payload)}
          </CheckBoxItem>
        )
      })}
    </div>
  )
})

CheckBoxList.defaultProps = {
  defaultItems: [],
  onChange: null,
  onItemChange: noop,
  renderCheckboxBody: null,
  itemContainerClassName: ''
}

CheckBoxList.propTypes = {
  renderCheckboxBody: PropTypes.func,
  onItemChange: PropTypes.func,
  defaultItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      payload: PropTypes.any,
      disabled: PropTypes.bool
    })
  ),
  onChange: PropTypes.func,
  itemContainerClassName: PropTypes.string
}

export default CheckBoxList
