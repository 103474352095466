import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Checkbox, makeStyles } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  resultWrapper: {
    width: 'calc(100% - 4px)',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    padding: '5px',
    outline: '2px solid transparent',
    transition: 'outline .3s ease-in-out .1s',
    margin: '0 2px',
    '&:hover': {
      outline: `2px solid ${theme.palette.summitBlue}`,
      cursor: 'pointer'
    }
  },
  result: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    padding: '8px 10px',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      backgroundColor: theme.palette.white,
      padding: 0
    }
  },
  details: {
    width: '100%',
    '& .__name': {
      padding: '5px 0'
    },
    '& .__details': {
      fontWeight: '100',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    width: '100%',
    paddingLeft: '60px',
    justifyContent: 'flex-end',
    '& .__chip': {
      backgroundColor: theme.palette.summitBlue,
      color: theme.palette.white,
      borderRadius: '100px',
      padding: '3px 8px'
    }
  }
}))

function DocumentSearchResult ({ result, index, selected, onSelect }) {
  const classes = useStyles()

  const dateValue = useMemo(() => {
    return dayjs().utc(result.created_at).format('MM/DD/YYYY')
  }, [result.created_at])

  const isSelected = useMemo(() => {
    return !!selected.find(x => result.documentId === x.documentId)
  }, [selected, result.documentId])
  // eslint-disable-next-line no-undef
  return (
    <FadeIn
      delay={index * 0.05}
      className={classes.resultWrapper}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onSelect(result)
      }}
    >
      <div className={classes.result}>
        <div>
          <Checkbox className='__cb' checked={isSelected} onChange={() => {}} />
        </div>
        <div className={classes.details}>
          <div className='__name'>{result.name}</div>
          <div className='__details'>
            <div>{dateValue}</div>
            <div>{result.documentType ?? result.type}</div>
          </div>
        </div>
      </div>
      <div className={classes.tags}>
        {result.tags?.map(td => (
          <span className='__chip' key={td.id}>{td.name}</span>
        ))}
      </div>
    </FadeIn>
  )
}

DocumentSearchResult.propTypes = {
  result: PropTypes.shape({
    documentId: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    documentType: PropTypes.string,
    created_at: PropTypes.string,
    tags: PropTypes.array
  }),
  index: PropTypes.number,
  selected: PropTypes.bool,
  onSelect: PropTypes.func
}

DocumentSearchResult.defaultProps = {
  index: 0,
  selected: false,
  onSelect: () => {}
}

export default DocumentSearchResult
