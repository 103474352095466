import VaultWrapper from './VaultWrapper'
import VaultCollection from './VaultCollection'
import VaultContextProvider from './VaultContextProvider'
import TagsFilter from './Controls/TagsFilter'
import SearchFilter from './Controls/SearchFilter'
import ViewToggles from './Controls/ViewToggles'

export const DocumentVaultComponents = {
  DocumentVault: VaultWrapper,
  DV_ViewToggles: ViewToggles,
  DV_TagsFilter: TagsFilter,
  DV_SearchFilter: SearchFilter,
  DV_Collection: VaultCollection,
  DV_ContextProvider: VaultContextProvider
}
