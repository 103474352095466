import React, { useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import SydAutocomplete from '../../../commonDesign/SydAutocomplete'
import { useListTags } from '../../../../api/documentVault'
import useDebouncedValue from '../../../../hooks/useDebounceValue'
import { useVaultContext } from '../VaultContextProvider'
import { useNormalizeLevelValues } from '../hooks'

const TagsFilter = () => {
  const { levels: _levels, setFilters } = useVaultContext()
  const [searchString, setSearchString] = useState('')
  const searchStringDebounced = useDebouncedValue(searchString, 500)
  const levels = useNormalizeLevelValues(_levels)

  const { data, isLoading } = useListTags({
    filters: {
      levels,
      status: [{ op: 'eq', value: 'uploaded' }]
    },
    ...(searchStringDebounced?.length && {
      textSearch: {
        name: [{ op: 'contains', value: searchStringDebounced }]
      }
    })
  },
  {
    mapper: data => data.tags?.map(({ id, name }) => ({ value: id, label: name }))
  })

  const handleOnChange = useCallback(val => {
    setFilters(prevVal => ({ ...prevVal, tags: val }))
  }, [setFilters])

  return (
    <Box width='100%'>
      <SydAutocomplete
        size='sm'
        label='Tags'
        placeholder='All Documents'
        options={data ?? []}
        defaultValue={[]}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={(e, val) => handleOnChange(val)}
        onInputChange={(e, val) => setSearchString(val)}
        multiple
        isLoading={isLoading}
      />
    </Box>
  )
}
export default TagsFilter
