import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import ModifyDisplayNameForm from './form'

const ModifyDisplayNameDialog = React.forwardRef(function ModifyDisplayNameDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (asset) => setDialogState({ asset, open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Modify Display Name'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ModifyDisplayNameForm asset={dialogState.asset} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ModifyDisplayNameDialog.propTypes = {
}

export default ModifyDisplayNameDialog
