import KDPMetric from './KDPMetric'
import KDPTable from './KDPTable'
import KDPMetricGrid from './KDPMetricGrid'

const KeyDatapointModule = {
  KDPMetric,
  KDPTable,
  KDPMetricGrid
}

export default KeyDatapointModule
