import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useAssetDetails } from '../AssetProvider'
import NullValue from '../../../../molecules/NullValue'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '20px',
    '& .__name': {
      fontSize: theme.typography.fontSizes.h3
    },
    '& .__desc': {
      fontWeight: theme.typography.weights.light
    }
  }
}))

function AssetDetailsHeader () {
  const classes = useStyles()
  const { asset } = useAssetDetails()
  return (
    <div className={classes.header}>
      {asset?.assetName ? (
        <div className='__name'>{asset.assetName}</div>
      ) : (
        <div className='__name'>
          <NullValue value='<No Name Provided>' />
        </div>
      )}
      {asset?.assetIdentifier ? (
        <div className='__desc'>{asset.assetIdentifier}</div>
      ) : (
        <div className='__desc'>
          <NullValue value='<Unidentified Asset>' />
        </div>
      )}
    </div>
  )
}

export default AssetDetailsHeader
