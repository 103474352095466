import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core'
import FlexRow from '../../../../molecules/FlexRow'
import SydInput from '../../../../commonDesign/SydInput'
import Icon from '../../../../atoms/Icon'
import { KEYS } from '../../../../../constants'
import { useRenameImage } from '../../../../../api/media'

const useStyles = makeStyles((theme) => ({
  displayName: {
    cursor: 'pointer'
  }
}))

function ImageDisplayName ({ image }) {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(image?.displayName)
  const inputRef = useRef(null)
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: renameImage } = useRenameImage()

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
      setTimeout(() => inputRef.current.select(), 10)
    }
  }, [editing, inputRef])

  const reset = useCallback(() => {
    setValue(image?.displayName)
    setEditing(false)
  }, [setValue, setEditing, image?.displayName])

  const submit = useCallback(async () => {
    setProcessing(true)
    try {
      if (!value) {
        console.log('Required')
      }
      await renameImage({
        imageId: image.imageId,
        displayName: value
      })
      setEditing(false)
    } finally {
      setProcessing(false)
    }
  }, [setProcessing, setEditing, renameImage, image, value])

  const edit = useCallback(() => {
    setValue(image.displayName)
    setEditing(true)
  }, [setValue, image, setEditing])

  useEffect(() => {
    if (editing) {
      const keyListener = (e) => {
        if (e.which === KEYS.ESC) {
          reset()
        }
        if (e.which === KEYS.ENTER) {
          submit().catch(console.error)
        }
      }
      window.addEventListener('keyup', keyListener, true)
      return () => {
        window.removeEventListener('keyup', keyListener, true)
      }
    }
  }, [editing, reset, submit])

  if (editing) {
    return (
      <FlexRow>
        <SydInput
          ref={inputRef}
          value={value}
          disabled={processing}
          onChange={(e) => setValue(e.target.value)}
        />
        <IconButton onClick={submit} disabled={processing}>
          {processing ? (
            <CircularProgress size={24} />
          ) : (
            <Icon name='check' color='green' customSize={24} />
          )}
        </IconButton>
        <IconButton onClick={reset} disabled={processing}>
          <Icon name='close' color='red' customSize={24} />
        </IconButton>
      </FlexRow>
    )
  } else {
    return (
      <FlexRow style={{ alignItems: 'center', width: '100%' }}>
        <IconButton onClick={edit}>
          <Icon name='edit' customSize={20} padding='1px' />
        </IconButton>
        <Typography
          className={classes.displayName}
          variant='h2'
          onClick={edit}
        >
          {image.displayName}
        </Typography>
      </FlexRow>
    )
  }
}

ImageDisplayName.propTypes = {
  image: PropTypes.shape({
    imageId: PropTypes.string,
    displayName: PropTypes.string
  })
}

export default ImageDisplayName
