import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ImageThumbnail from './ImageThumbnail'

const useStyles = makeStyles((theme) => ({
  imageList: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fit, 170px)',
    columnGap: theme.layout.gap.g10,
    rowGap: theme.layout.gap.g10
  }
}))

function ImageList ({ images, onSelect, selectedImage }) {
  const classes = useStyles()

  return (
    <div className={classes.imageList}>
      {images.map((image) => (
        <ImageThumbnail
          key={image.imageId}
          thumbnailUrl={image.thumbnailUrl}
          description={image.description}
          displayName={image.displayName}
          onClick={() => onSelect(image)}
          selected={selectedImage?.imageId === image.imageId}
        />
      ))}
    </div>
  )
}

ImageList.propTypes = {
  images: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selectedImage: PropTypes.string
}

export default ImageList
