/* eslint-disable react/jsx-key */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useColumnOrder, useExpanded, useFlexLayout, usePagination, useSortBy, useTable } from 'react-table'
import { LinearProgress } from '@material-ui/core'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { useSticky } from 'react-table-sticky'
import StickySuperHeader from '../StickySuperHeader'
import { useTablePositioningContext } from '../StickySuperHeader/TablePositioningContext'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import { useDeepCompareMemo, useHorizontalOverflow } from '../../../hooks'
import ExpandyIcon from './ExpandyIcon'
import TableWrapper from './TableWrapper'
import { useTableStyles } from './tableStyles'

/**
 * Table used for presentation purposes.
 * Assumptions are that the data is mainly client-side
 * @param columns
 * @param data
 * @param {bool} expandable - does the table have expandable rows?
 * @param {function} onRowClick - callback when a row gets clicks
 * @param {boolean} loading - is the table loading? controls the horizontal loading indicator
 * @param {boolean} sortable - is the table client-size sortable?
 * @param {[]} defaultSort - the default sort configuration for the table
 * @param {string} className
 * @param {'bordered'} variant
 * @param {boolean} autoSticky
 * @param {boolean} defaultExpanded
 * @param {object} depthStyles - styles to apply to depth levels on expandable tables
 * @return {JSX.Element}
 * @constructor
 */
const PresentationTable = forwardRef(({
  columns,
  data,
  expandable = false,
  onRowClick = noop,
  loading = false,
  sortable = false,
  defaultSort = [],
  className = '',
  variant,
  autoSticky = false,
  defaultExpanded = false,
  depthStyles,
  includeFooter
}, ref) => {
  const { hasHorizontalOverflow, containerRefCallback } = useHorizontalOverflow(autoSticky)

  const { superHeaderRect } = useTablePositioningContext()
  const classes = useTableStyles({
    columns,
    expandable,
    loading,
    superHeaderRect,
    hasHorizontalOverflow,
    depthStyles
  })

  const hiddenColumns = useMemo(
    () => columns.filter(({ hidden }) => hidden).map((col) => col.id),
    [columns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    flatRows,
    flatHeaders,
    allColumns,
    setHiddenColumns,
    setColumnOrder,
    toggleAllRowsExpanded
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualExpandedKey: 'expanded',
      disableSortBy: !sortable,
      disableSortRemove: true,
      pageCount: -1,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetExpanded: !defaultExpanded, // expanded state will change
      initialState: {
        sortBy: defaultSort,
        hiddenColumns
      }
    },
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useSticky,
    useColumnOrder
  )

  const tableProps = useDeepCompareMemo(() => ({
    flatHeaders,
    flatRows,
    allColumns,
    setColumnOrder,
    setHiddenColumns
  }), [flatHeaders, flatRows, allColumns, setHiddenColumns])

  useImperativeHandle(ref, () => tableProps, [tableProps])

  const rowClickHandler = useCallback(row => (e) => {
    if (expandable) {
      row.toggleRowExpanded()
    }
    if (onRowClick) {
      onRowClick(row)
    }
  }, [expandable, onRowClick])

  useEffect(() => {
    if (defaultExpanded && !loading) {
      toggleAllRowsExpanded()
    }
  }, [defaultExpanded, loading, toggleAllRowsExpanded])

  const variantClass = (variant in classes) ? classes[variant] : classes.presentationTable
  return (
    <div className={clsx(variantClass, { [className]: Boolean(className), bordered: variant === 'bordered' })}>
      <LinearProgress classes={{ root: classes.sparkRoot }} variant='indeterminate' />
      <div ref={containerRefCallback} className='__table __sticky' {...getTableProps()}>
        <div className='__thead'>
          {headerGroups.map(headerGroup => {
            return (
              <div className='__headerGroup' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <div
                    className={clsx('__th', {
                      __right: column.alignRight || column.align === 'right',
                      __center: column.alignCenter || column.align === 'center'
                    })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {sortable && column.isSorted
                      ? (
                        <span className='__sort-icon'>
                          {column.isSortedDesc
                            ? <Icon name={ICON_NAMES.down} />
                            : <Icon name={ICON_NAMES.up} />}
                        </span>
                      ) : null}
                  </div>
                ))}
              </div>
            )
          })}
        </div>
        <div className='__tbody' {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            const rowProps = row.getRowProps()
            return (
              <div
                key={rowProps.key}
                className={clsx('__tr', {
                  __child: (row.depth > 0),
                  __expanded: (row.isExpanded),
                  __expandable: expandable
                }, `__depth_${row.depth}`, row.original.className)}
              >
                <div className='__tr-wrapper' {...rowProps}>
                  {row.cells.map((cell, index) => (
                    <div
                      onClick={rowClickHandler(row)}
                      className={clsx('__td', {
                        __right: cell.column.alignRight || cell.column.align === 'right',
                        __center: cell.column.alignCenter || cell.column.align === 'center'
                      })}
                      {...cell.getCellProps()}
                    >
                      {index === 0 && expandable ? (
                        <div className='__expand-container'>
                          <ExpandyIcon className='__expand-handle' enabled={row.original?._next !== null} expanded={row.isExpanded} loading={row.original?._subRowsFetching} />
                          {cell.render('Cell')}
                        </div>
                      ) : cell.render('Cell')}
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
        {includeFooter ? (
          <div className='__tfoot'>
            {footerGroups.map(footerGroup => {
              return (
                <div className='__footerGroup' {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map(column => (
                    <div
                      className={clsx('__tf', {
                        __right: column.alignRight || column.align === 'right',
                        __center: column.alignCenter || column.align === 'center'
                      })}
                      {...column.getFooterProps()}
                    >
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
})

PresentationTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  expandable: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  sortable: PropTypes.bool,
  defaultSort: PropTypes.array,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['bordered', 'presentationTable', 'glass']),
  autoSticky: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  depthStyles: PropTypes.object,
  includeFooter: PropTypes.bool
}

PresentationTable.defaultProps = {
  variant: 'presentationTable',
  includeFooter: false
}

export default Object.assign(PresentationTable, {
  Wrapper: TableWrapper,
  SuperHeader: StickySuperHeader
})
