import React, { useCallback, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Checkbox } from '@material-ui/core'
import SydButton from '../../../../commonDesign/Button'
import SydModalActions from '../../../../commonDesign/SydModal/SydModalActions'
import ImageInput from '../ImageInput'
import { useImageUpload } from '../../../../../api/media'

const useSubmitter = (form, onComplete, addAnotherRef, reset) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: uploadImage } = useImageUpload({ waitForProcessingComplete: true })
  const onSubmit = useCallback(async (formData) => {
    const command = {
      fileName: formData.image.name,
      displayName: formData.image.displayName,
      file: formData.image.file
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await uploadImage(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to upload image')
      }
      // eslint-disable-next-line no-debugger
      if (!addAnotherRef.current.checked) {
        onComplete(result)
      } else {
        reset()
      }
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [uploadImage, setProcessing, onComplete, setError, addAnotherRef, reset])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function AddImageForm ({ onCancel, onComplete }) {
  const addAnotherRef = useRef(null)
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      image: null
    }
  })

  const { reset } = form
  const _reset = useCallback(() => {
    reset({
      image: null
    })
  }, [reset])

  const { submitter, processing } = useSubmitter(form, onComplete, addAnotherRef, _reset)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name='image'
          control={form.control}
          render={(f) => (
            <ImageInput {...f.field} />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <div>
            <Checkbox inputRef={addAnotherRef} disabled={processing} />
            Add Another
          </div>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            processing={processing}
            variant='primary'
            size='md'
            onClick={submitter}
          >
            Save
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

AddImageForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default AddImageForm
