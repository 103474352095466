import { useMemo } from 'react'
import { useAppContext } from '../../../redux/slices/appContext'

export const useMediaScope = ({ levelTypeId, levelId }) => {
  const { clientId } = useAppContext()
  return useMemo(() => {
    if (levelTypeId === 201) {
      return {
        levelTypeId,
        levelId: clientId
      }
    }
    return {
      levelTypeId,
      levelId
    }
  }, [levelTypeId, levelId, clientId])
}
