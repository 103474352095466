import React from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import ErrorBoundary from '../../../../../molecules/ErrorBoundary'
import DocumentsSection from './DocumentsSection'

function DocumentsTool () {
  return (
    <ErrorBoundary name='Asset Documents Tab'>
      <FadeIn>
        <Card className={{ position: 'relative' }}>
          <DocumentsSection />
        </Card>
      </FadeIn>
    </ErrorBoundary>
  )
}

export default DocumentsTool
