import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSydMenuContext } from './SydMenuContext'

const SydMenuContent = ({ value, equalityComparer, index, children }) => {
  const { selectedValue } = useSydMenuContext()
  const isSelected = useMemo(() => {
    if (index && !selectedValue) return true

    return equalityComparer(value, selectedValue)
  }, [value, selectedValue, equalityComparer, index])

  const childrenIsFunc = useMemo(() => typeof children === 'function', [children])

  return isSelected
    ? childrenIsFunc
      ? children()
      : children
    : null
}

SydMenuContent.propTypes = {
  value: PropTypes.any.isRequired,
  equalityComparer: PropTypes.func,
  index: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
}

SydMenuContent.defaultProps = {
  equalityComparer: (a, b) => a === b,
  index: false
}

export default SydMenuContent
