import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import TableSkeleton from '../../atoms/TableSkeleton'
import PresentationTable from '../PresentationTable'
import { useConfigColumns } from '../../../hooks/useConfigColumns'
import { useViewContext } from '../../../redux/slices/viewContext'
import { useAppContext } from '../../../redux/slices/appContext'
import { LEVEL_TYPES } from '../../../constants'
import { useGetHoldingsData } from './hooks'

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: '100%',
    marginTop: '20px'
  },
  rightAligned: {
    fontSize: '14px',
    textAlign: 'right',
    padding: '6px 12px',
    fontWeight: 400,
    lineHeight: '30px'
  },
  leftAligned: {
    fontSize: '14px',
    textAlign: 'left',
    padding: '6px 12px',
    fontWeight: 400,
    lineHeight: '30px'
  }
}))

const normalizeData = (data, splitAssetClassTagIds, assetClassTagId) => {
  const filteredData = data.filter(row => assetClassTagId
    ? row.assetClassTagId === assetClassTagId
    : !splitAssetClassTagIds.includes(row.assetClassTagId)
  )
  const totalHoldingValue = filteredData.reduce((acc, curr) => acc + curr.endingValue ?? 0, 0)
  return filteredData.map(row => ({
    ...row,
    allocationPercentage: row.endingValue / totalHoldingValue
  }))
}

function VisualBalanceHoldingsTable ({ columns: _columns, defaultSort: _defaultSort, splitAssetClassTagIds }) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const { mainDate, selectedBlock, selectedAccount } = useViewContext()

  const coreDataParams = useMemo(() => ({
    dateRange: {
      endDate: mainDate,
      startDate: mainDate
    },
    levelFilters: {
      dateType: 'all',
      clientIds: [clientId],
      calcType: 'balance',
      ...(selectedBlock?.accountCategoryGroupId && { accountCategoryIds: [selectedBlock?.accountCategoryGroupId] }),
      levelTypes: [
        LEVEL_TYPES.CLIENT,
        selectedAccount?.accountId ? LEVEL_TYPES.ACCOUNTS : '',
        LEVEL_TYPES.ASSETS
      ],
      ...(selectedAccount?.accountId && { accountIds: [selectedAccount?.accountId] })
    }
  }), [selectedAccount, selectedBlock, clientId, mainDate])
  const { data: rows, isLoading, isFetching } = useGetHoldingsData(
    coreDataParams,
    { mapper: (data) => normalizeData(data, splitAssetClassTagIds, selectedBlock?.assetClassTagId) }
  )

  const { columns, defaultSort } = useConfigColumns({
    columnsConfig: {
      columns: _columns,
      defaultSort: _defaultSort
    }
  })

  if (isLoading || isFetching) {
    return (
      <div className={classes.tableContainer}>
        <TableSkeleton firstColumnWithAvatar={false} columns={3} />
      </div>
    )
  }

  return (
    <div className={classes.tableContainer}>
      <PresentationTable.Wrapper>
        <PresentationTable
          data={rows}
          columns={columns}
          defaultSort={defaultSort}
          sortable
        />
      </PresentationTable.Wrapper>
    </div>
  )
}

VisualBalanceHoldingsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultSort: PropTypes.arrayOf(PropTypes.object),
  splitAssetClassTagIds: PropTypes.arrayOf(PropTypes.number)
}

export default VisualBalanceHoldingsTable
