import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useGetImagesByLevel } from '../../../../../../api/media'
import Loading from '../../../../../molecules/Loading'
import EmptySection from '../../../../../atoms/EmptySection'
import ImagePurpose from './ImagePurpose'

function LevelImages ({
  levelTypeId,
  levelId,
  purposes,
  onAttachImage,
  onRemoveImageAttachment,
  onSelectImage,
  selectedImage
}) {
  const { data: images, isLoading } = useGetImagesByLevel(levelTypeId, levelId)

  if (isLoading) {
    return (
      <Loading />
    )
  }

  if (!purposes?.length) {
    return (
      <EmptySection title='No image purposes defined' description='' />
    )
  }

  return (
    <Grid container spacing={3}>
      {(purposes || []).map(purpose => (
        <Grid item xs={12} key={`purpose_${purpose.purposeId}`}>
          <ImagePurpose
            images={images?.images || []}
            purpose={purpose}
            onAttachImage={onAttachImage}
            onRemoveImageAttachment={onRemoveImageAttachment}
            onSelectImage={onSelectImage}
            selectedImage={selectedImage}
          />
        </Grid>
      ))}
    </Grid>
  )
}

LevelImages.propTypes = {
  levelTypeId: PropTypes.number,
  levelId: PropTypes.number,
  purposes: PropTypes.array,
  onAttachImage: PropTypes.func,
  onRemoveImageAttachment: PropTypes.func,
  onSelectImage: PropTypes.func,
  selectedImage: PropTypes.any
}

export default LevelImages
