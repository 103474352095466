import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useSydMenuContext } from './SydMenuContext'

const useStyles = makeStyles((theme) => ({
  sydMenuList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g5,

    '& .__menu-item': {
      width: '100%',
      fontSize: theme.typography.fontSizes.xxl,
      transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out, outline 0.2s ease-in-out',
      paddingTop: theme.layout.padding.p5,
      paddingRight: theme.layout.padding.p10,
      paddingBottom: theme.layout.padding.p5,
      paddingLeft: theme.layout.padding.p10,
      outline: '1px solid transparent',
      userSelect: 'none',

      borderRadius: theme.layout.radius.loose,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.gray.dark
      },
      '&.__selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  }
}))

function SydMenuList () {
  const classes = useStyles()
  const { options, selectedValue, selectValue } = useSydMenuContext()

  return (
    <div className={classes.sydMenuList}>
      {options.map(option => {
        const isSelected = selectedValue === option.key
        return (
          <div
            role='button'
            key={option.key}
            className={clsx('__menu-item', { __selected: isSelected })}
            onClick={() => selectValue(option.key)}
          >
            <div>{option.value?.label || option.value}</div>
          </div>
        )
      })}
    </div>
  )
}

export default SydMenuList
