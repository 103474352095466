import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { CircularProgress, makeStyles, TextField } from '@material-ui/core'
import noop from 'lodash/noop'
import Icon from '../../atoms/Icon'
import { ICON_NAMES } from '../../../constants'
import { mapInputStyles } from './styles'

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    minWidth: '300px',
    width: '100%',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'outline 200ms ease-in-out',
    outline: '1px solid transparent',
    '&:has(:-internal-autofill-selected)': {
      backgroundColor: 'rgb(232, 240, 254)'
    },
    '&:focus-within': {
      outline: `1px solid ${theme.palette.primary.main}`
    }
  },
  input: mapInputStyles(theme),
  paper: {
    boxShadow: '0px 4px 12px 0px rgba(24, 27, 53, 0.2)'
  },
  sydMenuList: {
    borderRadius: '8px',
    padding: 0,
    minWidth: '300px',
    background: '#fff'
  },
  sydMenuItem: {
    padding: '8px',
    fontSize: '14px',
    '&.Mui-selected': {
      fontWeight: 700,
      background: '#F5F6F7'
    }
  },
  optionSubLabel: {
    color: theme.palette.gray.A600,
    fontSize: '12px',
    display: 'block'
  },
  endAdornment: {
    marginRight: '8px'
  }
}))

const SydAutocomplete = forwardRef(function SydAutocomplete ({
  className,
  name,
  onChange = noop,
  onBlur = noop,
  onInputChange = noop,
  value: _value = [],
  placeholder,
  defaultValue,
  isLoading = false,
  disabled = false,
  size = 'lg',
  options: _options = [],
  multiple = false
}, ref) {
  const classes = useStyles({ size, disabled })
  const options = Array.isArray(_options) ? _options : []
  const [value, setValue] = useState(defaultValue ?? (multiple ? [] : null))

  const inputRef = useRef(null)
  useImperativeHandle(ref, () => ({
    clear: () => setValue(defaultValue),
    value
  }), [value, defaultValue])

  useEffect(() => {
    if (_value === undefined) return
    if (value === _value) return
    if (
      Array.isArray(_value) &&
      Array.isArray(value) &&
      _value.every((v, i) => {
        if (v.value) {
          return v.value === value[i].value
        }
        return v === value[i]
      })
    ) return
    setValue(_value)
  }, [_value, value, setValue])

  const handleOnInputChange = useCallback((event, value, reason) => {
    if (reason === 'input') {
      onInputChange(event, value)
    }
  }, [onInputChange])

  const handleOnChange = useCallback((e, value) => {
    setValue && setValue(value)
    onChange(e, value)
  }, [onChange])

  return (
    <div>
      <Autocomplete
        ref={inputRef}
        options={options}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        defaultValue={defaultValue}
        clearOnBlur={false}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
        value={value}
        loading={isLoading}
        multiple={multiple}
        classes={{
          root: clsx(
            classes.sydSelect,
            { [classes.disabled]: disabled },
            className && className
          ),
          paper: classes.paper,
          listbox: classes.sydMenuList,
          endAdornment: classes.endAdornment
        }}
        renderInput={(params) => (
          <TextField
            placeholder={!value?.length && placeholder}
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: {
                input: classes.input
              },
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color='primary' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            classes={{
              root: classes.inputRoot
            }}
          />
        )}
        getOptionLabel={(option) => (option?.label ?? option ?? '').toString()}
        getOptionSelected={(option, value) => (option?.value ?? option) === (value?.value ?? value)}
        renderOption={(option) => (
          <div className={classes.sydMenuItem}>
            {option?.label ?? option}
            {option.subLabel && <span className={classes.optionSubLabel}>{option.subLabel}</span>}
          </div>
        )}
        filterOptions={(options, params) => options.filter(opt => opt)}
        popupIcon={<Icon name={ICON_NAMES.chevronDown} customSize='20px' />}
        closeIcon={<Icon name={ICON_NAMES.close} customSize='18px' />}
      />
    </div>
  )
})

const valueObject = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
})

SydAutocomplete.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, valueObject, PropTypes.arrayOf(valueObject)]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, valueObject, PropTypes.arrayOf(valueObject)]),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  multiple: PropTypes.bool
}

export default SydAutocomplete
