import { useMemo } from 'react'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchClients } from '../../../api/clients'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

export const useMetrics = (datapoints) => {
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    return {
      limit: 1,
      filters: {
        clientId: [{ op: 'eq', value: clientId }]
      },
      includes: {
        keyDatapoints: datapoints.map(x => x.code)
      }
    }
  }, [datapoints, clientId])

  const { data, isLoading } = useSearchClients(query)

  const { formatter } = useFormattingContext()
  const formattedValues = useMemo(() => {
    if (!data?.data?.length) return []
    const kdps = data.data.at(0).keyDatapoints
    return datapoints.map(dp => {
      const match = kdps[dp.code]
      if (!match) return null
      return {
        name: match.displayName,
        value: formatter(match.value, dp.format),
        tooltip: dp.tooltip
      }
    }).filter(x => x)
  }, [data, datapoints, formatter])

  return {
    data,
    isLoading,
    formattedValues
  }
}
