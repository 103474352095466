import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import Icon from '../../atoms/Icon'

function StatusReportLink ({ to, target }) {
  const location = useLocation()
  const destination = useMemo(() => {
    const pathSegments = location.pathname.split('/')
    pathSegments.splice(-1)
    pathSegments.push(to)
    return pathSegments.join('/')
  }, [to, location])

  return (
    <Link to={destination} target={target}>
      <Icon name={target === '_blank' ? 'openInNew' : 'link'} />
    </Link>
  )
}

StatusReportLink.propTypes = {
  to: PropTypes.string,
  target: PropTypes.string
}

export default StatusReportLink
