import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import { LinearProgress, makeStyles } from '@material-ui/core'
import RoundedButton from '../../atoms/RoundedButton'
import FadeIn from '../../molecules/Transitions/FadeIn'
import DocumentSearchResult from './DocumentSearchResult'

const useStyles = makeStyles((theme) => ({
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    minHeight: '15rem',
    '& header': {
      fontSize: '22px'
    }
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px'
  },
  resultHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 0',
    fontSize: '18px'
  },
  totals: {
    fontWeight: '100',
    fontSize: '16px'
  }
}))

function DocumentSearchResults ({
  loading,
  error,
  documents,
  addAttachment,
  selected,
  setSelected
}) {
  const classes = useStyles()
  const itemSelect = useCallback((document) => {
    setSelected(prev => {
      const index = prev.findIndex(x => x.id === document.documentId)
      if (index === -1) {
        return [...prev, document]
      }
      const copy = [...prev]
      copy.splice(index, 1)
      return copy
    })
  }, [setSelected])

  const selectAll = useCallback(() => {
    setSelected(prev => prev.length === documents.length ? [] : [...documents])
  }, [documents, setSelected])

  const total = useMemo(() => {
    if (selected?.length && documents?.length) {
      return `(${selected.length}/${documents.length})`
    }
    if (documents?.length) {
      return `(${documents.length})`
    }
    return ''
  }, [selected, documents])

  if (loading) {
    return (
      <LinearProgress classes={{ root: classes.sparkRoot }} variant='indeterminate' />
    )
  }

  if (error) {
    return (<div>{error.toString()}</div>)
  }

  if (!documents?.length) {
    return (
      <FadeIn className={classes.noData}>
        <header>No Documents Found</header>
        <RoundedButton primary startIcon={<AddIcon />} onClick={addAttachment}>
          Add a Document?
        </RoundedButton>
      </FadeIn>
    )
  }

  return (
    <div className={classes.results}>
      <header className={classes.resultHeader}>
        <div>Search Results <span className={classes.totals}>{total}</span></div>
        <div>
          <RoundedButton tertiary size='small' onClick={selectAll}>Select All</RoundedButton>
        </div>
      </header>
      {documents?.map((d, i) => (
        <DocumentSearchResult
          key={d.document.documentId}
          result={d.document}
          index={i}
          onSelect={itemSelect}
          selected={selected}
        />
      ))}
    </div>
  )
}

DocumentSearchResults.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  documents: PropTypes.array,
  addAttachment: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func
}

export default DocumentSearchResults
