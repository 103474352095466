import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { usePolicy } from '../../../../hooks/usePolicy'
import EmptySection from '../../../atoms/EmptySection'
import { useSearchAssets } from '../../../../api/groups'
import { AssetProvider } from './AssetProvider'
import AssetTabs from './AssetTabs'
import AssetDetailsHeader from './Header'

const useStyles = makeStyles({
  page: {
    margin: '1rem'
  }
})

const useAssetId = () => {
  const { id } = useParams()
  return isNaN(+id) ? [-1] : [+id]
}

const useAssetById = (assetId) => {
  const query = useMemo(() => {
    return {
      filters: {
        assetId
      },
      includes: {
        classificationTags: true,
        assetType: true,
        characteristics: true
      },
      take: 1
    }
  }, [assetId])
  const { data, ...rest } = useSearchAssets(query)

  const asset = useMemo(() => {
    if (!data?.length) return null
    return data.at(0)
  }, [data])

  return {
    asset,
    ...rest
  }
}

function AssetDetails () {
  const classes = useStyles()
  const [assetId] = useAssetId()
  const canView = usePolicy('admin_edit_assetdetails')
  const { asset, isLoading, isFetching, error } = useAssetById(assetId)

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (error) {
    return (
      <EmptySection
        title='There was an error loading this Asset'
        description=''
      />
    )
  }

  if (!asset) {
    return (
      <EmptySection
        title='Asset Not Found'
        description=''
      />
    )
  }

  return (
    <div className={classes.page}>
      <AssetProvider asset={asset} isFetching={isFetching}>
        <AssetDetailsHeader />
        <AssetTabs />
      </AssetProvider>
    </div>
  )
}

export default AssetDetails
