import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAccountTearSheetView } from '../../../api/abundanceEngine'
import Loading from '../../molecules/Loading'
import { useHideSideMenuEffect, useSetAppContext } from '../../../redux/slices/appContext'
import { useSearchAccounts } from '../../../api/accounts'
import TearSheetView from './TearSheetView'
import TearSheetProvider from './TearSheetProvider'

const useAccountId = () => {
  const { accountId } = useParams()
  return useMemo(() => {
    const result = Number(accountId)
    if (isNaN(result)) return null
    return result
  }, [accountId])
}

function AccountTearSheet () {
  const accountId = useAccountId()
  useHideSideMenuEffect([])
  const accountQuery = useMemo(() => {
    return { filters: { accountId }, take: 1 }
  }, [accountId])
  const { data: accounts, isLoading: accountLoading } = useSearchAccounts(accountQuery)
  const { data: view, isLoading: viewLoading } = useAccountTearSheetView(accountId)
  const tearSheetValue = useMemo(() => {
    const _accounts = accounts || []
    const subject = _accounts?.length ? _accounts.at(0) : null
    return {
      levelTypeId: 1,
      levelId: subject?.accountId
    }
  }, [accounts])
  const setAppContext = useSetAppContext()

  useEffect(() => {
    if (tearSheetValue?.subject?.accountName) {
      setAppContext({
        viewTitle: tearSheetValue.subject.accountName
      })
      return () => {
        setAppContext({
          viewTitle: null
        })
      }
    }
  }, [tearSheetValue, setAppContext])

  if (accountLoading || viewLoading) {
    return (
      <Loading />
    )
  }

  return (
    <TearSheetProvider value={tearSheetValue}>
      <TearSheetView
        jsx={view?.jsxView}
        bindings={{
          accountId,
          view
        }}
      />
    </TearSheetProvider>
  )
}

export default AccountTearSheet
