import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import VaultCollection from '../../../../../organisms/DocumentVault/VaultCollection'
import { useAssetDetails } from '../../AssetProvider'
import VaultContextProvider from '../../../../../organisms/DocumentVault/VaultContextProvider'
import ViewToggles from '../../../../../organisms/DocumentVault/Controls/ViewToggles'

const useStyles = makeStyles(theme => ({
  documentsSection: {
    '& .__title': {
      height: '50px',
      paddingBottom: theme.layout.padding.p10,
      fontSize: theme.typography.fontSizes.h4
    }
  }
}))

function DocumentsSection () {
  const classes = useStyles()
  const { asset } = useAssetDetails()
  const levels = useMemo(() => {
    return [{
      levelTypeId: 3,
      levelIds: [asset.assetId]
    }]
  }, [asset])

  return (
    <VaultContextProvider levels={levels}>
      <div className={classes.documentsSection}>
        <div className='__title'>
          <ViewToggles />
        </div>
        <VaultCollection canUpload />
      </div>
    </VaultContextProvider>
  )
}

export default DocumentsSection
