import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { ALERT_SEVERITY, ALERT_VARIANT, ICON_NAMES } from '../../../../../../constants'
import SydButton from '../../../../../commonDesign/Button'
import SydModal from '../../../../../commonDesign/SydModal'
import { useDeleteTag } from '../../../../../../api/documentVault'
import Alert from '../../../../../atoms/Alert'

const DeleteTagButtonCell = ({ row, onFinish }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleOnClick = (e) => {
    e.stopPropagation()
    setShowDeleteModal(true)
  }

  const { mutateAsync, isLoading, isError } = useDeleteTag()
  const handleDelete = useCallback(async () => {
    await mutateAsync(row.original.id)
    onFinish()
    setShowDeleteModal(false)
  }, [mutateAsync, onFinish, row.original.id])

  return (
    <>
      <SydButton
        size='sm'
        onClick={handleOnClick}
        icon={ICON_NAMES.delete}
        priority='warning'
        variant='primary'
      >
        Delete
      </SydButton>

      <SydModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        disablePortal={false}
        title='Delete tag'
        subtitle='Are you sure you want to delete this tag?'
      >
        {isError && (
          <Box mb={4}>
            <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>An error occurred while deleting the tag</Alert>
          </Box>
        )}
        <Box width='100%' display='flex' justifyContent='space-between'>
          <SydButton
            onClick={() => setShowDeleteModal(false)}
            disabled={isLoading}
          >
            Cancel
          </SydButton>
          <SydButton
            variant='primary'
            priority='warning'
            onClick={handleDelete}
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : 'Delete'}
          </SydButton>
        </Box>
      </SydModal>
    </>
  )
}

DeleteTagButtonCell.propTypes = {
  onFinish: PropTypes.func,
  row: {
    original: PropTypes.object
  }
}

export default DeleteTagButtonCell
