import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, Tooltip } from '@material-ui/core'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import { useAssetTearSheetList } from '../../../../../../api/abundanceEngine'
import Loading from '../../../../../molecules/Loading'
import SectionHeader from '../../../shared/SectionHeader'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import NullValue from '../../../../../molecules/NullValue'
import SydButton from '../../../../../commonDesign/Button'
import FlexRow from '../../../../../molecules/FlexRow'
import ErrorBoundary from '../../../../../molecules/ErrorBoundary'
import PreviewDialog from './PreviewDialog'
import ModifyDialog from './ModifyDialog'

function TearSheets ({ asset }) {
  const { data, isLoading } = useAssetTearSheetList(3)
  const assignedTearSheet = useMemo(() => {
    return (data?.tearSheets || []).find(x => x.tearSheetId === asset.tearSheetId)
  }, [data, asset])
  const preview = useRef(null)
  const modify = useRef(null)

  if (isLoading) {
    return (
      <FadeIn>
        <Card>
          <Loading />
        </Card>
      </FadeIn>
    )
  }

  return (
    <ErrorBoundary name='Asset Tear Sheet Tab'>
      <FadeIn>
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SectionHeader slim text='Tear Sheet' />
            </Grid>
            <Grid item xs={12}>
              {assignedTearSheet ? (
                <Tooltip title={assignedTearSheet.description}>
                  <div>
                    <SydLabel label='Assignment'>
                      <SydInput disabled value={assignedTearSheet.name} />
                    </SydLabel>
                  </div>
                </Tooltip>
              ) : (
                <SydLabel label='Assignment'>
                  <NullValue value='No Assignment' />
                </SydLabel>
              )}
            </Grid>
            <Grid item xs={12}>
              <FlexRow style={{ justifyContent: 'flex-end', gap: '10px' }}>
                <SydButton size='sm' variant='outline' onClick={() => modify.current.open(asset, assignedTearSheet)} icon='edit'>Modify</SydButton>
                <SydButton disabled={!assignedTearSheet} size='sm' variant='outline' onClick={() => preview.current.open(asset, assignedTearSheet)} icon='image'>Preview</SydButton>
              </FlexRow>
            </Grid>
          </Grid>
        </Card>
        <ModifyDialog ref={modify} />
        <PreviewDialog ref={preview} />
      </FadeIn>
    </ErrorBoundary>
  )
}

TearSheets.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number,
    tearSheetId: PropTypes.number
  })
}

export default TearSheets
