import React from 'react'
import PropTypes from 'prop-types'
import ClientHeader from './ClientHeader'
import AccountHeader from './AccountHeader'
import AssetHeader from './AssetHeader'

function LevelHeader ({ levelTypeId, levelId }) {
  if (levelTypeId === 1) {
    return (
      <AccountHeader accountId={levelId} />
    )
  }

  if (levelTypeId === 3) {
    return (
      <AssetHeader assetId={levelId} />
    )
  }

  if (levelTypeId === 201) {
    return (
      <ClientHeader clientId={levelId} />
    )
  }

  return null
}

LevelHeader.propTypes = {
  levelTypeId: PropTypes.number,
  levelId: PropTypes.number
}

export default LevelHeader
