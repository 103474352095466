import DocumentDownloadCell from './Cells/DocumentDownloadCell'
import VisibilityCell from './Cells/VisibilityCell'
import TagsCell from './Cells/TagsCell'
import DocumentEditCell from './Cells/DocumentEditCell'
import DocumentThumbnailCell from './Cells/DocumentThumbnailCell'

const predefinedColumns = new Map([
  ['name', {
    id: 'name',
    accessor: 'name',
    key: 'name',
    Header: 'Name'
  }],
  ['thumbnail', {
    id: 'thumbnail',
    accessor: 'thumbnailUrl',
    key: 'thumbnail',
    Header: 'Thumbnail',
    Cell: DocumentThumbnailCell
  }],
  ['download', {
    id: 'download',
    accessor: 'document.documentId',
    key: 'download',
    Header: 'Download',
    Cell: DocumentDownloadCell
  }],
  ['edit', {
    id: 'edit',
    accessor: 'document.documentId',
    key: 'edit',
    Header: 'Edit',
    Cell: DocumentEditCell
  }],
  ['description', {
    id: 'description',
    accessor: 'document.description',
    key: 'description',
    Header: 'Description'
  }],
  ['visibility', {
    id: 'visibility',
    accessor: 'document.visibility',
    key: 'visibility',
    Header: 'Visibility',
    Cell: VisibilityCell,
    width: 100
  }],
  ['tags', {
    id: 'tags',
    accessor: 'document.tags',
    key: 'tags',
    Header: 'Tags',
    Cell: TagsCell
  }]
])

export const getColumnConfig = (columns) => {
  const cols = columns.map(column => {
    if (typeof column === 'object') {
      const predefinedColumn = predefinedColumns.get(column.id ?? column.key ?? column.accessor)
      if (!predefinedColumn) return column
      return {
        ...predefinedColumn,
        ...column,
        Header: column.header ?? column.Header ?? predefinedColumn.Header
      }
    }
    return predefinedColumns.get(column)
  }).filter(x => !!x)

  if (!cols.find(x => x.id === 'name')) {
    cols.unshift(predefinedColumns.get('name'))
  }
  return cols
}
