import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import EditTagButtonCell from './EditTagButtonCell'
import DeleteTagButtonCell from './DeleteTagButtonCell'

const TagActionsCell = ({ row, onClick, onFinish, ...props }) => {
  return (
    <Box display='flex' gridGap='6px'>
      <EditTagButtonCell row={row} {...props} onClick={onClick} />
      {!row?.original?.documentCount && (
        <DeleteTagButtonCell row={row} onFinish={onFinish} />
      )}
    </Box>
  )
}

TagActionsCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  row: {
    original: {
      documentCount: PropTypes.number
    }
  }
}

export default TagActionsCell
