import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFormattingContext } from '../../../../organisms/FormattingProvider/FormattingContext'

const useStyles = makeStyles((theme) => ({
  imageData: {
    width: '100%',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: theme.layout.gap.g10,
    rowGap: theme.layout.gap.g5,
    '& *:nth-child(2n)': {
      fontWeight: theme.typography.weights.light,
      textAlign: 'left'
    },
    '& *:nth-child(2n - 1)': {
      fontWeight: theme.typography.weights.bold
    }
  }
}))

function ImageData ({ image }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()

  if (!image) return null

  return (
    <div className={classes.imageData}>
      <div>Image Id</div>
      <div>{image.imageId}</div>
      <div>Orig. File Name</div>
      <div>{image.fileName}</div>
      <div>Orig. Image Type</div>
      <div>{image.mediaType}</div>
      <div>Uploaded Date</div>
      <div>{formatter(image.createdDate, 'timestamp')}</div>
      <div>Dimensions</div>
      <div>{image.width} x {image.height}</div>
      <div>File Size</div>
      <div>{formatter(image.size, '0.00 b')}</div>
      <div>Description</div>
      <div>{image.description}</div>
    </div>
  )
}

ImageData.propTypes = {
  image: PropTypes.shape({
    imageId: PropTypes.string,
    fileName: PropTypes.string,
    displayName: PropTypes.string,
    mediaTypeId: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
    description: PropTypes.string,
    mediaType: PropTypes.string,
    createdDate: PropTypes.string
  })
}

export default ImageData
