import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import ImageThumbnail from '../../ImageThumbnail'
import FlexRow from '../../../../../molecules/FlexRow'
import ImageAssociationMenu from '../../ImageAssociationMenu'

const useStyles = makeStyles((theme) => ({
  imagePurposeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.layout.gap.g10
  },
  imagePurposeList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.layout.gap.g10
  }
}))

function ImagePurpose ({
  images,
  purpose,
  onAttachImage,
  onRemoveImageAttachment,
  onSelectImage,
  selectedImage
}) {
  const classes = useStyles({ purpose })
  const _images = useMemo(() => {
    if (purpose.configuration?.single) {
      const img = images.find(x => x.purposeId === purpose.purposeId)
      return img ? [img] : undefined
    }
    return images.filter(x => x.purposeId === purpose.purposeId)
  }, [purpose, images])
  const allowMultiple = !(purpose.configuration?.single)

  return (
    <div className={classes.imagePurposeContainer}>
      <FlexRow>
        <Typography variant='h3'>{purpose.name}</Typography>
        <span> / </span>
        <Typography variant='h4'>{purpose.description}</Typography>
      </FlexRow>
      {_images?.length ? (
        <div className={classes.imagePurposeList}>
          {_images.map(img => (
            <ImageAssociationMenu
              key={`img_${img.imageId}_purpose_${purpose.purposeId}`}
              onDelete={() => onRemoveImageAttachment({
                imageId: img.imageId,
                purposeId: purpose.purposeId
              })}
            >
              <ImageThumbnail
                displayName={img.displayName}
                description={img.description}
                thumbnailUrl={img.thumbnailUrl}
                onClick={() => onSelectImage(img)}
                selected={selectedImage?.imageId === img.imageId}
              />
            </ImageAssociationMenu>
          ))}
          {allowMultiple ? (
            <ImageThumbnail
              icon='addCircle'
              displayName='Associate Image'
              onClick={() => onAttachImage(purpose)}
            />
          ) : null}
        </div>
      ) : (
        <div className={classes.imagePurposeList}>
          <ImageThumbnail
            icon='addCircle'
            displayName='Associate Image'
            onClick={() => onAttachImage(purpose)}
          />
        </div>
      )}
    </div>
  )
}

ImagePurpose.propTypes = {
  images: PropTypes.array,
  purpose: PropTypes.shape({
    configuration: PropTypes.shape({
      single: PropTypes.bool
    }),
    purposeId: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string
  }),
  onAttachImage: PropTypes.func,
  onRemoveImageAttachment: PropTypes.func,
  onSelectImage: PropTypes.func,
  selectedImage: PropTypes.object
}

export default ImagePurpose
