import React, { useCallback, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import RoundedModal from '../../../../molecules/RoundedModal'
import AttachImageForm from './form'

const AttachImageDialog = React.forwardRef(function AttachImageDialog ({ onComplete }, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })

  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  const handleComplete = useCallback((e) => {
    close()
    onComplete(e)
  }, [close, onComplete])

  useImperativeHandle(ref, () => ({
    open: (purpose, levelTypeId, levelId) => setDialogState({ levelTypeId, levelId, purpose, open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Attach Image'
      open={dialogState.open}
      onClose={close}
      size='full'
      position='top'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (
          <AttachImageForm
            purpose={dialogState.purpose}
            levelTypeId={dialogState.levelTypeId}
            levelId={dialogState.levelId}
            onComplete={handleComplete}
            onCancel={close}
          />
        ) : null}
      </div>
    </RoundedModal>
  )
})

AttachImageDialog.propTypes = {
  onComplete: PropTypes.func
}

AttachImageDialog.defaultProps = {
  onComplete: () => {}
}

export default AttachImageDialog
