
import { useMemo } from 'react'
import { useAssetSearchDeprecated } from '../../../../api/coreData'

const getDetailsQuery = (query = {}) => {
  const skip = query?.skip ?? 0
  const take = query?.take ?? 100
  const sort = query?.sort
  const includes = {
    classificationTags: true,
    assetType: true
  }
  return {
    ...query,
    includes,
    skip,
    take,
    sort,
    resultType: 'details'
  }
}

const mapSort = (sorts) => sorts?.map(({ id, desc }) => ({ field: id, dir: desc ? 'desc' : 'asc' }))

const textSearchFields = ['symbol', 'cusip', 'longName']

const assetMapSearch = (searchText) => searchText
  ? textSearchFields.reduce((prev, field) => {
    prev[field] = [{ op: 'contains', value: searchText }]
    return prev
  }, {})
  : {}

export const useTotalClassifyAssets = ({
  searchText
}) => {
  const query = useMemo(() => {
    return {
      resultType: 'total',
      textSearch: assetMapSearch(searchText)
    }
  }, [searchText])

  const {
    data,
    isLoading,
    isPreviousData,
    error
  } = useAssetSearchDeprecated(query)

  return {
    data: isLoading ? [] : data,
    loading: isLoading || isPreviousData,
    error
  }
}

export const useClassifyAssets = ({
  pageIndex,
  pageSize,
  sort,
  searchText
}) => {
  const query = useMemo(() => {
    return getDetailsQuery({
      skip: pageIndex * pageSize,
      take: pageSize,
      sort: mapSort(sort),
      textSearch: assetMapSearch(searchText)
    })
  }, [pageSize, pageIndex, sort, searchText])

  const {
    data,
    isLoading,
    isPreviousData,
    error
  } = useAssetSearchDeprecated(query)

  return {
    data: isLoading ? [] : data,
    loading: isLoading || isPreviousData,
    error
  }
}
