import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import ModifyForm from './form'

const ModifyDialog = React.forwardRef(function ModifyDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (asset, tearSheet) => setDialogState({ asset, tearSheet, open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title='Assign Tear Sheet'
      open={dialogState.open}
      onClose={close}
      position='top'
      size='full'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ModifyForm asset={dialogState.asset} tearSheet={dialogState.tearSheet} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ModifyDialog.propTypes = {
}

export default ModifyDialog
