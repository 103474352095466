import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { ICON_NAMES } from '../../../../constants'
import FadeIn from '../../../molecules/Transitions/FadeIn'
import Icon from '../../../atoms/Icon'
import FlexRow from '../../../molecules/FlexRow'
import SydInput from '../../../commonDesign/SydInput'
import SydLabel from '../../../commonDesign/SydLabel'

const useStyles = makeStyles((theme) => ({
  imageInput: {
    width: '100%',
    minHeight: '300px',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    padding: theme.layout.padding.p20
  },
  dropZone: {
    width: '100%',
    minHeight: '300px',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    position: 'relative',
    backgroundImage: 'linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%)',
    backgroundSize: '24px 24px',
    backgroundPosition: '0 0, 12px 0, 12px -12px, 0px 12px',
    padding: theme.layout.padding.p20
  },
  dropIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageInfo: {
    marginTop: theme.layout.margin.m10
  },
  imagePreview: {
    maxWidth: '90%',
    maxHeight: '50vh'
  },
  message: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.layout.padding.p5,
    paddingRight: theme.layout.padding.p10,
    paddingBottom: theme.layout.padding.p5,
    paddingLeft: theme.layout.padding.p10,
    borderRadius: theme.layout.radius.tight,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.gray.dark,
    boxShadow: theme.layout.shadow.deep
  }
}))

function getFileName (name) {
  const extension = `.${name.split('.').pop()}`
  return name.slice(0, name.length - extension.length)
}

function ImageInput ({
  disabled,
  acceptFiles,
  value,
  onChange,
  processing
}) {
  const onDropAccepted = useCallback(async ([file]) => {
    if (file) {
      const preview = await new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })
      const img = new Image()
      img.onload = () => {
        onChange({
          file,
          displayName: getFileName(file.name),
          name: file.name,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          size: file.size,
          type: file.type,
          width: img.width,
          height: img.height,
          preview
        })
      }
      img.src = preview
    }
  }, [onChange])

  const {
    getRootProps,
    getInputProps
  } =
    useDropzone({
      accept: acceptFiles,
      maxFiles: 1,
      onDropAccepted,
      disabled
    })

  const classes = useStyles()

  return (
    <div className={classes.imageInput}>
      <div {...getRootProps()} className={classes.dropZone}>
        <input {...getInputProps()} />
        {processing ? (
          <CircularProgress />
        ) : null}
        {(value?.preview) ? (
          <FlexRow style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img className={classes.imagePreview} src={value?.preview} alt={value?.displayName} />
          </FlexRow>
        ) : (
          <FadeIn className={classes.dropIcon}>
            <FlexRow className={classes.message}>
              <Icon customSize='2rem' color='#212945' name={ICON_NAMES.image} />
              Click Here or Drop a File
            </FlexRow>
          </FadeIn>
        )}
      </div>
      {(value?.preview) ? (
        <FadeIn className={classes.imageInfo}>
          <FlexRow>
            <SydLabel label='Image Name' style={{ width: '100%' }}>
              <SydInput
                minWidth='0px'
                disabled={!value.preview || processing}
                size='sm'
                value={value.displayName}
                onChange={(e) => {
                  const displayName = e.target.value
                  onChange({
                    ...value,
                    displayName
                  })
                }}
              />
            </SydLabel>
          </FlexRow>
        </FadeIn>
      ) : null}
    </div>
  )
}

ImageInput.propTypes = {
  disabled: PropTypes.bool,
  acceptFiles: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  processing: PropTypes.bool,
  value: PropTypes.object
}

ImageInput.defaultProps = {
  disabled: false,
  acceptFiles: ['image/*'],
  onChange: () => {
  }
}

export default ImageInput
