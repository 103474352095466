import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tag: ({
    backgroundColor,
    borderRadius,
    clickable,
    color,
    fontSize,
    height,
    padding,
    margin,
    disabled
  }) => ({
    height: height || '1.5rem',
    width: 'max-content',
    margin: margin || '0px',
    backgroundColor: backgroundColor || theme.palette.primary.main,
    color: color || theme.palette.white,
    // alignSelf: 'baseline',
    fontSize: fontSize || '0.8rem',
    borderRadius: borderRadius || '0.4rem',
    padding: padding || '4px 8px',
    // margin: '4px 12px',
    fontWeight: 'bold',
    opacity: disabled ? 0.7 : 1,
    ...(clickable && !disabled ? { cursor: 'pointer' } : { cursor: 'not-allowed' }),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
}))

const Tag = ({
  backgroundColor,
  borderRadius,
  clickable,
  color,
  fontSize,
  height,
  label,
  onClick,
  margin,
  padding,
  disabled
}) => {
  const classes = useStyles({
    backgroundColor,
    borderRadius,
    clickable,
    color,
    fontSize,
    height,
    margin,
    padding,
    disabled
  })
  return (
    <div className={classes.tag} onClick={onClick}>
      {label}
    </div>
  )
}

Tag.propTypes = {
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  clickable: PropTypes.bool,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  disabled: PropTypes.bool
}

Tag.defaultProps = {
  backgroundColor: '',
  borderRadius: '',
  clickable: false,
  color: '',
  fontSize: '',
  height: '',
  margin: '',
  label: '',
  onClick: noop,
  padding: '',
  disabled: false
}

export default Tag
