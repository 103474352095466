import React, { useMemo, useRef } from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import { useSectionEditing, useAssetDetails } from '../../AssetProvider'
import {
  useClassificationTagTypes
} from '../../../../../../api/coreData'
import Loading from '../../../../../molecules/Loading'
import ClassifyAssetDialog from '../dialogs/ClassifyAsset'
import ClassificationsSectionDisplay from './Display'

function ClassificationsSection () {
  const { editing, asset } = useAssetDetails()
  const sectionIsEditing = useSectionEditing('classifications')

  const query = useMemo(() => ({
    includes: {
      classificationTags: true
    }
  }), [])
  const { data: _classificationSchema, isLoading } = useClassificationTagTypes(query)
  const classificationSchema = useMemo(() => {
    if (!_classificationSchema?.length) return []
    return _classificationSchema
  }, [_classificationSchema])
  const editClassification = useRef(null)

  if (isLoading) {
    return (
      <Loading />
    )
  }

  // Do classifications one at a time with a dialog
  return (
    <>
      <FadeIn>
        <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
          <SectionHeader text='Classifications' />
          <ClassificationsSectionDisplay
            asset={asset}
            schema={classificationSchema}
            onEditClassification={(...args) => editClassification.current.open(...args)}
          />
        </SectionScreen>
      </FadeIn>
      <ClassifyAssetDialog ref={editClassification} />
    </>
  )
}

ClassificationsSection.propTypes = {}

export default ClassificationsSection
