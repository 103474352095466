import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import get from 'lodash/get'
import clsx from 'clsx'
import DebouncedInput from '../../../../../molecules/DebouncedInput'

const useStyles = makeStyles((theme) => ({
  selectBox: {
    position: 'relative',
    overflowY: 'auto',
    borderRadius: theme.layout.radius.thin,
    border: `1px solid ${theme.palette.gray.darker}`,
    padding: theme.layout.padding.thin,
    maxHeight: '300px',
    minHeight: '300px'
  },
  selectBoxContent: {
  },
  selectBoxItem: {
    padding: theme.layout.padding.thin,
    marginLeft: '-5px',
    marginRight: '-5px',
    '&.__disabled': {
      // backgroundColor: theme.palette.primary.main,
      color: theme.palette.gray.dark,
      fontStyle: 'italic',
      '&:hover': {
        cursor: 'unset',
        backgroundColor: theme.palette.background.default
      }
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.gray.main
    }
  }
}))

const MultiSelectBox = function MultiSelectBox ({ options, labelAccessor, valueAccessor, value, onChange, className }) {
  const classes = useStyles()
  const _labelAccessor = useCallback((opt) => {
    return get(opt, labelAccessor)
  }, [labelAccessor])
  const _valueAccessor = useCallback((opt) => {
    return get(opt, valueAccessor)
  }, [valueAccessor])

  const [filter, setFilter] = useState('')
  const filteredOptions = useMemo(() => {
    return (options || []).filter(x => _labelAccessor(x)?.toLowerCase().includes(filter?.toLowerCase() || ''))
  }, [filter, options, _labelAccessor])

  return (
    <div className={classes.selectBoxWrap}>
      <DebouncedInput delay={100} onChange={setFilter} style={{ minWidth: 'unset !important' }} />
      <div className={clsx(classes.selectBox, className)}>
        <div className={classes.selectBoxContent}>
          {filteredOptions.map(opt => {
            const v = _valueAccessor(opt)
            const selected = (value || []).includes(v)
            return (
              <div
                key={v}
                className={clsx(classes.selectBoxItem, { __disabled: selected })}
                onClick={() => selected ? null : onChange([...(value || []), v])}
              >
                {_labelAccessor(opt)}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

MultiSelectBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  labelAccessor: PropTypes.string,
  valueAccessor: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string
}

MultiSelectBox.defaultProps = {
  labelAccessor: 'label',
  valueAccessor: 'value'
}

export default MultiSelectBox
