import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import flatten from 'lodash.flatten'
import noop from 'lodash/noop'
import { useAccountBillingInfo, useSearchAccounts } from '../../../../api/accounts'
import ErrorComponent from '../../../atoms/ErrorComponent'
import { useBoolean, useCheckPolicy } from '../../../../hooks'
import { CALC_TYPES, FEATURE_FLAG, INTERNAL_DATE_FORMAT, LEVEL_TYPES } from '../../../../constants'
import { usePolicy } from '../../../../hooks/usePolicy'
import EmptySection from '../../../atoms/EmptySection'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'
import { useCoreDataDataset } from '../../../../api/coreData'
import { numberToUSD } from '../../../../utils'
import { useGroupSearch } from '../../../../api/groups'
import { getGroupsQuery, tagsQueryOptions } from '../LevelTags/helpers'
import { ACCOUNTS } from '../../../../policies/admin'
import { ACCOUNTS_MEMBER_LEVEL_ID, formatDate, getAccountQuery, mapAccount } from './helpers'
import AccountDetailsHeader from './Header/AccountDetailsHeader'
import useAccountClients from './useAccountClients'
import AccountClientsAssignment from './ClientsTab/AccountClientsAssignment'
import AccountBillingDetails from './BillingTab/AccountBillingDetails'
import AccountDetailsGeneralInfo from './GeneralInfo/AccountDetailsGeneralInfo'
import Positions from './PositionsTab/Positions'
import { AccountFormProvider } from './AccountFormProvider'
import AccountTabs from './AccountTabs'
import AccountDatesHeader from './Header/AccountDatesHeader'
import KdpTab from './KdpTab'
import TargetsTab from './TargetsTab'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0rem 3rem 1rem 3rem',
    '& .__control-group': {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center'
    }
  },
  content: {},
  header: {
    padding: '2rem 0 0 0',
    display: 'flex'
  },
  headerDetails: {
    display: 'flex',
    flex: 1
  },
  mainTitle: {
    fontSize: '2.125rem'
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    color: '#212945',
    lineHeight: '2.5625rem',
    fontSize: '2.125rem'
  },
  subTitle: {
    fontSize: '0.875rem'
  },
  roundedButton: {
    border: `2px solid ${theme.palette.mirage}`,
    color: theme.palette.mirage,
    borderRadius: 25,
    padding: '4px 8px 3px 8px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formActions: {
    position: 'fixed',
    bottom: '1rem',
    left: '50%',
    padding: '0',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    zIndex: 2
  },
  titleDescription: {
    color: '#141929',
    lineHeight: '1.0625rem',
    fontSize: '0.875rem',
    fontWeight: 350
  },
  columnTitle: {
    textAlign: 'left',
    fontSize: '1.5rem'
  },
  viewTabs: {
    marginBottom: '20px'
  }
}))

const AccountDetailsView = () => {
  const classes = useStyles()
  const params = useParams()
  const { active: isBillingActive } = useFeatureFlag(FEATURE_FLAG.SHOW_BILLING_INFO)
  const [assetsQuery, setAssetsQuery] = useState(null)
  const [disableEdit, setDisableEdit] = useBoolean()
  const canView = usePolicy(ACCOUNTS.viewAccounts)
  const canViewKdps = usePolicy('admin_view_key_datapoints')
  const canViewTargets = usePolicy('admin_view_acct_models')

  const userCanViewBilling = useCheckPolicy(ACCOUNTS.viewAccountBilling)

  const { query, tagsQuery } = useMemo(
    () => ({
      query: getAccountQuery(params.id),
      tagsQuery: getGroupsQuery({
        memberLevelId: params.id,
        memberLevelTypeId: ACCOUNTS_MEMBER_LEVEL_ID
      })
    }),
    [params]
  )

  const tabOptions = useMemo(() => ({
    general: {
      value: 'general',
      label: 'General Info'
    },
    clients: {
      value: 'clients',
      label: 'Clients'
    },
    ...isBillingActive
      ? {
        billing: {
          value: 'billing',
          label: 'Billing Details'
        }
      }
      : {},
    positions: {
      value: 'positions',
      label: 'Positions'
    },
    ...canViewTargets ? {
      targets: {
        value: 'targets',
        label: 'Targets'
      }
    } : {},
    ...canViewKdps ? {
      kdps: {
        value: 'kdps',
        label: 'Key Datapoints'
      }
    } : {}
  }), [isBillingActive, canViewKdps, canViewTargets])

  const { isLoading, error, data, refetch: refetchAccount } = useSearchAccounts(query)

  const { data: accountTags, refetch: refetchAccountTags, isFetching: tagsFetching } = useGroupSearch(tagsQuery, tagsQueryOptions)
  const { data: assets, refetch: refetchAssets } = useCoreDataDataset(assetsQuery, { enabled: !!assetsQuery })
  const { data: accountBillingInfo, refetch: refetchAccountBillingInfo } = useAccountBillingInfo(params.id, { enabled: userCanViewBilling })

  const account = useMemo(
    () => mapAccount(data, accountBillingInfo, accountTags),
    [data, accountTags, accountBillingInfo]
  )

  const {
    clientsAssigned,
    selectedClients,
    onSearchClients,
    clientsUnAssigned,
    searchResultOptions,
    onAssignUnAssignClient,
    persistClientsSelection,
    refetchClientsAssigned
  } = useAccountClients({
    accountId: params.id
  })

  useEffect(() => {
    if (account) {
      const { startDate: start, asOfDate, accountId } = account
      const startDate = formatDate(start, INTERNAL_DATE_FORMAT)
      const endDate = formatDate(asOfDate, INTERNAL_DATE_FORMAT)

      if (startDate !== '-' && endDate !== '-') {
        setAssetsQuery({
          levelFilters: {
            V2: true,
            calcType: CALC_TYPES.balance,
            levelTypes: [LEVEL_TYPES.ACCOUNTS],
            accountIds: [accountId]
          },
          dateRange: { startDate, endDate }
        })
      }
    }
  }, [account])

  const onClosedAccount = useCallback(async () => {
    await Promise.all([refetchAssets(), refetchAccount()])
  }, [refetchAssets, refetchAccount])

  const onGeneralInfoSave = useCallback(async () => {
    await Promise.all([refetchAccount(), refetchAccountTags()])
  }, [refetchAccountTags, refetchAccount])

  const onClientsSave = useCallback(async () => {
    await Promise.all([
      persistClientsSelection(),
      refetchClientsAssigned()
    ])
  }, [persistClientsSelection, refetchClientsAssigned])

  const onBillingSave = useCallback(async () => {
    await Promise.all([
      refetchAccountBillingInfo(),
      refetchAccount()
    ])
  }, [refetchAccountBillingInfo, refetchAccount])

  if (!canView) {
    return (
      <EmptySection
        title='Access Denied'
        description='You do not have sufficient permissions to access this resource'
      />
    )
  }

  if (isLoading || !account) {
    return null
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  const clientsAssignedIds = clientsAssigned.data.map(({ id }) => id)

  return (
    <AccountFormProvider account={account} isFetching={isLoading}>
      <div className={classes.container}>
        <div>
          <AccountDetailsHeader
            marketValue={flatten(assets)?.length ? numberToUSD(flatten(assets)[0]?.endingValue) : '-'}
          />
          <AccountDatesHeader
            onClosedAccount={onClosedAccount}
            onBillingSave={onBillingSave}
            onGeneralInfoSave={onGeneralInfoSave}
          />
        </div>
        <ViewTabs.Group tabOptions={tabOptions} onChange={noop}>
          <AccountTabs />
          <ViewTabs.Tab value='general'>
            <Grid container>
              <AccountDetailsGeneralInfo
                loading={tagsFetching}
                account={account}
                onSave={onGeneralInfoSave}
                disableEdit={disableEdit}
                setDisableEdit={setDisableEdit}
              />
            </Grid>
          </ViewTabs.Tab>
          <ViewTabs.Tab value='clients'>
            <Grid container>
              <Grid item xs={12}>
                <AccountClientsAssignment
                  loading={isLoading}
                  onSave={onClientsSave}
                  selectedClients={selectedClients}
                  onSearchClients={onSearchClients}
                  clientsAssigned={clientsAssigned}
                  clientsUnAssigned={clientsUnAssigned}
                  searchResultOptions={searchResultOptions}
                  onAssignUnAssignClient={onAssignUnAssignClient}
                  disableEdit={disableEdit}
                  setDisableEdit={setDisableEdit}
                />
              </Grid>
            </Grid>
          </ViewTabs.Tab>
          {isBillingActive && (
            <ViewTabs.Tab value='billing'>
              <Grid container>
                <Grid item xs={12}>
                  <AccountBillingDetails
                    filterAccountsByAssignedToClientIds={clientsAssignedIds}
                    onSave={onBillingSave}
                  />
                </Grid>
              </Grid>
            </ViewTabs.Tab>
          )}
          <ViewTabs.Tab value='positions'>
            <Grid container>
              <Grid item xs={12}>
                <Positions
                  account={account}
                  disableEdit={disableEdit}
                  setDisableEdit={setDisableEdit}
                  onSave={refetchAccount}
                />
              </Grid>
            </Grid>
          </ViewTabs.Tab>
          <ViewTabs.Tab value='targets'>
            <Grid container>
              <Grid item xs={12}>
                <TargetsTab />
              </Grid>
            </Grid>
          </ViewTabs.Tab>
          <ViewTabs.Tab value='kdps'>
            <Grid container>
              <Grid item xs={12}>
                <KdpTab />
              </Grid>
            </Grid>
          </ViewTabs.Tab>
        </ViewTabs.Group>
      </div>
    </AccountFormProvider>
  )
}

export default AccountDetailsView
