import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'

const TearSheetContext = createContext(null)

export const useTearSheetContext = () => {
  return useContext(TearSheetContext)
}

function TearSheetProvider ({ children, value }) {
  return (
    <TearSheetContext.Provider value={value}>
      {children}
    </TearSheetContext.Provider>
  )
}

TearSheetProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any
}

export default TearSheetProvider
