import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import Tag from '../../../../atoms/Tag'

const TagsCell = ({ cell }) => {
  const theme = useTheme()

  if (!cell.value) return ''

  return (
    <Box style={{ marginLeft: 0 }} display='flex' gridGap='6px' flexWrap='wrap'>
      {cell.value.map((tag) => (
        <Tag
          fontSize='12px'
          key={tag.tagId}
          label={tag.name}
          backgroundColor={tag.colorField ?? theme.palette.primary.main}
          color={theme.palette.getContrastText(tag.colorField ?? theme.palette.primary?.main)}
        />
      ))}
    </Box>
  )
}

TagsCell.propTypes = {
  cell: PropTypes.object
}
export default TagsCell
