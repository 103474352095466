import React from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import Loading from '../../../../../molecules/Loading'
import ErrorBoundary from '../../../../../molecules/ErrorBoundary'
import { useAssetDetails } from '../../AssetProvider'
import { useGetThingSchema, useLevelThings } from '../../../../../../api/things'
import ThingContentForm from './ThingContentForm'

function ThingsTool () {
  const { asset } = useAssetDetails()
  const { data, isLoading } = useGetThingSchema(3)
  const { data: thingValues, isLoading: thingValuesLoading } = useLevelThings(3, asset.assetId)

  if (isLoading || thingValuesLoading) {
    return (
      <FadeIn>
        <Card>
          <Loading />
        </Card>
      </FadeIn>
    )
  }

  return (
    <ErrorBoundary name='Asset Content Tab'>
      <FadeIn>
        <Card>
          <ThingContentForm asset={asset} schema={data?.schema} values={thingValues} />
        </Card>
      </FadeIn>
    </ErrorBoundary>
  )
}

export default ThingsTool
