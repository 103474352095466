import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Box, CircularProgress } from '@material-ui/core'
import SydTabs from '../../commonDesign/SydTabs'
import { useListTags } from '../../../api/documentVault'
import Divider from '../../atoms/Divider'
import { useVaultContext } from './VaultContextProvider'
import VaultCollection from './VaultCollection'
import { useGetDefaultVisibility, useNormalizeLevelValues } from './hooks'
import DocumentVaultFileUpload from './VaultFileUpload'

const fileUploadStyles = {
  border: 'none',
  position: 'relative',
  cursor: 'inherit',
  padding: '0 10px',
  display: 'block',
  marginLeft: '-10px',
  marginRight: '-10px',
  minWidth: '100%',
  width: 'auto'
}

const DocumentVaultContent = () => {
  const { view, levels: _levels, labels, currentTab, setCurrentTab, canUpload, showUploadButton, columns, groupVaultByTags, defaultVisibility: _defaultVisibility, includeRecentGroup, filters } = useVaultContext()
  const levels = useNormalizeLevelValues(_levels)
  const queryClient = useQueryClient()
  const [hiddenFilters, setHiddenFilters] = useState([])
  const [tabVersion, setTabVersion] = useState(0)

  const tabs = useMemo(() => levels?.reduce((acc, level, i) => {
    if (level.levelTypeId === 1000000 && hiddenFilters.find(filter => filter.levelTypeId === 1000000)) return acc
    acc[level.levelTypeId] = {
      label: labels?.titles[level.levelTypeId],
      order: i
    }
    return acc
  }, {}), [hiddenFilters, labels?.titles, levels])

  useEffect(() => {
    if (!tabs[currentTab] && Object.keys(tabs)?.length) {
      setCurrentTab(Object.keys(tabs)?.[0])
      setTabVersion((prev) => prev + 1)
    }
  }, [currentTab, setCurrentTab, setTabVersion, tabs])

  const activeLevel = useMemo(() => {
    if (!currentTab) return null
    return levels.filter((level) => {
      return currentTab.toString() === level.levelTypeId.toString()
    })
  }, [currentTab, levels])

  const { data: currentTabTags, isLoading } = useListTags({
    filters: {
      levels: activeLevel,
      ...(filters?.tags?.length && ({ tagIds: [{ op: 'in', value: filters?.tags?.map(({ value }) => value) }] }))
    }
  }, { enabled: !!groupVaultByTags?.[currentTab] })

  const onRefetch = async () => {
    await queryClient.invalidateQueries({ queryKey: ['list-documents'] })
    await queryClient.invalidateQueries({ queryKey: ['list-tags'] })
  }
  const defaultVisibility = useGetDefaultVisibility(_defaultVisibility)

  const handleOnEmpty = useCallback((filter) => {
    setHiddenFilters((prev) => [...prev, filter])
  }, [])

  const allItemsEmpty = useMemo(() => {
    return hiddenFilters.includes('recent') && hiddenFilters.includes('untagged') && !currentTabTags?.tags?.length
  }, [currentTabTags, hiddenFilters])

  const contentDisplayRender = useMemo(() => {
    return (
      <>
        {!allItemsEmpty && includeRecentGroup?.[currentTab] && view !== 'list' && (
          <>
            <VaultCollection
              title={labels?.collections?.[currentTab]?.recent ?? 'Recent'}
              take={5}
              skip={0}
              orderBy={[{ field: 'updatedDate', dir: 'desc' }]}
              key={currentTab}
              levels={activeLevel}
              view={view}
              columns={columns}
              allowDetails
              collapsible
              onEmpty={() => handleOnEmpty('recent')}
            />
            <Box mt='-16px'>
              <Divider />
            </Box>
          </>
        )}
        {!allItemsEmpty && groupVaultByTags?.[currentTab] && view !== 'list' ? (
          isLoading ? (<CircularProgress />) : (
            <>
              {currentTabTags?.tags?.map((tag) => (
                <VaultCollection
                  title={tag.name}
                  filters={{ tagIds: [{ op: 'eq', value: tag.id }] }}
                  key={currentTab}
                  levels={activeLevel}
                  view={view}
                  columns={columns}
                  allowDetails
                  collapsible
                />
              ))}
              {!filters?.tags?.length && !hiddenFilters.includes('untagged') && (
                <VaultCollection
                  title={labels?.collections?.[currentTab]?.untagged ?? 'Untagged'}
                  filters={{ tagIds: [{ op: 'isNull' }] }}
                  key={currentTab}
                  levels={activeLevel}
                  view={view}
                  columns={columns}
                  allowDetails
                  collapsible
                  onEmpty={() => handleOnEmpty('untagged')}
                />
              )}
            </>
          )
        ) : (
          <VaultCollection
            key={currentTab}
            levels={activeLevel}
            view={view}
            columns={columns}
            allowDetails
            canUpload={canUpload}
            showUploadButton={canUpload && showUploadButton}
            onEmpty={() => handleOnEmpty(activeLevel[0])}
          />
        )}
      </>
    )
  }, [allItemsEmpty, includeRecentGroup, currentTab, view, labels, activeLevel, columns, groupVaultByTags, isLoading, currentTabTags, filters, hiddenFilters, canUpload, showUploadButton, handleOnEmpty])

  return (
    <SydTabs.Group
      tabOptions={tabs}
      onChange={setCurrentTab}
      defaultValue={currentTab}
      key={tabVersion}
    >
      <SydTabs.Links />
      {currentTab && (
        canUpload ? (
          <DocumentVaultFileUpload
            containerStyle={fileUploadStyles}
            showBackButton
            backButtonLabel='Back'
            onFinish={onRefetch}
            onFilesChange={onRefetch}
            persistDropRejected
            levelId={activeLevel[0].levelIds[0]}
            levelTypeId={activeLevel[0].levelTypeId}
            showUploadButton={showUploadButton}
            labels={labels}
            defaultVisibility={defaultVisibility}
          >
            {contentDisplayRender}
          </DocumentVaultFileUpload>
        ) : (contentDisplayRender))}
    </SydTabs.Group>
  )
}

export default DocumentVaultContent
