import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Divider, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../../redux/slices/appContext'
import Loading from '../../molecules/Loading'
import FlexRow from '../../molecules/FlexRow'
import MediaImage from '../ImageAssocation/MediaImage'
import { useSearchAssets } from '../../../api/groups'
import SingleThing from '../Things/SingleThing'
import ThingProvider from '../Things/ThingProvider'
import Card from '../../molecules/Card'
import TearSheetDialog from '../TearSheetDialog'
import Icon from '../../atoms/Icon'

const useStyles = makeStyles(theme => {
  return ({
    assetDetailList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.g10,

      '& .__row': {
        alignItems: 'flex-start',
        transition: 'outline .2s ease-in-out, background-color .2s ease-out',
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'transparent',
        borderRadius: theme.layout.radius.round,
        '&.__selectable:hover:not(:has(.__new-tab:hover))': {
          outlineColor: `color-mix(in srgb, ${theme.palette.primary.main} 50%, transparent)`,
          backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 20%, transparent)`,
          cursor: 'pointer'
        },
        '&.__selectable:active:not(:has(.__new-tab:hover))': {
          backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 50%, transparent)`
        },
        '& .__new-tab': {
          padding: theme.layout.padding.p10,
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.layout.radius.circle,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'outline .2s ease-in-out, background-color .2s ease-out',
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineColor: 'transparent',
          '&:hover': {
            outlineColor: theme.palette.primary.main,
            backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 20%, transparent)`,
            cursor: 'pointer'
          },
          '&.active': {
            backgroundColor: `color-mix(in srgb, ${theme.palette.primary.main} 50%, transparent)`
          }
        }
      },

      '& .__asset-name': {
        fontSize: theme.typography.fontSizes.h4
      },
      '& .__asset-identifier': {
        fontSize: theme.typography.fontSizes.lg,
        fontStyle: 'italic',
        color: `color-mix(in srgb, ${theme.palette.text.primary} 75%, transparent)`
      },

      '& .__details': {
        padding: theme.layout.padding.p10,
        width: '100%'
      },

      '& .__media-container': {
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20%',
        minWidth: '20%',
        padding: theme.layout.padding.p10
      },

      '& .__thing-container': {
        '& .__thing-content': {
          padding: 0
        }
      },

      '& .__kdp-item': {},

      '& .__kdp-title': {
        fontWeight: theme.typography.weights.bold,
        fontSize: theme.typography.fontSizes.h5
      },

      '& .__kdp-value': {
        fontWeight: theme.typography.weights.light,
        fontSize: theme.typography.fontSizes.xxl
      }
    }
  })
})

function AssetDetailList ({
  className,
  listStyle,
  defaultQuery,
  mediaPurposeCode,
  mediaStyle,
  holdingStatus,
  thingCode
}) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const q = useMemo(() => {
    return {
      ...(defaultQuery || {}),
      filters: {
        ...(defaultQuery?.filters || {}),
        clientId: [clientId],
        tearSheetId: [{ op: 'exists' }],
        holdingStatus
      },
      includes: {
        ...(defaultQuery?.includes || {}),
        holdingStatus: {
          clientId: [clientId]
        }
      }
    }
  }, [defaultQuery, clientId, holdingStatus])
  const { data, isLoading } = useSearchAssets(q, { enabled: !!q })
  const dialogRef = useRef(null)

  if (isLoading) {
    return (
      <Loading />
    )
  }

  /* The images and things are loaded in a waterfall, but not investing in optimizing that until we know it's a problem */
  return (
    <div className={clsx(classes.assetDetailList, className)} style={listStyle}>
      {(data || []).map(asset => (
        <FlexRow
          key={asset.assetId}
          className={clsx('__row', { __selectable: !!asset.tearSheetId })}
          onClick={() => dialogRef.current.open({
            levelTypeId: 3,
            levelId: asset.assetId,
            tearSheetId: asset.tearSheetId,
            title: asset.assetName || asset.assetIdentifier,
            bindings: {
              assetId: asset.assetId
            }
          })}
        >
          {mediaPurposeCode ? (
            <div className='__media-container'>
              <Card style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MediaImage
                  purposeCode={mediaPurposeCode}
                  levelTypeId={3}
                  levelId={asset.assetId}
                  style={mediaStyle}
                  alt={asset.assetName}
                />
              </Card>
            </div>
          ) : null}
          <div className='__details'>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <div>
                <div className='__asset-name'>{asset.assetName}</div>
                <div className='__asset-identifier'>{asset.assetIdentifier}</div>
              </div>
              {asset.tearSheetId ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <Link to={`/tear-sheets/s/${asset.assetId}`} target='_blank' rel='noopener noreferrer' title='Open In New Tab'>
                    <div className='__new-tab'>
                      <Icon additionalClasses='__tab-icon' name='openInNew' />
                    </div>
                  </Link>
                </div>
              ) : null}
            </FlexRow>
            <Divider />
            <div className='__thing-container'>
              <ThingProvider levelTypeId={3} levelId={asset.assetId}>
                <SingleThing showHeader={false} hideIfEmpty code={thingCode} variant='borderless' />
              </ThingProvider>
            </div>
          </div>
        </FlexRow>
      ))}
      <TearSheetDialog ref={dialogRef} />
    </div>
  )
}

AssetDetailList.propTypes = {
  className: PropTypes.string,
  listStyle: PropTypes.object,
  defaultQuery: PropTypes.object,
  mediaPurposeCode: PropTypes.string,
  mediaStyle: PropTypes.object,
  thingCode: PropTypes.string,
  holdingStatus: PropTypes.oneOf(['current', 'ever'])
}

AssetDetailList.defaultProps = {
  holdingStatus: 'current'
}

export default AssetDetailList
