import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import ImagesTab from '../ImagesTab'
import AssociationsTab from '../AssociationsTab'
import IndexViewTabs from './IndexViewTabs'

function MediaIndexView ({ root }) {
  return (
    <FadeIn>
      <IndexViewTabs
        root={root}
        imageTab={<ImagesTab />}
        associationsTab={<AssociationsTab />}
      />
    </FadeIn>
  )
}

MediaIndexView.propTypes = {
  root: PropTypes.any
}

export default MediaIndexView
