import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    gap: '24px'
  },
  thumbnailContainer: {
    width: '250px',
    position: 'relative'
  },
  detailsContainer: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  actionsContainer: {
    display: 'flex',
    gap: '16px'
  },
  documentTitle: {
    fontWeight: 600,
    padding: '2px 0 !important',
    fontSize: '24px',
    textTransform: 'capitalize',
    margin: '0'
  },
  detailsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    alignItems: 'top'
  },
  detailBlockTitle: {
    fontSize: '18px',
    fontWeight: 600,
    margin: 0
  },
  detailsBlockContent: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '18px',
    flex: '1 0 0',
    width: '100%'
  },
  tagContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px'
  }
}))
