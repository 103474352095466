import React, { useCallback, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import SydButton from '../../../../commonDesign/Button'
import { ICON_NAMES } from '../../../../../constants'
import FileEditModal from '../../FileEditModal'
import { useVaultContext } from '../../VaultContextProvider'
import { useCheckPolicy } from '../../../../../hooks'
import { useInternalUserId } from '../../../../../redux/slices/appContext'
import { defaultDocumentDetailsLabel } from '../../constants'

const DocumentDownloadCell = ({ cell, row }) => {
  const queryClient = useQueryClient()
  const { labels, refetch: _refetch } = useVaultContext()
  const refetch = useCallback(async () => {
    if (_refetch) return _refetch()
    await queryClient.invalidateQueries({ queryKey: ['list-documents'] })
    await queryClient.invalidateQueries({ queryKey: ['list-tags'] })
  }, [_refetch, queryClient])
  const [editingDocument, setEditingDocument] = useState(false)
  const userCanEditAnyDocument = useCheckPolicy('vault_edit_any_document')
  const internalUserId = useInternalUserId()

  const createdByUserId = useMemo(() => row.original?.document?.createdByUser?.userId, [row.original])

  const userCanEdit = useMemo(() => userCanEditAnyDocument || createdByUserId === internalUserId, [userCanEditAnyDocument, createdByUserId, internalUserId])

  if (!cell.value || !userCanEdit) return ''

  return (
    <>
      <Box style={{ marginLeft: 0 }}>
        <SydButton
          size='sm'
          icon={ICON_NAMES.edit}
          onClick={(e) => {
            e.stopPropagation()
            setEditingDocument(row.original)
          }}
          disabled={editingDocument}
        >
          {labels?.documentDetails?.editButton ?? defaultDocumentDetailsLabel.editButton}
        </SydButton>
      </Box>

      {editingDocument && (
        <FileEditModal
          document={editingDocument}
          labels={labels?.visibility}
          onClose={(e) => {
            e.stopPropagation()
            setEditingDocument(null)
          }}
          onFinish={async () => {
            await refetch()
            setEditingDocument(null)
          }}
        />
      )}
    </>
  )
}

DocumentDownloadCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object
}
export default DocumentDownloadCell
