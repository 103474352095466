import React from 'react'
import { Grid } from '@material-ui/core'
import { useAssetDetails } from '../AssetProvider'
import SydMenu from '../../../../commonDesign/SydMenu'
import Card from '../../../../molecules/Card'
import PersonalSpace from '../../shared/PersonalSpace'
import TearSheets from './TearSheets'
import ThingsTool from './Things'
import KDPTool from './KeyDatapoints'
import ImagesTool from './Images'
import DocumentsTool from './Documents'

const tabOptions = {
  tearSheets: {
    value: 'tearSheets',
    label: 'Tear Sheets'
  },
  kdps: {
    value: 'kdps',
    label: 'Key Datapoints'
  },
  things: {
    value: 'things',
    label: 'Content'
  },
  images: {
    value: 'images',
    label: 'Images'
  },
  documents: {
    value: 'documents',
    label: 'Documents'
  }
}

function ContentTab () {
  const { asset } = useAssetDetails()

  return (
    <Card variant='clear'>
      <SydMenu
        options={tabOptions}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <SydMenu.List />
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <SydMenu.Content value='tearSheets'>
              <TearSheets asset={asset} />
            </SydMenu.Content>
            <SydMenu.Content value='kdps'>
              <KDPTool asset={asset} />
            </SydMenu.Content>
            <SydMenu.Content value='things'>
              <ThingsTool asset={asset} />
            </SydMenu.Content>
            <SydMenu.Content value='images'>
              <ImagesTool asset={asset} />
            </SydMenu.Content>
            <SydMenu.Content value='documents'>
              <DocumentsTool asset={asset} />
            </SydMenu.Content>
          </Grid>
        </Grid>
      </SydMenu>
      <PersonalSpace />
    </Card>
  )
}

export default ContentTab
