import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import clsx from 'clsx'
import { alpha } from '@material-ui/core/styles'
import Icon from '../../../atoms/Icon'
import Text from '../../../atoms/Text'
import { useAppContext } from '../../../../redux/slices/appContext'
import { defaultVisibilityLabel } from '../constants'

const visibilityOptions = ['internal', 'hidden', 'visible']

const useVisibilityToggleStyles = makeStyles((theme) => ({
  visibilityButtonsContainer: {
    display: 'flex',
    gridGap: '12px',
    flexWrap: 'wrap'
  },
  visibilityButton: {
    display: 'flex',
    flex: '1 1 150px',
    gap: '12px',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2)
    }
  },
  visibilityButtonContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '2px'
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.9)
    }
  }
}))

const VaultUploadsVisibilityToggle = ({
  userIsCreator = true,
  defaultVisibility = 'visible',
  onToggleVisibility = noop,
  labels: _labels = defaultVisibilityLabel
}) => {
  const { isAdvisor } = useAppContext()
  const classes = useVisibilityToggleStyles()
  const [visibility, setVisibility] = useState(defaultVisibility)

  const availableVisibilityOptions = useMemo(() => {
    return visibilityOptions.filter((option) => {
      if (option === 'internal') return isAdvisor
      if (option === 'hidden') return userIsCreator
      return true
    }).map((option) => ({
      value: option,
      ...(_labels[option] ?? defaultVisibilityLabel[option])
    }))
  }, [_labels, isAdvisor, userIsCreator])

  const onToggleVisibilityHandler = (val) => {
    setVisibility(val)
    onToggleVisibility(val)
  }

  return (
    <div className={classes.visibilityButtonsContainer}>
      {availableVisibilityOptions.map((option) => (
        <button
          type='button'
          key={option.value}
          onClick={() => onToggleVisibilityHandler(option.value)}
          className={clsx({
            [classes.visibilityButton]: true,
            [classes.activeButton]: visibility === option.value
          })}
        >
          <Icon name={option.icon} customSize='20px' />
          <div className={classes.visibilityButtonContent}>
            <Text variant='h4' text={option.label} />
            <Text variant='body2' text={option.description} />
          </div>
        </button>
      ))}
    </div>
  )
}

VaultUploadsVisibilityToggle.propTypes = {
  userIsCreator: PropTypes.bool,
  defaultVisibility: PropTypes.string,
  onToggleVisibility: PropTypes.func,
  labels: PropTypes.object
}

export default VaultUploadsVisibilityToggle
