import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import SydModal from '../../../../commonDesign/SydModal'
import SydModalActions from '../../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../../commonDesign/Button'
import SydLabel, { hookFormErrorAdapter } from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import { useUpdateTag } from '../../../../../api/documentVault'
import Alert from '../../../../atoms/Alert'
import { ALERT_SEVERITY, ALERT_VARIANT } from '../../../../../constants'

const EditTagModal = ({ tag, onClose, onFinish }) => {
  const theme = useTheme()
  const modalTitle = useMemo(() => {
    if (tag.id === 'new') {
      return 'Create New Tag'
    }
    return 'Edit Tag'
  }, [tag.id])

  const form = useForm({
    defaultValues: {
      name: '',
      description: null,
      colorField: null,
      ordinal: 99999,
      ...(tag.id !== 'new' && tag)
    }
  })

  const { handleSubmit } = form

  const { mutateAsync, isLoading, isError } = useUpdateTag()
  const onSubmit = useCallback(async (data) => {
    await mutateAsync(data)
    onFinish()
  }, [mutateAsync, onFinish])

  return (
    <>
      <SydModal
        open={!!tag}
        onClose={onClose}
        title={modalTitle}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {isError && (
            <Box mb={4}>
              <Alert variant={ALERT_VARIANT.standard} severity={ALERT_SEVERITY.error}>An error occurred while editing this tag</Alert>
            </Box>
          )}
          <Box display='flex' flexDirection='column' gridGap={12} mb={2}>
            <Controller
              control={form.control}
              name='name'
              rules={{ required: { value: true, message: 'Required' } }}
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Name'
                  required
                  description='Required'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Tag Name' {...field} />
                </SydLabel>
              )}
            />
            <Controller
              control={form.control}
              name='description'
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Description'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='Description...' {...field} />
                </SydLabel>
              )}
            />
            <Controller
              control={form.control}
              name='colorField'
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Color'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder={theme.palette.primary.main} {...field} />
                </SydLabel>
              )}
            />
            <Controller
              control={form.control}
              name='ordinal'
              render={({ field, fieldState }) => (
                <SydLabel
                  label='Ordinal'
                  error={hookFormErrorAdapter(form, fieldState)}
                >
                  <SydInput size='sm' placeholder='99999' {...field} />
                </SydLabel>
              )}
            />
          </Box>
          <SydModalActions>
            <SydButton onClick={onClose}>
              Cancel
            </SydButton>
            <SydButton
              variant='primary'
              type='submit'
              disabled={isLoading}
            >
              {isLoading ? 'Saving..' : 'Save'}
            </SydButton>
          </SydModalActions>
        </form>
      </SydModal>
    </>
  )
}

EditTagModal.propTypes = {
  tag: PropTypes.object,
  onClose: PropTypes.func,
  onFinish: PropTypes.func
}

export default EditTagModal
