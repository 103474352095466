import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useListImages } from '../../../api/media'
import { useMediaScope } from './useMediaScope'

const useStyles = makeStyles((theme) => ({
  imageList: {
  }
}))

function ImageList ({
  levelTypeId,
  purposeCode,
  imgStyle,
  listStyle,
  levelId
}) {
  const classes = useStyles()
  const mediaScope = useMediaScope({ levelTypeId, levelId })
  const query = useMemo(() => {
    return {
      purpose: {
        purposeCode,
        ...mediaScope
      }
    }
  }, [purposeCode, mediaScope])
  const { data: images, isLoading } = useListImages(query)

  if (isLoading) return null
  if (!images?.data?.length) return null

  return (
    <div className={classes.imageList} style={listStyle}>
      {images?.data?.map((image) => (
        <img
          key={`image-list-${purposeCode}-${image.imageId}`}
          src={image.imageUrl}
          alt={image.description}
          style={imgStyle}
        />
      ))}
    </div>
  )
}

ImageList.propTypes = {
  levelTypeId: PropTypes.number.isRequired,
  purposeCode: PropTypes.string.isRequired,
  imgStyle: PropTypes.object,
  listStyle: PropTypes.object,
  levelId: PropTypes.number
}

export default ImageList
