import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchClients } from '../../../api/clients'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'

const sizes = {
  md: {
    '& .__metric': {
      fontSize: '50px'
    }
  },
  lg: {
    '& .__metric': {
      fontSize: '75px'
    }
  }
}

const useStyles = makeStyles((theme) => ({
  kdpMetric: ({ size }) => ({
    ...(sizes[size] || {}),
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    '& .__header': {
      fontWeight: theme.typography.weights.light
    }
  })
}))

const useMetric = (keyDatapoint) => {
  const { clientId } = useAppContext()
  const query = useMemo(() => {
    return {
      limit: 1,
      filters: {
        clientId: [{ op: 'eq', value: clientId }]
      },
      includes: {
        keyDatapoints: [keyDatapoint]
      }
    }
  }, [keyDatapoint, clientId])

  const clientResponse = useSearchClients(query)

  return clientResponse
}

function KDPMetric ({ keyDatapoint, format, className, hideName, style, size, tooltip }) {
  const classes = useStyles({ size })
  const { data, isLoading } = useMetric(keyDatapoint)
  const datapoint = useMemo(() => {
    if (!data?.data?.length) return null

    return data.data.at(0).keyDatapoints[keyDatapoint]
  }, [data, keyDatapoint])
  // eslint-disable-next-line no-unused-vars
  const { formatter } = useFormattingContext()

  if (isLoading) {
    return (
      <div className={clsx(className, classes.kdpMetric)}>
        Loading...
      </div>
    )
  }

  const OuterComponent = tooltip ? Tooltip : Fragment

  return (
    <OuterComponent title={tooltip}>
      <div className={clsx(className, classes.kdpMetric)} style={style}>
        {!hideName ? (
          <div className='__header'>{datapoint?.displayName}</div>
        ) : null}
        <div className='__metric'>{formatter(datapoint?.value, format)}</div>
      </div>
    </OuterComponent>
  )
}

KDPMetric.propTypes = {
  keyDatapoint: PropTypes.string,
  format: PropTypes.string,
  className: PropTypes.string,
  hideName: PropTypes.bool,
  style: PropTypes.any,
  size: PropTypes.oneOf(['md', 'lg']),
  tooltip: PropTypes.string
}

KDPMetric.defaultProps = {
  size: 'md'
}

export default KDPMetric
