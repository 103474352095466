import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import EditThingForm from './form'

const EditThingDialog = React.forwardRef(function EditThingDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (asset, thing, thingConfiguration) => setDialogState({ asset, thing, thingConfiguration, open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`Edit Content: ${dialogState?.thingConfiguration?.name}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<EditThingForm asset={dialogState.asset} thing={dialogState.thing} thingConfiguration={dialogState.thingConfiguration} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

EditThingDialog.propTypes = {
}

export default EditThingDialog
