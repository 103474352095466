import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import NotFoundView from '../NotFoundView'
import AccountTearSheet from './AccountTearSheet'
import AssetTearSheet from './AssetTearSheet'

function TearSheet () {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <SecureRoute path={`${path}/a/:accountId`}>
        <AccountTearSheet />
      </SecureRoute>
      <SecureRoute path={`${path}/s/:assetId`}>
        <AssetTearSheet />
      </SecureRoute>
      <Route path='*'>
        <NotFoundView />
      </Route>
    </Switch>
  )
}

export default TearSheet
