import React from 'react'
import { Box } from '@material-ui/core'
import TagsFilter from './Controls/TagsFilter'
import ViewToggles from './Controls/ViewToggles'
import SearchFilter from './Controls/SearchFilter'
import DocumentVaultContent from './DocumentVaultContent'

export const DefaultHeaderContent = () => (
  <Box
    display='flex'
    flexWrap='wrap'
    sx={{
      justifyContent: {
        xs: 'center',
        md: 'space-between'
      }
    }}
    width='100%'
    gridGap='10px'
  >
    <Box>
      <Box>
        <TagsFilter />
      </Box>
    </Box>
    <Box display='flex' gridGap='10px' flexWrap='wrap' justifyContent='center'>
      <Box>
        <SearchFilter />
      </Box>
      <Box>
        <ViewToggles />
      </Box>
    </Box>
  </Box>
)

export const DefaultVaultContent = () => {
  return (
    <Box display='flex' flexDirection='column' gridGap='8px'>
      <DefaultHeaderContent />
      <DocumentVaultContent />
    </Box>
  )
}
