import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SydInput from '../../../../../commonDesign/SydInput'
import SydLabel from '../../../../../commonDesign/SydLabel'

function OtherInfoSectionDisplay ({ asset }) {
  return (
    <FadeIn>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Asset Type'>
            <SydInput disabled value={asset.assetType} />
          </SydLabel>
        </Grid>
      </Grid>
    </FadeIn>
  )
}

OtherInfoSectionDisplay.propTypes = {
  asset: PropTypes.shape({
    assetTypeId: PropTypes.number,
    assetType: PropTypes.string
  })
}

export default OtherInfoSectionDisplay
