import React, { useCallback, useImperativeHandle, useState } from 'react'
import RoundedModal from '../../../../../../molecules/RoundedModal'
import ClassifyAssetForm from './form'

const ClassifyAssetDialog = React.forwardRef(function ClassifyAssetDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (asset, classificationTagType) => setDialogState({ asset, classificationTagType, open: true, processing: false })
  }), [setDialogState])

  return (
    <RoundedModal
      title={`Classify ${dialogState?.classificationTagType?.longName}`}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='large'
      disablePortal={false}
    >
      <div>
        {dialogState.open ? (<ClassifyAssetForm asset={dialogState.asset} classificationTagType={dialogState.classificationTagType} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

ClassifyAssetDialog.propTypes = {
}

export default ClassifyAssetDialog
