import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useSearchAccounts } from '../../../../api/accounts'
import FlexRow from '../../../molecules/FlexRow'

function AccountSubjectHeader ({ accountId }) {
  const q = useMemo(() => ({
    filters: { accountId },
    take: 1
  }), [accountId])
  const { data, isLoading } = useSearchAccounts(q)
  const account = useMemo(() => {
    const d = data?.data || []
    return d?.length ? d.at(0) : null
  }, [data])

  if (isLoading) return null
  if (!account) return null

  return (
    <FlexRow>
      <div>
        <Typography variant='h2'>{account.accountName}</Typography>
        <Typography variant='h4'>{account.accountNumber}</Typography>
      </div>
    </FlexRow>
  )
}

AccountSubjectHeader.propTypes = {
  accountId: PropTypes.string
}

export default AccountSubjectHeader
