import React from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import { useSectionEditing, useAssetDetails } from '../../AssetProvider'
import CharacteristicsSectionDisplay from './Display'

function CharacteristicsSection () {
  const { editing, asset } = useAssetDetails()
  const sectionIsEditing = useSectionEditing('characteristics')

  return (
    <FadeIn>
      <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
        <SectionHeader text='Attributes (Internal Only)' />
        <CharacteristicsSectionDisplay asset={asset} />
      </SectionScreen>
    </FadeIn>
  )
}

CharacteristicsSection.propTypes = {
}

export default CharacteristicsSection
