import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import clsx from 'clsx'
import Icon from '../../../atoms/Icon'
import { useComments } from '../../Comments/CommentContext'

const variants = {
  default: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '4px',

    '& .__thing-header': {
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      padding: '10px 20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    '& .__thing-name': {
      fontSize: theme.typography.fontSizes.h5
    },

    '& .__thing-content': {
      padding: '10px 20px'
    },

    '& .__thing-actions': {
      display: 'flex',
      flexDirection: 'row'
    }
  }),
  borderless: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    borderRadius: '4px',

    '& .__thing-header': {
      borderBottom: `2px solid ${theme.palette.gray.dark}`,
      padding: '10px 20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    '& .__thing-name': {
      fontSize: theme.typography.fontSizes.h5
    },

    '& .__thing-content': {
      padding: '10px 20px',
      fontWeight: theme.typography.weights.light
    },

    '& .__thing-actions': {
      display: 'flex',
      flexDirection: 'row'
    }
  })
}

const mapVariant = (theme, variant) => {
  if (variant in variants) {
    return variants[variant](theme)
  }
  return variants.default(theme)
}

const useStyles = makeStyles((theme) => ({
  thing: ({ variant }) => ({
    ...mapVariant(theme, variant)
  })
}))

function Thing ({
  thing,
  className,
  style,
  showHeader,
  variant,
  commentsIcon,
  attachmentsIcon,
  hideComments,
  hideAttachments,
  hideIfEmpty,
  labels
}) {
  const classes = useStyles({ variant })
  const { showCommentThread, showAttachments } = useComments()
  const openAttachments = useCallback(() => {
    showAttachments({
      clientId: thing.levelId,
      levelTypeId: 279,
      levelId: thing.thingId
    })
  }, [showAttachments, thing])

  const openComments = useCallback(() => {
    showCommentThread({
      clientId: thing.levelId,
      levelTypeId: 279,
      levelId: thing.thingId
    })
  }, [showCommentThread, thing])

  const contentType = thing?.data?.contentType ?? 'html'

  if (!thing && hideIfEmpty) {
    return null
  }

  if (!thing && !hideIfEmpty) {
    return (
      <div className={clsx(classes.thing, className)} style={style}>{labels?.empty || 'N/A'}</div>
    )
  }

  return (
    <div className={clsx(classes.thing, className)} style={style}>
      {showHeader ? (
        <div className='__thing-header'>
          <div>
            <div className='__thing-name'>{thing.name}</div>
          </div>
          <div className='__thing-actions'>
            {hideComments ? null : (
              <IconButton onClick={openComments}>
                <Icon name={commentsIcon} customSize={16} />
              </IconButton>
            )}
            {hideAttachments ? null : (
              <IconButton onClick={openAttachments}>
                <Icon name={attachmentsIcon} customSize={16} />
              </IconButton>
            )}
          </div>
        </div>
      ) : null}
      {contentType === 'html' ? (
        <div className='__thing-content'>
          <div dangerouslySetInnerHTML={{ __html: thing.content }} />
        </div>
      ) : contentType === 'markdown' ? (
        <div className='__thing-content'>
          <ReactMarkdown children={thing.content} remarkPlugins={[remarkGfm]} />
        </div>
      ) : null}
    </div>
  )
}

Thing.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  thing: PropTypes.shape({
    thingId: PropTypes.number,
    levelId: PropTypes.number,
    levelTypeId: PropTypes.number,
    name: PropTypes.string,
    content: PropTypes.string,
    data: PropTypes.object,
    category: PropTypes.string
  }),
  variant: PropTypes.oneOf(['default', 'borderless']),
  showHeader: PropTypes.bool,
  commentsIcon: PropTypes.string,
  attachmentsIcon: PropTypes.string,
  hideComments: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  hideIfEmpty: PropTypes.bool,
  labels: PropTypes.shape({
    empty: PropTypes.string
  })
}

Thing.defaultProps = {
  showHeader: true,
  variant: 'default',
  commentsIcon: 'chat',
  attachmentsIcon: 'document',
  labels: {
    empty: 'N/A'
  }
}

export default Thing
