import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useSearchClients } from '../../../api/clients'
import Skeleton from '../../atoms/Skeleton'
import { SKELETON_VARIANTS } from '../../../constants'
import { useAppContext } from '../../../redux/slices/appContext'
import FadeIn from '../../molecules/Transitions/FadeIn'

const useStyles = makeStyles((theme) => ({
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.layout.gap.g10,
    alignItems: 'center'
  },
  tag: {
    borderRadius: theme.layout.radius.loose,
    boxShadow: theme.layout.shadow.light,
    border: `1px solid ${theme.palette.gray.dark}`,
    background: theme.palette.gray.main,
    padding: `${theme.layout.padding.p5} ${theme.layout.padding.p10}`,
    fontSize: theme.typography.fontSizes.xl,
    fontWeight: theme.typography.weights.bold
  }
}))

const GroupList = ({ groupTypeId, className, listStyles, itemStyles }) => {
  const classes = useStyles()
  const { clientId } = useAppContext()

  const { query, queryOptions } = useMemo(
    () => ({
      query: {
        includes: {
          groups: {
            levelTypeId: [{ op: 'eq', value: groupTypeId }]
          }
        },
        filters: { clientId }
      },
      queryOptions: {
        mapper: ({ data }) => data?.length ? data.at(0) : {}
      }
    }),
    [clientId, groupTypeId]
  )
  const { data: client, isLoading } = useSearchClients(query, queryOptions)

  if (isLoading) {
    return (
      <div className={clsx(className, classes.tagsContainer)} style={listStyles}>
        <Skeleton className={classes.tag} height='32px' width='60px' variant={SKELETON_VARIANTS.rect} />
        <Skeleton className={classes.tag} height='32px' width='60px' variant={SKELETON_VARIANTS.rect} />
        <Skeleton className={classes.tag} height='32px' width='60px' variant={SKELETON_VARIANTS.rect} />
      </div>
    )
  }

  return (
    <div className={clsx(className, classes.tagsContainer)} style={listStyles}>
      {client?.groups.map(({ groupId, name }, index) => {
        return (
          <FadeIn key={groupId} className={classes.tag} style={itemStyles} delay={index * 0.1}>
            {name}
          </FadeIn>
        )
      })}
    </div>
  )
}

GroupList.propTypes = {
  groupTypeId: PropTypes.number,
  className: PropTypes.string,
  listStyles: PropTypes.object,
  itemStyles: PropTypes.object
}

GroupList.defaultProps = {
  groupTypeId: 275
}

export default GroupList
