import { useCallback, useState } from 'react'

function useFallbackImg (img, fallback) {
  const [src, setImg] = useState(img || fallback)
  const [isErrored, setIsErrored] = useState(false)

  const onError = useCallback(
    (e) => {
      setIsErrored(true)
      setImg(fallback)
    },
    [fallback]
  )

  return { src, onError, isErrored }
}

export default useFallbackImg
