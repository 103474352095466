import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Divider, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useAppContext } from '../../../redux/slices/appContext'
import { useSearchAccounts } from '../../../api/accounts'
import Loading from '../../molecules/Loading'
import FlexRow from '../../molecules/FlexRow'
import MediaImage from '../ImageAssocation/MediaImage'

const useStyles = makeStyles(theme => {
  return ({
    accountDetailList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.g10,

      '& .__row': {
        alignItems: 'flex-start'
      },

      '& .__account-name': {
        fontSize: theme.typography.fontSizes.h4
      },
      '& .__account-number': {
        fontSize: theme.typography.fontSizes.lg,
        fontStyle: 'italic',
        color: theme.palette.gray.darker
      },

      '& .__details': {
        padding: theme.layout.padding.p10,
        width: '100%'
      },

      '& .__media-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20%',
        minWidth: '20%',
        padding: theme.layout.padding.p10
      },

      '& .__kdp-container': {
        marginTop: theme.layout.margin.m10,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.layout.gap.g10
      },

      '& .__kdp-item': {},

      '& .__kdp-title': {
        fontWeight: theme.typography.weights.bold,
        fontSize: theme.typography.fontSizes.h5
      },

      '& .__kdp-value': {
        fontWeight: theme.typography.weights.light,
        fontSize: theme.typography.fontSizes.xxl
      }
    }
  })
})

function AccountDetailList ({
  className,
  listStyle,
  defaultQuery,
  kdpCodes,
  mediaPurposeCode,
  mediaStyle
}) {
  const classes = useStyles()
  const { clientId } = useAppContext()
  const q = useMemo(() => {
    return {
      ...(defaultQuery || {}),
      filters: {
        ...(defaultQuery?.filters || {}),
        assignedToClientIds: [clientId]
      },
      includes: {
        ...(defaultQuery?.includes || {}),
        keyDatapoints: kdpCodes || []
      }
    }
  }, [defaultQuery, clientId, kdpCodes])
  const { data, isLoading } = useSearchAccounts(q, { enabled: !!q })

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div className={clsx(classes.accountDetailList, className)} style={listStyle}>
      {(data?.data || []).map(account => (
        <FlexRow key={account.accountId} className='__row'>
          {mediaPurposeCode ? (
            <div className='__media-container'>
              <MediaImage
                purposeCode={mediaPurposeCode}
                levelTypeId={1}
                levelId={account.accountId}
                style={mediaStyle}
                alt={account.accountName}
              />
            </div>
          ) : null}
          <div className='__details'>
            <div>
              <div className='__account-name'>{account.accountName}</div>
              <div className='__account-number'>{account.accountNumber}</div>
            </div>
            <Divider />
            <div className='__kdp-container'>
              {(kdpCodes || []).map(kdpCode => {
                const kdp = kdpCode in account.keyDatapoints ? account.keyDatapoints[kdpCode] : undefined
                if (!kdp) return null
                return (
                  <div key={`${account.accountId}_${kdpCode}`} className='__kdp-item'>
                    <div className='__kdp-title'>{kdp.displayName}</div>
                    <div className='__kdp-value'>{kdp.value}</div>
                  </div>
                )
              }).filter(x => x !== null)}
            </div>
          </div>
        </FlexRow>
      ))}
    </div>
  )
}

AccountDetailList.propTypes = {
  className: PropTypes.string,
  listStyle: PropTypes.object,
  defaultQuery: PropTypes.object,
  kdpCodes: PropTypes.arrayOf(PropTypes.string),
  mediaPurposeCode: PropTypes.string,
  mediaStyle: PropTypes.object
}

export default AccountDetailList
