import { makeStyles } from '@material-ui/core'

export const usePickerStyles = makeStyles((theme) => ({
  clientItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.medium,
    border: `1px solid ${theme.palette.gray.dark}`,
    padding: theme.layout.padding.thin,
    borderRadius: theme.layout.radius.tight,
    marginBottom: theme.layout.margin.thin
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.gray.main
  },
  search: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  table: {
    overflowY: 'auto',
    paddingBottom: '100px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  topBar: {
    borderBottom: `1px solid ${theme.palette.gray.darker}`
  },
  noData: {
    padding: theme.layout.padding.thick,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingMessage: {
    textAlign: 'center'
  }
}))
