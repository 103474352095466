import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip } from '@material-ui/core'
import SydButton from '../../../commonDesign/Button'
import { useVaultContext } from '../VaultContextProvider'

const ViewToggles = ({ options: _options, selectedValue, onChange }) => {
  const { availableViews, setView, view } = useVaultContext()

  return (
    <Box display='flex' gridGap='1rem'>
      {availableViews.map(option =>
        <Tooltip
          key={'tooltip-' + option.key}
          title={option.description}
          arrow
          placement='bottom'
        >
          <span>
            <SydButton
              variant={option.key === view ? 'primary' : 'secondary'}
              key={option.value}
              type='button'
              onClick={
                () => setView(option.key)
              }
              icon={option.icon}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{option.label}</span>
            </SydButton>
          </span>
        </Tooltip>
      )}
    </Box>
  )
}

ViewToggles.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  selectedValue: PropTypes.string,
  onChange: PropTypes.func
}

export default ViewToggles
