import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { useMetrics } from './useMetrics'

const sizes = {
  md: (theme) => ({
    '& .__metric': {
      fontSize: '50px',
      padding: `${theme.layout.padding.p10} ${theme.layout.padding.p30}`,
      borderLeft: `1px solid ${theme.palette.gray.dark}`
    },
    '& .__header': {
      fontSize: '25px',
      display: 'inline-block',
      padding: `${theme.layout.padding.p10} ${theme.layout.padding.p30}`,
      fontWeight: theme.typography.weights.light
    }
  }),
  lg: (theme) => ({
    '& .__metric': {
      fontSize: '75px',
      padding: `${theme.layout.padding.p10} ${theme.layout.padding.p30}`,
      borderLeft: `1px solid ${theme.palette.gray.dark}`
    },
    '& .__header': {
      display: 'inline-block',
      textAlign: 'right',
      fontSize: '50px',
      padding: `${theme.layout.padding.p10} ${theme.layout.padding.p30}`,
      fontWeight: theme.typography.weights.light
    }
  })
}

const useStyles = makeStyles((theme) => ({
  metricGrid: ({ size }) => ({
    ...(sizes[size]?.(theme) || {}),
    display: 'inline-grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.layout.gap.g10,
    alignItems: 'center'
  })
}))

function KDPMetricGrid ({ datapoints, className, style, size }) {
  const classes = useStyles({ size })
  const { formattedValues, isLoading } = useMetrics(datapoints)

  if (isLoading) {
    return null
  }

  return (
    <div className={clsx(className, classes.metricGrid)} style={style}>
      {formattedValues?.map((datapoint) => (
        <Fragment key={datapoint.name}>
          {datapoint?.tooltip ? (
            <Tooltip title={datapoint.tooltip}>
              <div className='__header'>{datapoint?.name}</div>
            </Tooltip>
          ) : (
            <div className='__header'>{datapoint?.name}</div>
          )}
          {datapoint?.tooltip ? (
            <Tooltip title={datapoint.tooltip}>
              <div className='__metric'>{datapoint?.value}</div>
            </Tooltip>
          ) : (
            <div className='__metric'>{datapoint?.value}</div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

KDPMetricGrid.propTypes = {
  datapoints: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    format: PropTypes.string,
    tooltip: PropTypes.string
  })),
  className: PropTypes.string,
  style: PropTypes.any,
  size: PropTypes.oneOf(['md', 'lg'])
}

KDPMetricGrid.defaultProps = {
  size: 'md'
}

export default KDPMetricGrid
