import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { useThingContext } from '../ThingContext'
import Thing from '../Thing'

const useStyles = makeStyles((theme) => ({
  thingSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  }
}))

function ThingSection ({
  category,
  className,
  style,
  variant,
  showHeader,
  commentsIcon,
  attachmentsIcon,
  hideComments,
  hideAttachments,
  itemClass,
  itemStyle,
  hideIfEmpty,
  labels
}) {
  const classes = useStyles()
  const { data, isLoading } = useThingContext()

  const categoryData = useMemo(() => {
    if (!data?.things) return []

    if (!category) return data.things

    return data.things.filter(x => x.category === category)
  }, [category, data])

  if (isLoading) return null

  if (hideIfEmpty && categoryData?.length === 0) return null

  return (
    <div className={clsx(classes.thingSection, className)} style={style}>
      {categoryData.map(x => (
        <Thing
          key={x.thingId}
          thing={x}
          showHeader={showHeader}
          variant={variant}
          commentsIcon={commentsIcon}
          attachmentsIcon={attachmentsIcon}
          hideComments={hideComments}
          hideAttachments={hideAttachments}
          className={itemClass}
          style={itemStyle}
          hideIfEmpty={hideIfEmpty}
          labels={labels}
        />
      ))}
    </div>
  )
}

ThingSection.propTypes = {
  category: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  showHeader: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'borderless']),
  commentsIcon: PropTypes.string,
  attachmentsIcon: PropTypes.string,
  hideComments: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  itemClass: PropTypes.string,
  itemStyle: PropTypes.object,
  hideIfEmpty: PropTypes.bool,
  labels: PropTypes.shape({
    empty: PropTypes.string
  })
}

ThingSection.defaultProps = {
  showHeader: true,
  variant: 'default'
}

export default ThingSection
