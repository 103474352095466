import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import FlexRow from '../../../../molecules/FlexRow'
import FastAvatar from '../../../../molecules/FastAvatar'
import Card from '../../../../molecules/Card'
import { useAssetSearchDeprecated } from '../../../../../api/coreData'

function AssetHeader ({ assetId }) {
  const query = useMemo(() => {
    return {
      filters: {
        assetId: [assetId]
      },
      includes: {
        avatars: true
      },
      take: 1
    }
  }, [assetId])
  const { data, isLoading } = useAssetSearchDeprecated(query)
  const asset = useMemo(() => {
    return (data || []).at(0)
  }, [data])

  if (isLoading) {
    return null
  }

  return (
    <Card>
      <FlexRow style={{ gap: '20px' }}>
        <FastAvatar avatarUrl={asset?.avatarUrl} size='md' />
        <div>
          <Typography variant='h2'>{asset.assetIdentifier}</Typography>
          <Typography variant='h4'>{asset.assetName}</Typography>
        </div>
      </FlexRow>
    </Card>
  )
}

AssetHeader.propTypes = {
  assetId: PropTypes.number
}

export default AssetHeader
