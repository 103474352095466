import PropTypes from 'prop-types'
import { alpha, makeStyles, Popover } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useTheme } from '@material-ui/styles'
import clsx from 'clsx'
import Icon from '../../../atoms/Icon'
import { defaultVisibilityLabel } from '../constants'
import { useVaultContext } from '../VaultContextProvider'

const useStyles = makeStyles((theme) => ({
  iconsContainer: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    display: 'flex',
    zIndex: 100
  },
  iconsContainerInline: {
    display: 'flex'
  },
  iconElement: {
    background: alpha(theme.palette.primary.main, 0.8),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    padding: '8px'
  },
  popoverRoot: {
    pointerEvents: 'none'
  },
  popoverContent: {
    padding: '.5rem',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '5px',
    marginTop: '4px'
  }
}))

const VisibilityIcon = ({ visibility, labels: _labels, inline = false, iconSize = '18px' }) => {
  const { labels: contextLabels } = useVaultContext()
  const classes = useStyles()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const handleMouseEnter = useCallback((e) => setAnchorEl(e.currentTarget), [setAnchorEl])
  const handleMouseLeave = useCallback(() => setAnchorEl(null), [setAnchorEl])

  const label = useMemo(() => {
    return {
      ...(defaultVisibilityLabel?.[visibility] ?? {}),
      ...(contextLabels?.visibility?.[visibility] ?? {}),
      ...(_labels?.visibility?.[visibility] ?? {})
    }
  }, [_labels, visibility, contextLabels?.visibility])

  if (!Object.keys(label)?.length) return null

  return (
    <div
      className={clsx({
        [classes.iconsContainer]: !inline,
        [classes.iconsContainerInline]: inline
      })}
    >
      <div
        className={classes.iconElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon
          name={label.icon}
          customSize={iconSize}
          color={theme.palette.primary.contrastText}
        />
      </div>
      {label?.label?.length && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverContent
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          disableRestoreFocus
        >
          {label.label}
        </Popover>
      )}
    </div>
  )
}
VisibilityIcon.propTypes = {
  visibility: {
    visibility: PropTypes.object
  },
  labels: PropTypes.object,
  inline: PropTypes.bool,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default VisibilityIcon
