import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import { useGetImageById } from '../../../../../api/media'
import Loading from '../../../../molecules/Loading'
import ImageActionMenu from '../ImageActionMenu'
import DeleteImageDialog from '../DeleteImageDIalog'
import EmptySection from '../../../../atoms/EmptySection'
import FlexRow from '../../../../molecules/FlexRow'
import Icon from '../../../../atoms/Icon'
import ImageData from './ImageData'
import ImageDisplayName from './ImageDisplayName'

const useStyles = makeStyles(theme => ({
  imageDetails: {
    width: '100%',
    padding: theme.layout.padding.p10,
    borderRadius: theme.layout.radius.loose,
    border: `1px solid ${theme.palette.gray.dark}`
  },
  imgArea: {
    maxWidth: '100%',
    borderRadius: theme.layout.radius.loose,
    marginBottom: '-6px'
  },
  imgContainer: {
    maxWidth: '100%',
    borderRadius: theme.layout.radius.loose,
    backgroundImage: 'linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%)',
    backgroundSize: '24px 24px',
    backgroundPosition: '0 0, 12px 0, 12px -12px, 0px 12px',
    position: 'relative'
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

function ImageDetails ({ image, onImageDeleted, disableActions }) {
  const { data, isFetching } = useGetImageById(image?.imageId)
  const classes = useStyles()
  const deleteImage = useRef(null)

  if (!image) {
    return (
      <FadeIn className={classes.imageDetails}>
        <EmptySection
          title=''
          header={(
            <FlexRow>
              <Icon name='image' customSize={24} />
              <div>No Image Selected</div>
            </FlexRow>
          )}
          description=''
        />
      </FadeIn>
    )
  }

  if (isFetching) {
    return (
      <FadeIn className={classes.imageDetails}>
        <Loading />
      </FadeIn>
    )
  }

  return (
    <FadeIn className={classes.imageDetails}>
      {data?.image ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FadeIn className={classes.imgContainer} key={data.image.imageId}>
              <img
                className={classes.imgArea}
                src={data.image.imageUrl}
                alt={data.image.description || data.image.displayName}
              />
              {!disableActions ? (
                <div className={classes.menuContainer}>
                  <ImageActionMenu
                    image={data.image}
                    onDelete={() => deleteImage.current.open(image)}
                  />
                </div>
              ) : null}
            </FadeIn>
          </Grid>
          <Grid item xs={12}>
            <ImageDisplayName image={data.image} />
          </Grid>
          <Grid item xs={12}>
            <ImageData image={data.image} />
          </Grid>
        </Grid>
      ) : null}
      <DeleteImageDialog ref={deleteImage} onComplete={onImageDeleted} />
    </FadeIn>
  )
}

ImageDetails.propTypes = {
  image: PropTypes.object,
  onImageDeleted: PropTypes.func,
  disableActions: PropTypes.bool
}

export default ImageDetails
