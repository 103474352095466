import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import noop from 'lodash/noop'
import clsx from 'clsx'
import SydAutocomplete from '../../../commonDesign/SydAutocomplete'
import { uploadDocument } from '../../../../service'
import SydInput from '../../../commonDesign/SydInput'
import { getFileExtension } from '../../../../utils'
import Text from '../../../atoms/Text'
import VaultUploadsVisibilityToggle from './VaultUploadsVisibilityToggle'
import { usePreviewCardStyles } from './styles'

const VaultUploadsPreviewCard = ({
  showPlaceHolder,
  showLoadingPlaceHolder,
  onDeleteFile,
  onUpdateDocument,
  document,
  tags,
  showVisibilityToggle
}) => {
  const [documentUpload, setDocumentUpload] = useState({
    progress: null,
    uploaded: false,
    hasError: false,
    aborted: false
  })
  const { extension: fileExtension } = getFileExtension(document.document.name)
  const [documentUploadRequest, setDocumentUploadRequest] = useState(null)

  const classes = usePreviewCardStyles({
    showPlaceHolder,
    progress: documentUpload.progress,
    isDocumentUploaded: documentUpload.uploaded
  })

  useEffect(() => {
    if (showPlaceHolder || !document) return
    const isDocumentUploadCompleted = documentUpload.progress === 100
    const isDocumentUploadFailed =
      documentUpload.aborted || documentUpload.hasError
    if (
      (isDocumentUploadCompleted && document.document.status !== 'uploaded') ||
      (isDocumentUploadFailed && document.document.status !== 'failed')
    ) {
      onUpdateDocument({
        ...document,
        document: {
          ...document.document,
          status: isDocumentUploadCompleted ? 'uploaded' : isDocumentUploadFailed ? 'failed' : 'pending'
        }
      })
    }
  }, [
    showPlaceHolder,
    onUpdateDocument,
    documentUpload.hasError,
    documentUpload.progress,
    documentUpload.aborted,
    document
  ])

  useEffect(() => {
    if (showPlaceHolder) return
    const xhr = new XMLHttpRequest()
    setDocumentUploadRequest(xhr)
    return () => {
      if (xhr) {
        xhr.abort()
      }
    }
  }, [showPlaceHolder])

  const onUploadFileProgress = useCallback(
    (event) =>
      setDocumentUpload((prevState) => ({
        ...prevState,
        progress: (event.loaded / event.total) * 100
      })),
    []
  )

  const onAbortDocumentUpload = useCallback(
    () =>
      setDocumentUpload((prevState) => ({
        ...prevState,
        aborted: true,
        progress: 0
      })),
    []
  )

  const uploadDocumentFile = useCallback(
    async (file) => {
      if (!document || !documentUploadRequest) return
      const { url: uploadUrl } = document
      try {
        await uploadDocument(
          uploadUrl,
          file,
          onUploadFileProgress,
          onAbortDocumentUpload,
          documentUploadRequest
        )
        setDocumentUpload((prevState) => ({
          ...prevState,
          uploaded: true
        }))
      } catch (error) {
        setDocumentUpload({
          progress: 0,
          uploaded: false,
          hasError: true
        })
        console.error('There was an error trying to upload the file', error)
      } finally {
        setDocumentUploadRequest(null)
      }
    },
    [onUploadFileProgress, onAbortDocumentUpload, documentUploadRequest, document]
  )

  const onChangeDocumentTitleHandler = useCallback(
    (e) => {
      onUpdateDocument({ ...document, name: `${e.target.value}.${fileExtension}` })
    },
    [document, fileExtension, onUpdateDocument]
  )

  const onEditTagsHandler = useCallback(
    (e, _tags) => {
      if (!document) return
      const tags = _tags?.map((tag) => ({
        id: tag.value,
        name: tag.label
      }))
      onUpdateDocument({ ...document, tags: tags })
    },
    [document, onUpdateDocument]
  )

  useEffect(() => {
    if (document.document.status !== 'pending') return
    uploadDocumentFile(document.file).then()
  }, [document, uploadDocumentFile])

  const renderDocumentUploadStatusIcon = useMemo(() => {
    if (documentUpload.hasError || documentUpload.aborted) {
      return <CloseIcon className={classes.errorIcon} />
    }
    if (documentUpload.uploaded) {
      return <CheckIcon className={classes.checkIcon} />
    }
    return null
  }, [documentUpload, classes.checkIcon, classes.errorIcon])

  if (showLoadingPlaceHolder) {
    return (
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.cardLoadingPlaceHolder)}>
          <CircularProgress color='primary' />
        </div>
      </div>
    )
  }

  if (showPlaceHolder) {
    return (
      <div className={classes.container}>
        <div className={clsx(classes.row, classes.cardPlaceHolder)}>
          <DescriptionOutlinedIcon />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <DescriptionOutlinedIcon />
        <Box display='flex' flex={1} gridGap={6} alignItems='center'>
          <Box flexGrow={1}>
            <SydInput
              defaultValue={getFileExtension(document.document.name, true)?.name}
              onChange={onChangeDocumentTitleHandler}
              size='sm'
            />
          </Box>
          <Box>
            <Text
              text={`.${fileExtension}`}
              customFontSize={16}
              lineHeight='20px'
            />
          </Box>
        </Box>
        {renderDocumentUploadStatusIcon}
        {Object.keys(documentUploadRequest ?? {})?.length && !documentUpload.aborted ? (
          <>
            <CloseIcon
              className={classes.icon}
              onClick={(e) => documentUploadRequest.abort()}
            />
          </>
        ) : (
          <DeleteOutlineIcon className={classes.icon} onClick={onDeleteFile} />
        )}
      </div>
      <div className={classes.row}>
        <LabelOutlinedIcon hidden />
        <SydAutocomplete
          size='sm'
          multiple
          options={tags}
          onChange={onEditTagsHandler}
          getOptionSelected={(option, value) => document.tags.map(x => x.id).includes(option.value)}
          selectedTags={document.tags ?? []}
          placeholder='Add tag'
        />
      </div>
      <div className={classes.row}>
        <VisibilityOutlinedIcon hidden />
        {showVisibilityToggle && (
          <Box mt='8px'>
            <VaultUploadsVisibilityToggle
              defaultVisibility={document.document.visibility}
              onToggleVisibility={(visibility) =>
                onUpdateDocument({
                  ...document,
                  document: {
                    ...document.document,
                    visibility
                  }
                })}
            />
          </Box>
        )}
      </div>
    </div>
  )
}

VaultUploadsPreviewCard.propTypes = {
  document: {
    document: {
      documentId: PropTypes.string,
      name: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.number)
    },
    url: PropTypes.string
  },
  showPlaceHolder: PropTypes.bool,
  showLoadingPlaceHolder: PropTypes.bool,
  onDeleteFile: PropTypes.func,
  onUpdateDocument: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object),
  showVisibilityToggle: PropTypes.bool
}

VaultUploadsPreviewCard.defaultProps = {
  document: undefined,
  showPlaceHolder: false,
  showLoadingPlaceHolder: false,
  onDeleteFile: noop,
  onUpdateDocument: noop,
  documentSpecification: null,
  showVisibilityToggle: true
}

export default VaultUploadsPreviewCard
