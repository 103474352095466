import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import VaultContextProvider from './VaultContextProvider'
import { DefaultVaultContent } from './DefaultVaultContent'
import DocumentDetail from './DocumentDetail'

const VaultWrapper = ({
  children: _children,
  padding = '50px 20px 20px',
  ...props
}) => {
  const { search } = useLocation()
  const children = useMemo(() => {
    if (!_children) return <DefaultVaultContent />
    return _children
  }, [_children])

  const currentDocumentId = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get('documentId') ?? null
  }, [search])

  return (
    <Box padding={padding} display='flex' flexDirection='column' height='100%'>
      <VaultContextProvider
        {...props}
      >
        {currentDocumentId
          ? <DocumentDetail documentId={currentDocumentId} />
          : children}
      </VaultContextProvider>
    </Box>
  )
}

VaultWrapper.propTypes = {
  levels: PropTypes.array,
  labels: PropTypes.object,
  children: PropTypes.node,
  uploadLevelTypeIds: PropTypes.array,
  showUploadButton: PropTypes.bool,
  availableViews: PropTypes.array,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.array,
  defaultVisibility: PropTypes.array,
  includeRecentGroup: PropTypes.bool,
  detailItems: PropTypes.array
}

export default VaultWrapper
