import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSydMenuValues, SydMenuContext } from './SydMenuContext'
import SydMenuContent from './SydMenuContent'
import SydMenuList from './SydMenuList'

const SydMenu = ({ children, options, onChange, defaultValue }) => {
  const menuContext = useSydMenuValues(options, defaultValue)

  useEffect(() => {
    onChange && onChange(menuContext.selectedValue)
  }, [onChange, menuContext.selectedValue])

  return (
    <SydMenuContext.Provider value={menuContext}>
      {children}
    </SydMenuContext.Provider>
  )
}

SydMenu.propTypes = {
  children: PropTypes.node,
  options: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string

}

SydMenu.Content = SydMenuContent
SydMenu.List = SydMenuList

export default SydMenu
