import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'

function CharacteristicsSectionDisplay ({ asset }) {
  return (
    <FadeIn>
      <Grid container spacing={3}>
        {asset?.characteristics?.map((c) => (
          <Grid item xs={12} lg={6} key={c.characteristicTypeId}>
            <SydLabel label={c.name}>
              <SydInput disabled value={c.value} />
            </SydLabel>
          </Grid>
        )) ?? null}
      </Grid>
    </FadeIn>
  )
}

CharacteristicsSectionDisplay.propTypes = {
  asset: PropTypes.shape({
    characteristics: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      sourceValue: PropTypes.any,
      normalizedDate: PropTypes.string,
      sourceValueOverride: PropTypes.any,
      characteristicTypeId: PropTypes.number,
      assetCharacteristicId: PropTypes.number
    }))
  })
}

export default CharacteristicsSectionDisplay
