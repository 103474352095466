import React from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Card from '../../../../../molecules/Card'
import ErrorBoundary from '../../../../../molecules/ErrorBoundary'
import ImageSection from './ImageSection'

function ImagesTool () {
  return (
    <ErrorBoundary name='Asset Images Tab'>
      <FadeIn>
        <Card>
          <ImageSection />
        </Card>
      </FadeIn>
    </ErrorBoundary>
  )
}

export default ImagesTool
