import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import SydLabel, { hookFormFieldErrorAdapter } from '../../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../../commonDesign/SydInput'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import { useModifyAssetDisplaySymbol } from '../../../../../../../api/accounts'

const useSubmitter = (form, onComplete, asset) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: modifyDisplaySymbol } = useModifyAssetDisplaySymbol()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      assetId: asset.assetId,
      displaySymbol: formData.displaySymbol || null
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await modifyDisplaySymbol(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to set display symbol')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [modifyDisplaySymbol, setProcessing, onComplete, asset, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  return {
    submitter,
    processing,
    error
  }
}

function ModifyDisplaySymbolForm ({ asset, onCancel, onComplete }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      assetId: asset.assetId,
      displaySymbol: asset.displaySymbol || ''
    }
  })

  const { submitter, processing, error } = useSubmitter(form, onComplete, asset)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Controller
          name='displaySymbol' control={form.control}
          rules={{ maxLength: { value: 50, message: 'Max 50 Characters' } }}
          render={(f => (
            <SydLabel label='Display Symbol' error={hookFormFieldErrorAdapter(f.fieldState)} description='Max 50 Characters'>
              <SydInput {...f.field} autoFocus />
            </SydLabel>
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            disabled={!form.formState?.isValid}
            processing={processing}
          >
            Save Display Symbol
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyDisplaySymbolForm.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number,
    displaySymbol: PropTypes.string
  }),
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModifyDisplaySymbolForm
