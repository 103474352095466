import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import SectionScreen from '../../shared/SectionScreen'
import SectionHeader from '../../shared/SectionHeader'
import { useManagerContext } from '../ManagerProvider'
import { useAssetSearchDeprecated } from '../../../../../api/coreData'
import SydReadOnly from '../../../../commonDesign/SydReadOnly'
import { ADMIN_ROUTES } from '../../../../../constants'
import SydLabel from '../../../../commonDesign/SydLabel'

const assetDescriptionText = `
  The Manager you create here is intended to be used in SMA Reporting.
  When you create a Manager, we create a corresponding Asset that should be classified in Admin > Assets.
  This will be used to support reporting on assigned accounts as “Assets” within performance and allocation reporting.
`

function AssetLink ({ asset, isLoading }) {
  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!asset) {
    return <div>--</div>
  }

  return (
    <Link to={`${ADMIN_ROUTES.CLASSIFY_ASSETS}/${asset.assetId}`}>
      {asset.assetName}
    </Link>
  )
}

AssetLink.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number,
    assetName: PropTypes.string
  }),
  isLoading: PropTypes.bool
}

function AssetDisplay ({ assetId }) {
  const { editing } = useManagerContext()
  const query = useMemo(() => ({
    filters: {
      assetId
    },
    take: 1
  }), [assetId])
  const { data, isLoading } = useAssetSearchDeprecated(query)

  const asset = useMemo(() => {
    if (!data?.length) return null
    return data.at(0)
  }, [data])

  return (
    <SectionScreen editing={editing}>
      <SectionHeader text='Asset' />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SydLabel
            label='Linked Asset'
            description={assetDescriptionText}
          >
            <SydReadOnly disabled iconName='link'>
              <AssetLink asset={asset} isLoading={isLoading} />
            </SydReadOnly>
          </SydLabel>
        </Grid>
      </Grid>
    </SectionScreen>
  )
}

AssetDisplay.propTypes = {
  assetId: PropTypes.number
}

export default AssetDisplay
