import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import SydButton from '../../../../../../commonDesign/Button'
import Editor from '../../../../../../organisms/WYSIWYGEditor'
import Card from '../../../../../../molecules/Card'
import { useSetThingContent } from '../../../../../../../api/things'

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    '& .editor-input': {
      boxShadow: '0 4px 2px -4px inset',
      overflow: 'auto',
      maxHeight: '50vh'
    }
  }
}))

function EditThingForm ({ asset, thing, thingConfiguration, onCancel, onComplete }) {
  const classes = useStyles()
  const [content, setContent] = useState(thing?.content || '')
  const [processing, setProcessing] = useState(false)
  const { mutateAsync: setThingContent } = useSetThingContent()

  const handleSubmit = useCallback(async (contentValue) => {
    try {
      setProcessing(true)
      const command = {
        levelTypeId: 3,
        levelId: asset.assetId,
        code: thingConfiguration.code,
        content: contentValue || null
      }
      await setThingContent(command)
      onComplete(command)
    } catch (err) {
      console.error(err)
    } finally {
      setProcessing(false)
    }
  }, [asset, thingConfiguration, setProcessing, setThingContent, onComplete])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card variant='outline' style={{ padding: 0, overflow: 'hidden' }}>
          <Editor
            className={classes.editorContainer}
            defaultValue={thing?.content || ''}
            placeholder={thingConfiguration?.description}
            isMarkdown
            onChange={setContent}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            disabled={!content && !thing?.thingId}
            processing={processing}
            onClick={() => handleSubmit(content)}
          >
            Save Content
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

EditThingForm.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number
  }),
  thing: PropTypes.object,
  thingConfiguration: PropTypes.object,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func
}

export default EditThingForm
