import React from 'react'
import PropTypes from 'prop-types'
import EmptySection from '../../atoms/EmptySection'
import { useTearSheetContext } from './TearSheetProvider'

function TearSheet ({ children }) {
  const tsContext = useTearSheetContext()

  if (!tsContext) {
    return (
      <EmptySection title='Cannot use a tear sheet outside of a valid context' description='' />
    )
  }

  return children
}

TearSheet.propTypes = {
  children: PropTypes.node
}

export default TearSheet
