import React from 'react'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import { useSectionEditing, useAssetDetails } from '../../AssetProvider'
import OtherInfoSectionDisplay from './Display'

function OtherInfoSection () {
  const { editing, asset } = useAssetDetails()
  const sectionIsEditing = useSectionEditing('otherInfo')

  return (
    <FadeIn>
      <SectionScreen editing={editing} sectionIsEditing={sectionIsEditing}>
        <SectionHeader text='Other Information' />
        <OtherInfoSectionDisplay asset={asset} />
      </SectionScreen>
    </FadeIn>
  )
}

OtherInfoSection.propTypes = {
}

export default OtherInfoSection
