import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { makeStyles } from '@material-ui/core'
import Icon from '../../atoms/Icon'
import { ICON_NAMES, KEYS } from '../../../constants'
import { useDebounce } from '../../../hooks/useDebounce'

const useStyles = makeStyles((theme) => ({
  searchBar: {
    height: '100%',
    width: '30rem',
    maxWidth: '30rem',
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: '30px',
    padding: '0 1rem 0 .7rem',
    backgroundColor: theme.palette.gray.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > input': {
      lineHeight: '2rem',
      backgroundColor: 'transparent',
      fontFamily: theme.typography.fontFamily,
      width: '100%',
      border: 'none',
      fontSize: '1rem',
      borderTopRightRadius: '1.5rem',
      borderBottomRightRadius: '1.5rem',
      '&:focus': {
        outline: 'none'
      },
      '&:-webkit-input-placeholder': {
        opacity: '0.2',
        fontWeight: 'bold'
      }
    },
    '& .__icon': {
      marginRight: '5px'
    }
  }
}))

const SearchBar = forwardRef(({
  onChange = noop,
  placeholder,
  delay = 1000
}, ref) => {
  const classes = useStyles()

  const [localValue, setLocalValue] = useState('')
  const [debouncedValue] = useDebounce(localValue, delay)

  useImperativeHandle(ref, () => ({
    clear: () => setLocalValue({ value: '', immediate: false })
  }), [])

  const change = useCallback((e) => {
    setLocalValue && setLocalValue({ value: e.target.value, immediate: false })
  }, [setLocalValue])

  useEffect(() => {
    setTimeout(() => {
      onChange(debouncedValue)
    }, 0)
  }, [debouncedValue, onChange])

  const inputRef = useRef(null)

  const onKeyPress = useCallback(e => {
    if (e.target !== inputRef.current) return
    if (e.which !== KEYS.ENTER) return

    e.stopPropagation()
    e.preventDefault()
    setLocalValue && setLocalValue({ value: e.target.value, immediate: true })
  }, [setLocalValue])

  useEffect(() => {
    const kp = onKeyPress
    window.addEventListener('keypress', kp)
    return () => {
      window.removeEventListener('keypress', kp)
    }
  }, [onKeyPress])

  return (
    <div className={classes.searchBar}>
      <Icon additionalClasses='__icon' name={ICON_NAMES.search} customSize='1.5rem' />
      <input
        ref={inputRef}
        value={localValue.value}
        onChange={change}
        placeholder={placeholder}
      />
    </div>
  )
})

SearchBar.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  delay: PropTypes.number
}

export default SearchBar
