import React from 'react'
import PropTypes from 'prop-types'
import { alpha, getContrastRatio, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const cardVariants = {
  default: (theme) => ({
    border: `2px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.background.card,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  outline: (theme) => ({
    border: `2px solid ${theme.palette.gray.darker}`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.background.clear,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  frosted: (theme) => ({
    position: 'relative',
    border: `2px solid ${theme.palette.gray.dark}`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: alpha(theme.palette.background.default, 0.5),
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick,
    '&::before': {
      backdropFilter: 'blur(10px)',
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: -1
    }
  }),
  clear: (theme) => ({
    padding: theme.layout.padding.thick
  }),
  primary: (theme) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: `color-mix(in srgb, ${theme.palette.primary.main} 75%, #ffffff)`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.primary.main,
    color: getContrastRatio(theme.palette.text.primary, '#fff') > 4.5 ? '#fff' : theme.palette.text.primary,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  secondary: (theme) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: `color-mix(in srgb, ${theme.palette.secondary.main} 75%, #ffffff)`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.secondary.main,
    color: getContrastRatio(theme.palette.text.primary, '#fff') > 4.5 ? '#fff' : theme.palette.text.primary,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  accent: (theme) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: `color-mix(in srgb, ${theme.palette.accent.main} 75%, #ffffff)`,
    borderRadius: theme.layout.radius.loose,
    backgroundColor: theme.palette.accent.main,
    color: getContrastRatio(theme.palette.text.primary, '#fff') > 4.5 ? '#fff' : theme.palette.text.primary,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  primaryText: (theme) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.layout.radius.loose,
    color: theme.palette.primary.main,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  secondaryText: (theme) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
    borderRadius: theme.layout.radius.loose,
    color: theme.palette.secondary.main,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  }),
  accentText: (theme) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
    borderRadius: theme.layout.radius.loose,
    color: theme.palette.accent.main,
    boxShadow: theme.layout.shadow.medium,
    padding: theme.layout.padding.thick
  })
}

const mapVariant = (theme, variant) => {
  if (variant in cardVariants) {
    return cardVariants[variant](theme)
  }

  return cardVariants.default(theme)
}

const useStyles = makeStyles((theme) => ({
  card: ({ variant }) => ({
    ...(mapVariant(theme, variant))
  })
}))

function Card ({ variant, children, className, style, ...rest }) {
  const classes = useStyles({ variant })

  return (
    <div className={clsx(classes.card, className)} style={style} {...rest}>
      {children}
    </div>
  )
}

Card.propTypes = {
  variant: PropTypes.oneOf(['default', 'outline', 'frosted', 'clear', 'primary', 'secondary', 'accent', 'primaryText', 'secondaryText', 'accentText']),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

Card.defaultProps = {
  variant: 'default'
}

export default Card
