import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAssetSearchDeprecated } from '../../../api/coreData'
import { useAssetTearSheetView } from '../../../api/abundanceEngine'
import Loading from '../../molecules/Loading'
import { useHideSideMenuEffect, useSetAppContext } from '../../../redux/slices/appContext'
import TearSheetView from './TearSheetView'
import TearSheetProvider from './TearSheetProvider'

const useAssetId = () => {
  const { assetId } = useParams()
  return useMemo(() => {
    const result = Number(assetId)
    if (isNaN(result)) return null
    return result
  }, [assetId])
}

function AssetTearSheet () {
  const assetId = useAssetId()
  useHideSideMenuEffect([])
  const assetQuery = useMemo(() => {
    return { filters: { assetId }, take: 1 }
  }, [assetId])
  const { data: assets, isLoading: assetLoading } = useAssetSearchDeprecated(assetQuery)
  const { data: view, isLoading: viewLoading } = useAssetTearSheetView(assetId)
  const tearSheetValue = useMemo(() => {
    const _assets = assets || []
    const subject = _assets?.length ? _assets.at(0) : null
    return {
      levelTypeId: 3,
      levelId: subject?.assetId
    }
  }, [assets])
  const setAppContext = useSetAppContext()

  useEffect(() => {
    if (tearSheetValue?.subject?.assetName) {
      setAppContext({
        viewTitle: tearSheetValue.subject.assetName
      })
      return () => {
        setAppContext({
          viewTitle: null
        })
      }
    }
  }, [tearSheetValue, setAppContext])

  if (assetLoading || viewLoading) {
    return (
      <Loading />
    )
  }

  return (
    <TearSheetProvider value={tearSheetValue}>
      <TearSheetView
        jsx={view?.jsxView}
        bindings={{
          assetId,
          view
        }}
      />
    </TearSheetProvider>
  )
}

export default AssetTearSheet
