import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Crect x='1' y='1' width='16' height='16' rx='2' fill='white' stroke='%23EEF0F8' stroke-width='2'/%3E%3C/svg%3E\")",
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' fill='%23212945' stroke='%23212945'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.2114 6.28864C10.8266 5.90379 10.2026 5.90379 9.81776 6.28864L7.3779 8.72848L6.6823 8.03288C6.29745 7.64803 5.67349 7.64803 5.28864 8.03288C4.90379 8.41773 4.90379 9.04169 5.28864 9.42654L6.6823 10.8202C7.06715 11.205 7.69111 11.205 8.07596 10.8202C8.08995 10.8062 8.10343 10.7919 8.1164 10.7773L11.2114 7.6823C11.5963 7.29745 11.5963 6.67349 11.2114 6.28864Z' fill='white'/%3E%3C/svg%3E\")",
      content: '""'
    }
  }
})

function StyledCheckbox (props) {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      disableRipple
      {...props}
    />
  )
}

const CustomCheckbox = StyledCheckbox

export default CustomCheckbox
