import React from 'react'
import { useTearSheetContext } from '../TearSheetProvider'
import AssetSubjectHeader from './AssetSubjectHeader'
import AccountSubjectHeader from './AccountSubjectHeader'

function SubjectHeader (props) {
  const tsContext = useTearSheetContext()

  if (tsContext?.levelTypeId === 1) {
    return (
      <AccountSubjectHeader accountId={tsContext.levelId} {...props} />
    )
  }

  if (tsContext?.levelTypeId === 3) {
    return (
      <AssetSubjectHeader assetId={tsContext.levelId} {...props} />
    )
  }

  return null
}

export default SubjectHeader
