import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import ThingCategory from './ThingCategory'
import EditThingDialog from './EditDialog'

function ThingContentForm ({ asset, schema, values }) {
  const editRef = useRef(null)

  return (
    <Grid container spacing={3}>
      {schema.map(category => (
        <Grid item xs={12} key={category.code}>
          <ThingCategory
            category={category}
            things={values?.things || []}
            onEdit={(thing, thingConfig) => editRef.current.open(asset, thing, thingConfig)}
          />
        </Grid>
      ))}
      <EditThingDialog ref={editRef} />
    </Grid>
  )
}

ThingContentForm.propTypes = {
  asset: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  values: PropTypes.any
}

export default ThingContentForm
