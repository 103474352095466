import React from 'react'
import { Grid } from '@material-ui/core'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import PersonalSpace from '../../shared/PersonalSpace'
import SummitOnlyContent from '../../../../atoms/SummitOnlyContent'
import AttributesSection from './AttributesSection'
import ClassificationsSection from './ClassificationsSection'
import OtherInfoSection from './OtherInfoSection'
import CharacteristicsSection from './CharacteristicsSection'

function GeneralTab () {
  return (
    <FadeIn>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AttributesSection />
        </Grid>
        <Grid item xs={12} md={6}>
          <ClassificationsSection />
        </Grid>
        <Grid item xs={12} md={6}>
          <OtherInfoSection />
        </Grid>
        <SummitOnlyContent>
          <Grid item xs={12} md={6}>
            <CharacteristicsSection />
          </Grid>
        </SummitOnlyContent>
      </Grid>
      <PersonalSpace />
    </FadeIn>
  )
}

GeneralTab.propTypes = {
}

export default GeneralTab
