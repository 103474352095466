import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ViewTabs from '../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../hooks'
import GeneralTab from './GeneralTab'
import PositionsTab from './PositionsTab'
import ContentTab from './ContentTab'

const tabOptions = {
  general: {
    value: 'general',
    label: 'General Info'
  },
  positions: {
    value: 'positions',
    label: 'Positions'
  },
  content: {
    value: 'content',
    label: 'Content'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.admin_view_assets) {
      result.general = tabOptions.general
    }
    if (policies.admin_view_assets) {
      result.positions = tabOptions.positions
    }
    if (policies.admin_view_assets) {
      result.content = tabOptions.content
    }

    return result
  }, [policies])
}

function AssetTabs ({ disabled }) {
  const tabs = useTabOptions()

  return (
    <div>
      <ViewTabs.Group tabOptions={tabs}>
        <div>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_status_template_details'
          />
        </div>
        {tabs.general ? (
          <ViewTabs.Tab value='general'>
            <GeneralTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.positions ? (
          <ViewTabs.Tab value='positions'>
            <PositionsTab />
          </ViewTabs.Tab>
        ) : null}
        {tabs.content ? (
          <ViewTabs.Tab value='content'>
            <ContentTab />
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

AssetTabs.propTypes = {
  disabled: PropTypes.bool
}

AssetTabs.defaultProps = {
  disabled: false
}

export default AssetTabs
