import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { usePolicy } from '../../../../hooks/usePolicy'
import Icon from '../../../atoms/Icon'

function EditButton ({ policy, onClick, editing, disabled, icon, style }) {
  const allowed = usePolicy(policy)

  if (!allowed) return null

  return (
    <IconButton
      style={style}
      disabled={disabled || editing}
      onClick={onClick}
    >
      <Icon name={icon} />
    </IconButton>
  )
}

EditButton.propTypes = {
  policy: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  editing: PropTypes.bool,
  icon: PropTypes.string,
  style: PropTypes.object
}

EditButton.defaultProps = {
  policy: null,
  disabled: false,
  editing: false,
  icon: 'edit'
}

export default EditButton
