import { useState } from 'react'
import { fetchUserViews } from '../../../../redux/slices/appConfig'

/**
 * Will get the default view if it is tagged as a wheel view or the first wheel view if not
 * @param {string} [fallback]
 * @return {{isLoading: boolean, redirectPath: string}}
 */
export const useGetUserWheelViewPath = (fallback = '/') => {
  const [path, setPath] = useState(fallback)
  const [isLoading, setIsLoading] = useState(true)

  fetchUserViews().then(({ data }) => {
    const redirectPath = (
      data?.find(x => x.tagName === 'wheel' && !!x.isDefault) ??
      data?.find(x => x.tagName === 'wheel')
    )?.path || fallback
    setPath(!redirectPath.startsWith('/views/') ? `/views/${redirectPath}` : redirectPath)
    setIsLoading(false)
  }).catch((e) => {
    setPath(fallback)
    setIsLoading(false)
  })

  return {
    path,
    isLoading
  }
}

export default useGetUserWheelViewPath
