import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SydLabel from '../../../../../commonDesign/SydLabel'
import SydInput from '../../../../../commonDesign/SydInput'
import FlexRow from '../../../../../molecules/FlexRow'
import EditIconButton from '../../../shared/EditIconButton'

function ClassificationsSectionDisplay ({ asset, schema, onEditClassification }) {
  return (
    <FadeIn>
      <Grid container spacing={3}>
        {schema?.map((tagType) => {
          const match = asset.classificationTags?.find(ct => ct.classificationTagTypeId === tagType.classificationTagTypeId)
          return (
            <Grid item xs={12} lg={6} key={tagType.classificationTagTypeId}>
              <SydLabel label={tagType.longName || tagType.shortName} required={!!tagType.required}>
                <FlexRow>
                  <SydInput minWidth='0px' disabled value={match?.longName ?? '--'} />
                  <EditIconButton style={{ flex: '1 0' }} policy='admin_edit_assettags' onClick={() => onEditClassification(asset, tagType)} />
                </FlexRow>
              </SydLabel>
            </Grid>
          )
        }) ?? null}
      </Grid>
    </FadeIn>
  )
}

ClassificationsSectionDisplay.propTypes = {
  asset: PropTypes.shape({
    classificationTags: PropTypes.arrayOf(PropTypes.shape({
      classificationTagId: PropTypes.number,
      classificationType: PropTypes.string,
      classificationTagTypeId: PropTypes.number,
      shortName: PropTypes.string,
      longName: PropTypes.string,
      ordinal: PropTypes.number,
      colorField: PropTypes.string,
      classificationTagTypeName: PropTypes.string,
      classificationTagTypeLongName: PropTypes.string,
      defaultTagId: PropTypes.number
    }))
  }),
  schema: PropTypes.arrayOf(PropTypes.shape({
    classificationTagTypeId: PropTypes.number,
    classificationType: PropTypes.string,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    defaultTagId: PropTypes.number,
    required: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    classificationTags: PropTypes.arrayOf(PropTypes.shape({
      classificationTagId: PropTypes.number,
      classificationType: PropTypes.string,
      shortName: PropTypes.string,
      longName: PropTypes.string,
      ordinal: PropTypes.number,
      colorField: PropTypes.string
    }))
  })),
  onEditClassification: PropTypes.func
}

export default ClassificationsSectionDisplay
