import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Box } from '@material-ui/core'
import Tabs from '../../../molecules/Tabs'
import { useSetViewTitle } from '../../../../redux/slices/appContext'
import VaultTagsAdmin from './VaultTagsAdmin'
import VaultDocumentAdmin from './VaultDocumentAdmin'

const VaultAdmin = () => {
  const history = useHistory()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState('documents')
  const setViewTitle = useSetViewTitle()
  setViewTitle('Vault Admin')

  useEffect(() => {
    if (!['documents', 'tags'].includes(location.pathname.split('/').pop())) {
      history.replace('/admin/vault/documents')
    }
    setSelectedTab(location.pathname.split('/').pop())
  }, [history, location])

  const tabOptions = [
    { label: 'Manage Documents', value: 'documents' },
    { label: 'Manage Tags', value: 'tags' }
  ]

  const handleSelectValue = useCallback((value) => {
    if (selectedTab === value) return
    history.push(`/admin/vault/${value}`)
  }, [selectedTab, history])

  return (
    <Box px={2}>
      <Tabs.Wrapper noMargin>
        <Tabs
          value={selectedTab}
          indicatorColor='primary'
          textColor='primary'
          onChange={(_, tabValue) => {
            handleSelectValue(tabValue)
          }}
        >
          {tabOptions.map((o) => {
            return (
              <Tabs.Tab
                key={o.value}
                value={o.value}
                label={o.label}
                disabled={o.disabled}
              />
            )
          })}
        </Tabs>
      </Tabs.Wrapper>
      {selectedTab === 'documents' && (
        <VaultDocumentAdmin />
      )}
      {selectedTab === 'tags' && (
        <VaultTagsAdmin />
      )}
    </Box>
  )
}

export default VaultAdmin
