import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useListImages } from '../../../api/media'
import { useMediaScope } from './useMediaScope'

function MediaBackground ({
  levelTypeId,
  purposeCode,
  style,
  className,
  children,
  levelId
}) {
  const mediaScope = useMediaScope({ levelTypeId, levelId })
  const query = useMemo(() => {
    return {
      purpose: {
        purposeCode,
        ...mediaScope
      },
      take: 1
    }
  }, [purposeCode, mediaScope])
  const { data: images } = useListImages(query)

  const _style = useMemo(() => {
    const backgroundImage = images?.data?.at?.(0) ? `url(${images.data.at(0).imageUrl})` : undefined
    return {
      ...(style || {}),
      backgroundImage
    }
  }, [images, style])

  return (
    <div className={className} style={_style}>
      {children}
    </div>
  )
}

MediaBackground.propTypes = {
  levelTypeId: PropTypes.number.isRequired,
  purposeCode: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  levelId: PropTypes.number
}

export default MediaBackground
