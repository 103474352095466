import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SydInput from '../../../../../commonDesign/SydInput'
import SydLabel from '../../../../../commonDesign/SydLabel'
import FlexRow from '../../../../../molecules/FlexRow'
import EditIconButton from '../../../shared/EditIconButton'

function AttributesSectionDisplay ({ asset, onEditDisplayName, onEditDisplaySymbol }) {
  return (
    <FadeIn>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Asset ID'>
            <SydInput disabled value={asset.assetId} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SydLabel label='CUSIP'>
            <SydInput disabled value={asset.cusip || ''} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Default Name'>
            <SydInput disabled value={asset.longName || ''} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Display Name'>
            <FlexRow>
              <SydInput minWidth='0px' disabled value={asset.displayName || ''} />
              <EditIconButton policy='admin_edit_assetdetails' onClick={onEditDisplayName} />
            </FlexRow>
          </SydLabel>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Symbol'>
            <SydInput disabled value={asset.symbol || ''} />
          </SydLabel>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Display Symbol'>
            <FlexRow>
              <SydInput minWidth='0px' disabled value={asset.displaySymbol || ''} />
              <EditIconButton policy='admin_edit_assetdetails' onClick={onEditDisplaySymbol} />
            </FlexRow>
          </SydLabel>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SydLabel label='Unique Identifier'>
            <SydInput disabled value={asset.uniqueIdentifier || ''} />
          </SydLabel>
        </Grid>
      </Grid>
    </FadeIn>
  )
}

AttributesSectionDisplay.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number,
    assetIdentifier: PropTypes.string,
    assetName: PropTypes.string,
    displayName: PropTypes.string,
    symbol: PropTypes.string,
    displaySymbol: PropTypes.string,
    cusip: PropTypes.string,
    isin: PropTypes.string,
    uniqueIdentifier: PropTypes.string,
    summitIdentifier: PropTypes.string,
    shortName: PropTypes.string,
    longName: PropTypes.string
  }),
  onEditDisplayName: PropTypes.func,
  onEditDisplaySymbol: PropTypes.func
}

export default AttributesSectionDisplay
