import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useTheme } from '@material-ui/core'
import { useFullClientSearch } from '../../../../../../api/clients'
import { Blade } from '../../../shared/Blade'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import Loading from '../../../../../molecules/Loading'
import MiniTable from '../../../shared/MiniTable'
import { usePickerStyles } from './usePickerStyles'

const clientKeyAccessor = client => client?.clientId
const query = {}

function ClientLevelPicker ({ columns, filterOn, onSelect, selected }) {
  const classes = usePickerStyles()
  const theme = useTheme()
  const clients = useFullClientSearch(query)
  const _selected = useMemo(() => {
    return (clients?.data || []).find(x => x.clientId === selected)
  }, [selected, clients?.data])
  const [filter, setFilter] = useState('')
  const summary = useMemo(() => {
    const lowerFilter = filter.toLowerCase()
    return (clients.data || [])
      .filter(x => !lowerFilter || (filterOn || []).reduce((prev, cur) => {
        const val = get(x, cur.accessor)?.toString()?.toLowerCase()
        // eslint-disable-next-line eqeqeq
        const res = cur.exact ? val == lowerFilter : val.includes(lowerFilter)
        return prev || res
      }, false))
  }, [filter, clients.data, filterOn])

  if (clients.error) {
    return (
      <div className={classes.blade}>
        Sorry, there was an error loading this view
      </div>
    )
  }

  return (
    <Blade scrollable={false} style={{ borderRadius: '4px', borderColor: theme.palette.gray.dark, borderWidth: '1px' }}>
      <div className={classes.wrapper}>
        <div className={classes.topBar}>
          <div>
            <DebouncedInput className={classes.search} onChange={setFilter} delay={100} />
          </div>
        </div>
        {clients.isLoading ? (
          <div>
            <Loading />
            <FadeIn delay={5} duration={5} className={classes.loadingMessage}>
              Pre-loading clients for a better experience
            </FadeIn>
          </div>
        ) : summary?.length ? (
          <div className={classes.table}>
            <div>
              <MiniTable
                data={summary}
                columns={columns}
                keyAccessor={clientKeyAccessor}
                onSelect={onSelect}
                selected={_selected}
              />
            </div>
          </div>
        ) : (
          <div className={classes.noData}>
            No Clients Available
          </div>
        )}
      </div>
    </Blade>
  )
}

ClientLevelPicker.propTypes = {
  columns: PropTypes.array,
  filterOn: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.any
}

ClientLevelPicker.defaultProps = {
  columns: [
    { accessor: 'clientId', header: 'ID' },
    { accessor: 'longName', header: 'Name' }
  ],
  filterOn: [
    { accessor: 'clientId', exact: true },
    { accessor: 'longName' }
  ]
}

export default ClientLevelPicker
