import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { postNamedCommand, postNamedQuery } from '../service'
import { useAppContext } from '../redux/slices/appContext'
import { QUERY_KEYS } from './queryKeys'

export const useLevelThings = (levelTypeId, levelId) => {
  const { userId } = useAppContext()
  return useQuery({
    queryKey: ['get-level-things', userId, levelTypeId, levelId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-things', {
        levelTypeId,
        levelId
      })

      return data
    }
  })
}

export const useGetThingSchema = (levelTypeId, options = {}) => {
  const { enabled = true, mapper } = options
  return useQuery({
    enabled,
    queryKey: ['levels', QUERY_KEYS.getThingSchema, levelTypeId],
    queryFn: async () => {
      const { data } = await postNamedQuery('levels', 'get-thing-schema', {
        levelTypeId
      })
      return data
    },
    select: mapper
  })
}

export const useSetThingContent = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ levelTypeId, levelId, code, content }) => {
      const { data } = await postNamedCommand('levels', 'set-thing-content', {
        levelTypeId, levelId, code, content
      })

      return data
    },
    onSuccess: (data, command) => {
      queryClient.invalidateQueries({ queryKey: ['get-level-things'], refetchType: 'all' }).catch(console.error)
    }
  })
}
