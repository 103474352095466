import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Icon from '../../../atoms/Icon'
import Text from '../../../atoms/Text'

const VaultEmptyState = ({
  icon,
  title,
  subtitle,
  uploadStatement
}) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' mt={8} minHeight='450px' height='calc(100vh-34px)'>
      <Icon name={icon} customSize='5rem' />
      <Text variant='h3'>{title}</Text>
      <Text variant='body'>{subtitle}</Text>
      {uploadStatement && <Text variant='body'>{uploadStatement}</Text>}
    </Box>
  )
}

VaultEmptyState.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  uploadStatement: PropTypes.string
}

export default VaultEmptyState
