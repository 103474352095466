import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { Blade } from '../../../shared/Blade'
import Loading from '../../../../../molecules/Loading'
import MiniTable from '../../../shared/MiniTable'
import DebouncedInput from '../../../../../molecules/DebouncedInput'
import { useAssetSearchDeprecated } from '../../../../../../api/coreData'
import { usePickerStyles } from './usePickerStyles'

const columns = [
  { accessor: 'assetId', header: 'ID' },
  { accessor: 'assetIdentifier', header: 'Symbol' },
  { accessor: 'assetName', header: 'Name' }
]
const assetKeySelector = asset => asset?.assetId

function AssetLevelPicker ({ onSelect, selected }) {
  const classes = usePickerStyles()
  const theme = useTheme()
  const [filter, setFilter] = useState('')
  const query = useMemo(() => {
    return {
      textSearch: {
        assetName: filter || undefined,
        assetIdentifier: filter || undefined
      },
      filters: {
      },
      take: 100
    }
  }, [filter])
  const { data: assets, isLoading } = useAssetSearchDeprecated(query)
  const [_assets, _selected] = useMemo(() => {
    const _assets = assets || []
    return [_assets, _assets.find(x => x.assetId === selected)]
  }, [selected, assets])

  return (
    <Blade scrollable={false} style={{ borderRadius: '4px', borderColor: theme.palette.gray.dark, borderWidth: '1px' }}>
      <div className={classes.wrapper}>
        <div className={classes.topBar}>
          <div>
            <DebouncedInput className={classes.search} onChange={setFilter} delay={100} />
          </div>
        </div>
        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : _assets?.length ? (
          <div className={classes.table}>
            <div>
              <MiniTable
                data={_assets}
                columns={columns}
                keyAccessor={assetKeySelector}
                onSelect={onSelect}
                selected={_selected}
              />
            </div>
          </div>
        ) : (
          <div className={classes.noData}>
            No Assets Available
          </div>
        )}
      </div>
    </Blade>
  )
}

AssetLevelPicker.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.any
}

AssetLevelPicker.defaultProps = {}

export default AssetLevelPicker
