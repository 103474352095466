import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import PresentationTable from '../../organisms/PresentationTable'
import { useBenchmarkReturns, useNormalizeDates } from '../../../api/coreData'
import { mapDateRangesToKeyValue, useRelativeDateRanges } from '../RelativeDateSelect'
import { useAppContext } from '../../../redux/slices/appContext'
import { findAndReplacePerformanceAccessors } from '../../organisms/PerformanceTableV2/usePerformanceColumns'
import { useColumns } from './columnConfig'

const mapBenchmarkReturns = (data, pivotKeys = ['benchmarkReturn', 'annualizedBenchmark']) => {
  const benchmarkReturnsMerged = Object.entries(data).reduce(
    (acc, [dateRangeKey, benchmarkReturns]) => {
      const returns = benchmarkReturns.map(benchmarkItem => {
        const baseResult = Object.entries(benchmarkItem).reduce((agg, [key, value]) => {
          if (!pivotKeys.includes(key)) {
            agg[key] = value
          }
          return agg
        }, {})
        return pivotKeys.reduce((agg, k) => {
          agg[`${dateRangeKey}_${k}`] = benchmarkItem[k]
          return agg
        }, baseResult)
      })
      return [...acc, ...returns]
    },
    []
  )

  const uniqueBenchmarks = benchmarkReturnsMerged.reduce(
    (acc, benchmarkReturn) => ({
      ...acc,
      [benchmarkReturn.benchmarkId]: {
        ...(acc?.[benchmarkReturn.benchmarkId] || {}),
        ...benchmarkReturn
      }
    }),
    {}
  )
  return Object.values(uniqueBenchmarks)
}

const BenchmarksTable = forwardRef((
  {
    sortable = true,
    queryParams = {},
    dateRanges,
    columnConfig = undefined,
    canChangeDateRange = true,
    pivotKeys,
    benchmarkOrder,
    variant
  },
  ref
) => {
  const { availableDates } = useAppContext()

  const { dateRanges: relativeDateRangeOptions } = useRelativeDateRanges(
    dateRanges,
    availableDates
  )

  const normalizedDatesQuery = useMemo(() => {
    return {
      dateRanges: mapDateRangesToKeyValue(relativeDateRangeOptions)
    }
  }, [relativeDateRangeOptions])

  const {
    data: normalizedDateRanges,
    isLoading: isLoadingNormalizedDates
  } = useNormalizeDates(normalizedDatesQuery)

  const benchmarkReturnsQuery = useMemo(() => {
    return {
      query: {
        ...queryParams,
        dateRanges: normalizedDateRanges
      },
      queryOptions: {
        mapper: (data) => mapBenchmarkReturns(data, pivotKeys),
        enabled: !isLoadingNormalizedDates
      }
    }
  }, [queryParams, isLoadingNormalizedDates, normalizedDateRanges, pivotKeys])

  const { data = [], isLoading } = useBenchmarkReturns(
    benchmarkReturnsQuery.query,
    benchmarkReturnsQuery.queryOptions
  )

  const augmentedData = useMemo(() => {
    if (!benchmarkOrder?.length) {
      return data.map(b => ({
        ...b,
        supplementalOrder: 0
      }))
    }
    return data.map(b => ({
      ...b,
      supplementalOrder: (benchmarkOrder.findIndex(bo => bo === b.benchmarkId) ?? 0) + 1
    }))
  }, [data, benchmarkOrder])

  const { columns, defaultSort } = useColumns({
    columnsConfig: columnConfig,
    canChangeDateRange
  })

  const [columConfig, setColumnConfig] = useState({})

  useEffect(() => {
    if (!isEmpty(dateRanges)) {
      const columnConfigTransformed = findAndReplacePerformanceAccessors(
        { columns },
        relativeDateRangeOptions.map(row => row.key),
        relativeDateRangeOptions
      )
      setColumnConfig(columnConfigTransformed)
    }
  }, [columns, dateRanges, relativeDateRangeOptions])

  return (
    <PresentationTable.Wrapper>
      <PresentationTable
        variant={variant}
        ref={ref}
        columns={columConfig?.columns || []}
        data={augmentedData}
        loading={isLoading}
        defaultSort={defaultSort}
        sortable={sortable}
      />
    </PresentationTable.Wrapper>
  )
})

export const benchmarkTableProps = {
  sortable: PropTypes.bool,
  queryParams: PropTypes.object,
  canChangeDateRange: PropTypes.bool,
  dateRanges: PropTypes.arrayOf(PropTypes.string),
  columnConfig: PropTypes.arrayOf(PropTypes.object),
  pivotKeys: PropTypes.arrayOf(PropTypes.string),
  benchmarkOrder: PropTypes.arrayOf(PropTypes.number),
  variant: PropTypes.string
}

BenchmarksTable.propTypes = benchmarkTableProps

export default BenchmarksTable
