import React from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import SydButton from '../../../../commonDesign/Button'
import { useDownloadDocument } from '../../hooks'
import { ICON_NAMES } from '../../../../../constants'
import { defaultDocumentDetailsLabel } from '../../constants'
import { useVaultContext } from '../../VaultContextProvider'

const DocumentDownloadCell = ({ cell }) => {
  const { labels } = useVaultContext()
  const { isLoadingDownload, getDownload } = useDownloadDocument(cell.value)

  if (!cell.value) return ''

  return (
    <Box style={{ marginLeft: 0 }}>
      <SydButton
        size='sm'
        icon={ICON_NAMES.download}
        onClick={(e) => {
          e.stopPropagation()
          getDownload()
        }}
        disabled={isLoadingDownload}
      >
        {isLoadingDownload ? 'Loading...' : labels?.documentDetails?.downloadButton ?? defaultDocumentDetailsLabel.downloadButton}
      </SydButton>
    </Box>
  )
}

DocumentDownloadCell.propTypes = {
  cell: PropTypes.object
}
export default DocumentDownloadCell
