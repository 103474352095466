import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import noop from 'lodash/noop'
import RoundedButton from '../atoms/RoundedButton'
import { BUTTON_SIZES, ICON_NAMES } from '../../constants'
import Text from '../atoms/Text'
import Icon from '../atoms/Icon'
import { useAppContext } from '../../redux/slices/appContext'
import { useToggle } from '../../hooks'
import DocumentVaultFileUpload from '../organisms/DocumentVault/VaultFileUpload'
import Modal from './Modal'
import AttachmentItem from './AttachmentItem'

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1rem',
    padding: '0.5rem 0rem'
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'end'
  },
  buttonIcon: {
    display: 'flex',
    marginRight: '0.25rem'
  },
  previewsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  modalContainer: {
    padding: '1.5rem 1rem',
    overflow: 'auto',
    minWidth: '60vw',
    height: '70vh',
    borderRadius: '0.5rem'
  }
}))

const AttachmentList = ({
  size,
  label,
  readOnly,
  documents,
  onAddDocument,
  onRemoveDocument
}) => {
  const classes = useStyles()
  const appContext = useAppContext()
  const [toggleFileUploadModal, , toggleModalOn, toggleModalOff] = useToggle()

  const renderFilePreviews = useMemo(() => {
    return documents.map((documentId) => (
      <AttachmentItem
        key={documentId}
        readOnly={readOnly}
        documentId={documentId}
        onDelete={() => onRemoveDocument({ id: documentId })}
      />
    ))
  }, [documents, readOnly, onRemoveDocument])

  const onFinishDocumentUpload = useCallback(
    (uploadedDocuments) => {
      onAddDocument(uploadedDocuments.map(({ id }) => id))
      toggleModalOff()
    },
    [toggleModalOff, onAddDocument]
  )

  return (
    <div className={classes.container}>
      <Modal
        open={toggleFileUploadModal}
        containerClassName={classes.modalContainer}
      >
        <DocumentVaultFileUpload
          showBackButton
          backButtonLabel='Back to Milestones'
          onBackClick={toggleModalOff}
          clientId={appContext.clientId}
          onFinish={onFinishDocumentUpload}
        />
      </Modal>
      <div className={classes.previewsContainer}>{renderFilePreviews}</div>
      {!readOnly && (
        <RoundedButton
          size={size}
          onClick={toggleModalOn}
          className={classes.button}
        >
          <div className={classes.buttonContent}>
            <div className={classes.buttonIcon}>
              <Icon
                color='#212945'
                customSize='1rem'
                name={ICON_NAMES.downloadView}
              />
            </div>
            <Text
              text={label}
              lineHeight='1'
              customFontWeight='bold'
              customFontSize='inherit'
            />
          </div>
        </RoundedButton>
      )}
    </div>
  )
}

AttachmentList.propTypes = {
  label: PropTypes.string,
  documents: PropTypes.array,
  onAddDocument: PropTypes.func,
  onRemoveDocument: PropTypes.func,
  readOnly: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES))
}

AttachmentList.defaultProps = {
  label: 'Attach File',
  documents: [],
  onAddDocument: noop,
  onRemoveDocument: noop,
  readOnly: false,
  size: BUTTON_SIZES.extraSmall
}

export default AttachmentList
