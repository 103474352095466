import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import {
  useGetLevelDatapointSchema,
  useGetLevelDatapointValues,
  useModifyLevelKeyDatapointValues
} from '../../../../../../api/groups'
import { useAssetDetails, useSectionEditing } from '../../AssetProvider'
import FadeIn from '../../../../../molecules/Transitions/FadeIn'
import SectionScreen from '../../../shared/SectionScreen'
import SectionHeader from '../../../shared/SectionHeader'
import EditButton from '../../../shared/EditButton'
import KdpDisplay from '../../../shared/KdpControls/KdpDisplay'
import PersonalSpace from '../../../shared/PersonalSpace'
import SaveCancelFooter from '../../../shared/SaveCancelFooter'
import Loading from '../../../../../molecules/Loading'

const useStyles = makeStyles((theme) => ({
  kdpSection: {
    position: 'relative',
    padding: '10px'
  },
  loading: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function KdpSection () {
  const classes = useStyles()
  const { asset, editSection, cancelEdit } = useAssetDetails()
  const editing = useSectionEditing('kdp')
  const { data, isLoading, isFetching } = useGetLevelDatapointValues(3, asset.assetId)
  const { data: schema, isLoading: isSchemaLoading } = useGetLevelDatapointSchema(3)
  const formRef = useRef()
  const [submitting, setSubmitting] = useState(false)
  const { mutateAsync: modifyKdps } = useModifyLevelKeyDatapointValues()
  const onSave = useCallback(async (e) => {
    const onValid = async (formData) => {
      const newValues = schema.schema.filter(s => !s.hidden).map(s => {
        const value = ['', undefined].includes(formData[s.codeName]) ? null : formData[s.codeName]
        switch (s.configurationJson?.type) {
          case 'string':
          case 'text':
          case 'list':
            return { ...s, value }
          case 'integer':
            return { ...s, value: value === null ? null : Number.parseInt(value) }
          case 'fixed':
            return { ...s, value: value === null ? null : Number.parseFloat(value).toFixed(s.configurationJson.length ?? 2) }
          case 'number':
            return { ...s, value: value === null ? null : Number.parseFloat(value) }
          case 'bool':
            return { ...s, value: value === null ? null : Boolean(value) }
          case 'json':
            return { ...s, value: value === null ? null : JSON.parse(value) }
          case 'date':
            return { ...s, value: value === null ? null : dayjs(value).format('YYYY-MM-DD') }
          case 'datetime':
            return { ...s, value: value === null ? null : dayjs(value).toISOString() }
          default:
            return { ...s, value }
        }
      })
      const changed = newValues.reduce((prev, cur) => {
        if (cur.readonly) return prev
        const match = data.values.find(x => x.codeName === cur.codeName)
        if (cur.configurationJson?.type === 'json') {
          const left = match.value ? JSON.stringify(match.value) : null
          const right = match.value ? JSON.stringify(match.value) : null
          if (left !== right) {
            prev.push(cur)
          }
        } else if (match.value !== cur.value) {
          prev.push(cur)
        }
        return prev
      }, [])

      if (!changed.length) {
        console.warn('nothing to save')
        return
      }

      try {
        setSubmitting(true)
        await modifyKdps({
          levelTypeId: 3,
          levelId: asset.assetId,
          datapoints: changed
        })
        cancelEdit()
      } finally {
        setSubmitting(false)
      }
    }

    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = formRef.current.form.handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [asset, formRef, modifyKdps, schema, data, setSubmitting, cancelEdit])

  if (isLoading || isSchemaLoading || isFetching) {
    return (
      <FadeIn className={classes.loading}>
        <Loading />
      </FadeIn>
    )
  }

  return (
    <>
      <SectionScreen sectionName='kdp' className={classes.kdpSection}>
        <SectionHeader text='Key Datapoint Values'>
          <div>
            <EditButton
              policy='admin_edit_key_datapoints'
              editing={editing}
              onClick={() => editSection({
                section: 'kdp',
                saveDescription: 'Save Key Datapoint Values',
                onSave
              })}
            />
          </div>
        </SectionHeader>
        <KdpDisplay
          ref={formRef}
          datapoints={data.values}
          schema={schema.schema}
          editing={editing}
        />
        <PersonalSpace />
      </SectionScreen>
      {editing ? (
        <SaveCancelFooter
          onSave={onSave}
          onCancel={cancelEdit}
          processing={submitting}
          saveText='Save Key Datapoint Values'
        />) : null}
    </>
  )
}

export default KdpSection
