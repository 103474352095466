import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Typography } from '@material-ui/core'
import SydButton from '../../../../commonDesign/Button'
import SydModalActions from '../../../../commonDesign/SydModal/SydModalActions'
import { useAssociateImage, useImageUpload, useListImages } from '../../../../../api/media'
import ImageList from '../ImageList'
import Loading from '../../../../molecules/Loading'
import Card from '../../../../molecules/Card'
import ImageInput from '../ImageInput'
import SectionScreen from '../../shared/SectionScreen'
import FlexRow from '../../../../molecules/FlexRow'

const useSubmitter = (onComplete, purpose, levelTypeId, levelId, selectedImage, newFile) => {
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: associateImage } = useAssociateImage()
  const { mutateAsync: uploadImage } = useImageUpload({ waitForProcessingComplete: true })

  const onAttach = useCallback(async () => {
    const command = {
      imageId: selectedImage?.imageId,
      levelTypeId: levelTypeId,
      levelId: levelId,
      purposeId: purpose.purposeId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await associateImage(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to upload image')
      }
      onComplete?.(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [associateImage, setProcessing, onComplete, setError, purpose, levelTypeId, levelId, selectedImage])

  const onUploadAttach = useCallback(async () => {
    const uploadCommand = {
      fileName: newFile.name,
      displayName: newFile.displayName,
      file: newFile.file
    }

    try {
      setError(null)
      setProcessing(true)
      const uploadResult = await uploadImage(uploadCommand)
      if (uploadResult?.statusCode === 500) {
        throw new Error('Failed to upload image')
      }

      const imageId = uploadResult.image.imageId
      const attachCommand = {
        imageId,
        levelTypeId: levelTypeId,
        levelId: levelId,
        purposeId: purpose.purposeId
      }

      const attachResult = await associateImage(attachCommand)
      if (attachResult?.statusCode === 500) {
        throw new Error('Failed to attach image')
      }

      onComplete?.(attachResult)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [associateImage, uploadImage, setProcessing, onComplete, setError, purpose, levelTypeId, levelId, newFile])

  return {
    onAttach,
    onUploadAttach,
    processing,
    error
  }
}

function AttachImageForm ({ onCancel, onComplete, purpose, levelTypeId, levelId }) {
  const [selectedImage, setSelectedImage] = useState(null)
  const [newFile, setNewFile] = useState(null)
  const _setNewFile = useCallback((value) => {
    setSelectedImage(null)
    setNewFile(value)
  }, [setNewFile, setSelectedImage])
  const { onAttach, onUploadAttach, processing } = useSubmitter(onComplete, purpose, levelTypeId, levelId, selectedImage, newFile)
  const query = useMemo(() => {
    return {
      dimensions: purpose?.configuration?.dimensions
    }
  }, [purpose])
  const { data, isFetching } = useListImages(query)
  const requirementsMessage = useMemo(() => {
    if (isEmpty(purpose.configuration?.dimensions)) {
      return ''
    }
    const parts = []
    const dim = purpose.configuration.dimensions
    if (dim.minWidth && dim.maxWidth) {
      parts.push(`width between ${dim.minWidth} and ${dim.maxWidth} px`)
    } else if (dim.minWidth) {
      parts.push(`width at least ${dim.minWidth} px`)
    } else if (dim.maxWidth) {
      parts.push(`width at most ${dim.maxWidth} px`)
    }

    if (dim.minHeight && dim.maxHeight) {
      parts.push(`height between ${dim.minHeight} and ${dim.maxHeight} px`)
    } else if (dim.minHeight) {
      parts.push(`height at least ${dim.minHeight} px`)
    } else if (dim.maxHeight) {
      parts.push(`height at most ${dim.maxHeight} px`)
    }

    return `Images with ${parts.join('; ')}`
  }, [purpose])

  const addingImage = useMemo(() => !!newFile, [newFile])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={3}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h3'>Add an Image</Typography>
            </Grid>
            <Grid item xs={12}>
              <ImageInput value={newFile} onChange={_setNewFile} />
            </Grid>
            {addingImage ? (
              <Grid item xs={12}>
                <FlexRow style={{ justifyContent: 'flex-end' }}>
                  <SydButton variant='ghost' disabled={processing} onClick={() => setNewFile(null)}>Cancel Upload</SydButton>
                  <SydButton variant='primary' processing={processing} onClick={onUploadAttach}>Upload & Attach</SydButton>
                </FlexRow>
              </Grid>
            ) : null}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <SectionScreen editing={addingImage}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h3'>Media Library</Typography>
              </Grid>
              <Grid item xs={12}>
                {requirementsMessage}
              </Grid>
              <Grid item xs={12}>
                {isFetching ? (
                  <Loading />
                ) : (
                  <div style={{ overflowY: 'auto', maxHeight: '60vh', padding: '3px' }}>
                    <ImageList
                      images={data?.data || []}
                      selectedImage={selectedImage}
                      onSelect={setSelectedImage}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Card>
        </SectionScreen>
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            disabled={addingImage || !selectedImage}
            processing={!addingImage && processing}
            variant='primary'
            size='md'
            onClick={onAttach}
          >
            Attach
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

AttachImageForm.propTypes = {
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  purpose: PropTypes.object,
  levelTypeId: PropTypes.string,
  levelId: PropTypes.string
}

export default AttachImageForm
