import React, { useMemo } from 'react'
import { useAssetDetails } from '../../AssetProvider'
import LevelDetails from '../../../media/AssociationsTab/LevelDetails'
import { useListImagePurposes } from '../../../../../../api/media'
import Loading from '../../../../../molecules/Loading'

function ImageSection () {
  const { asset } = useAssetDetails()
  const { data: p, isLoading } = useListImagePurposes()
  const selectedLevel = useMemo(() => {
    return {
      levelTypeId: 3,
      levelId: asset.assetId
    }
  }, [asset])

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <LevelDetails level={selectedLevel} purposes={p?.purposes || []} hideHeader />
  )
}

export default ImageSection
