import React from 'react'
import { Box } from '@material-ui/core'
import Text from '../../../../atoms/Text'
import { ICON_NAMES, TEXT_VARIANTS } from '../../../../../constants'
import VaultWrapper from '../../../../organisms/DocumentVault/VaultWrapper'
import { useCheckPolicy } from '../../../../../hooks'

const VaultDocumentAdmin = () => {
  const userCanEdit = useCheckPolicy('vault_manage_firm_documents')
  return (
    <>
      <Box mt={2}>
        <Text variant={TEXT_VARIANTS.h1} text='Firm level documents' />
        <VaultWrapper
          padding='20px 0'
          includeRecentGroup={false}
          availableViews={[
            {
              key: 'list',
              label: 'List',
              icon: ICON_NAMES.listAlt,
              description: 'List view'
            },
            {
              key: 'grid',
              label: 'Grid',
              icon: ICON_NAMES.document,
              description: 'Grid view'
            }
          ]}
          uploadLevelTypeIds={userCanEdit ? [1000000] : []}
          levels={[{ levelTypeId: 1000000 }]}
          columns={['name', 'description', 'visibility', 'download', 'edit']}
        />
      </Box>
    </>
  )
}

export default VaultDocumentAdmin
