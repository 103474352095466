import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import get from 'lodash/get'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useFirmReportSummary } from '../../../api/coreData'
import Icon from '../../atoms/Icon'
import { useFormattingContext } from '../FormattingProvider/FormattingContext'
import { useDenaliFilter } from './filters/DenaliFilterContextProvider'

const useStyles = makeStyles((theme) => ({
  daMetric: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.layout.gap.tight,
    padding: '10px',
    '& .__title': {
      fontSize: theme.typography.fontSizes.xl
    },
    '& .__metric': {
      fontSize: theme.typography.fontSizes.h1
    },
    '& .__left': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: `${theme.layout.padding.none} ${theme.layout.padding.thick}`,
      fill: theme.palette.primary.main
    },
    '& .__right': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.layout.gap.tight
    }
  }
}))

function Metric ({ reportId, format, accessor, title, className, style, children, icon }) {
  const classes = useStyles()
  const { formatter } = useFormattingContext()
  const { membershipFilter, filter } = useDenaliFilter()
  const { data, isLoading } = useFirmReportSummary(reportId, { membershipFilter, filters: filter })

  const value = useMemo(() => {
    if (!data?.summary?.length) return null
    const dv = data.summary.reduce((p, c) => {
      p[c.key] = c
      return p
    }, {})
    return get(dv, accessor)
  }, [data, accessor])

  return (
    <div className={clsx(className, classes.daMetric)} style={style}>
      {icon ? (
        <div className='__left'>
          <Icon name={icon} customSize='50px' />
        </div>
      ) : null}
      <div className='__right'>
        <div className='__title'>{title}</div>
        {isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <div className='__metric'>{formatter(value, format)}</div>
        )}
        {children}
      </div>
    </div>
  )
}

Metric.propTypes = {
  reportId: PropTypes.string,
  accessor: PropTypes.string,
  title: PropTypes.string,
  format: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  icon: PropTypes.string
}

export default Metric
