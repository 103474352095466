import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useListTags } from '../../../../../api/documentVault'
import OperationTable from '../../../../organisms/OperationalTable'
import SydLabel from '../../../../commonDesign/SydLabel'
import SydInput from '../../../../commonDesign/SydInput'
import SydButton from '../../../../commonDesign/Button'
import { ICON_NAMES } from '../../../../../constants'
import { useCheckPolicy } from '../../../../../hooks'
import EditTagModal from './EditTagModal'
import TagActionsCell from './Cells/TagActionsCell'

const defaultColumnConfig = {
  columns: [
    { id: 'name', Header: 'Name', accessor: 'name' },
    { id: 'description', Header: 'Description', accessor: 'description' },
    { id: 'colorField', Header: 'Color', accessor: 'colorField' },
    { id: 'ordinal', Header: 'Ordinal', accessor: 'ordinal' },
    { id: 'documentCount', Header: 'Document Count', accessor: 'documentCount' },
    { id: 'edit', Header: 'Edit', accessor: 'id' }
  ],
  defaultSort: [{ id: 'name', desc: false }]
}

const VaultDocumentAdmin = ({
  columnsConfig = defaultColumnConfig,
  defaultPageSize = 100
}) => {
  const userCanEdit = useCheckPolicy('vault_manage_tags')

  const [searchString, setSearchString] = useState('')
  const [debouncedSearchString, setDebouncedSearchString] = useState(searchString)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sortOrder, setSortOrder] = useState(columnsConfig.defaultSort)
  const [showEditModal, setShowEditModal] = useState(null)

  const { data: tags, isFetching: isFetchingTags, refetch } = useListTags({
    ...(debouncedSearchString?.length ? {
      textSearch: { name: [{ op: 'contains', value: debouncedSearchString }] }
    } : {}),
    take: pageSize,
    includes: {
      documentCount: true
    },
    skip: page,
    orderBy: sortOrder.map((sort) => ({ field: sort.id, dir: sort.desc ? 'desc' : 'asc' }))
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearchString(searchString)
    }, 500)
    return () => clearTimeout(timeout)
  }, [searchString])

  const columns = useMemo(() => columnsConfig.columns
    .filter(c => c.id !== 'edit' || userCanEdit)
    .map((column) => {
      if (column.id === 'edit') {
        return {
          ...column,
          Cell: (props) => (
            <TagActionsCell onClick={(tag) => setShowEditModal(tag)} onFinish={refetch} {...props} />
          )
        }
      }
      return column
    }, [])
  , [columnsConfig.columns, refetch, userCanEdit])

  const onPagingChange = useCallback(({ pageIndex, pageSize }) => {
    setPage(pageIndex)
    setPageSize(pageSize)
  }, [])

  const onSortingChange = useCallback((sort) => {
    setSortOrder(sort)
  }, [])

  return (
    <>
      <Box mt={2}>
        <OperationTable.Wrapper>
          <OperationTable.SuperHeader>
            <Box display='flex' gridGap='8px' justifyContent='space-between' alignItems='center'>
              <SydLabel label='Search'>
                <SydInput
                  size='sm'
                  label='Search'
                  placeholder='Search'
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </SydLabel>
              {userCanEdit && (
                <SydButton
                  variant='primary'
                  onClick={() => setShowEditModal({ id: 'new' })}
                  icon={ICON_NAMES.add}
                >
                  Create New tag
                </SydButton>
              )}
            </Box>
          </OperationTable.SuperHeader>
          <OperationTable
            mode='server'
            columns={columns}
            data={tags?.tags ?? []}
            defaultPageSize={defaultPageSize}
            defaultSort={columnsConfig.defaultSort}
            itemName='Tags'
            loading={isFetchingTags}
            total={tags?.total ?? tags?.tags.length}
            onSortingChange={onSortingChange}
            onPagingChange={onPagingChange}
          />
        </OperationTable.Wrapper>
      </Box>

      {showEditModal && (
        <EditTagModal
          tag={showEditModal}
          onClose={() => setShowEditModal(null)}
          onFinish={async () => {
            setShowEditModal(null)
            await refetch()
          }}
        />
      )}
    </>
  )
}

VaultDocumentAdmin.propTypes = {
  columnsConfig: PropTypes.object,
  defaultPageSize: PropTypes.number
}

export default VaultDocumentAdmin
