import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ViewTabs from '../../../../organisms/AdvisorView/components/AdvisorViewTabs'
import { usePolicies } from '../../../../../hooks'
import IndexViewActions from './IndexViewActions'

const tabOptions = {
  images: {
    value: 'images',
    label: 'Image Library'
  },
  assocations: {
    value: 'associations',
    label: 'Associations'
  }
}

const useTabOptions = () => {
  const policies = usePolicies()
  return useMemo(() => {
    const result = {}
    if (policies.internalOnly) {
      result.images = tabOptions.images
      result.assocations = tabOptions.assocations
    }
    return result
  }, [policies])
}

function IndexViewTabs ({
  onTabChange,
  disabled,
  root,
  imageTab,
  associationsTab
}) {
  const tabs = useTabOptions()

  return (
    <div>
      <ViewTabs.Group tabOptions={tabs} onChange={onTabChange}>
        <div>
          <ViewTabs.Links
            disabled={disabled}
            tabsKey='admin_media_index'
            rightAdornment={<IndexViewActions root={root} />}
            noHistory
          />
        </div>
        {tabs.images ? (
          <ViewTabs.Tab value='images'>
            {imageTab}
          </ViewTabs.Tab>
        ) : null}
        {tabs.assocations ? (
          <ViewTabs.Tab value='assocations'>
            {associationsTab}
          </ViewTabs.Tab>
        ) : null}
      </ViewTabs.Group>
    </div>
  )
}

IndexViewTabs.propTypes = {
  onTabChange: PropTypes.func,
  disabled: PropTypes.bool,
  root: PropTypes.string,
  imageTab: PropTypes.node.isRequired,
  associationsTab: PropTypes.node.isRequired
}

IndexViewTabs.defaultProps = {
  onTabChange: () => {},
  disabled: false
}

export default IndexViewTabs
