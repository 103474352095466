import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import { Blade } from '../shared/Blade'
import OverrideList from './OverrideList'

const useStyles = makeStyles((theme) => ({
  overrides: {
    padding: theme.layout.padding.medium
  },
  actions: {
    padding: theme.layout.padding.medium,
    display: 'flex',
    flexDirection: 'row-reverse',
    borderBottom: `1px solid ${theme.palette.gray.darker}`
  }
}))

function AssetOverridesDetails ({ overrides }) {
  const classes = useStyles()
  if (!overrides) {
    return null
  }

  return (
    <Blade>
      <div className={classes.overrides}>
        {!overrides?.length ? (
          <div className='__no-content'>
            No Asset Overrides
          </div>
        ) : (
          <OverrideList
            overrides={overrides}
            display={a => (
              <>
                <Box display='flex' gridGap={8}>
                  <span>{a.accountNumber}</span>
                  <span>{a.accountName}</span>
                </Box>
                <Box display='flex' gridGap={8}>
                  <span>{a.assetIdentifier}</span>
                  <span>{a.assetName}</span>
                </Box>
              </>
            )}
          />
        )}
      </div>
    </Blade>
  )
}

AssetOverridesDetails.propTypes = {
  overrides: PropTypes.any
}

export default AssetOverridesDetails
