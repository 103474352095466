import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import SydButton from '../../../../../../commonDesign/Button'
import SydLabel, { hookFormFieldErrorAdapter } from '../../../../../../commonDesign/SydLabel'
import SydModalActions from '../../../../../../commonDesign/SydModal/SydModalActions'
import SydSelectBox from '../../../../../../commonDesign/SydSelectBox'
import SydInput from '../../../../../../commonDesign/SydInput'
import FlexRow from '../../../../../../molecules/FlexRow'
import { useClassifyAsset } from '../../../../../../../api/accounts'
import EmptySection from '../../../../../../atoms/EmptySection'

const useSubmitter = (form, onComplete, asset, classificationTagType) => {
  const { handleSubmit } = form
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const { mutateAsync: classifyAsset } = useClassifyAsset()
  const onSubmit = useCallback(async (formData) => {
    const command = {
      assetId: asset.assetId,
      classificationTagTypeId: classificationTagType.classificationTagTypeId,
      classificationTagId: formData.classificationTagId
    }

    try {
      setError(null)
      setProcessing(true)
      const result = await classifyAsset(command)
      if (result?.statusCode === 500) {
        throw new Error('Failed to set display name')
      }
      onComplete(result)
    } catch (err) {
      setError(err?.toString())
    } finally {
      setProcessing(false)
    }
  }, [classifyAsset, setProcessing, onComplete, asset, classificationTagType, setError])

  const submitter = useCallback(async (e) => {
    const onValid = async (form) => {
      await onSubmit(form)
    }
    const onInvalid = (errors) => {
      console.error(errors)
    }

    const handler = handleSubmit(onValid, onInvalid)
    await handler(e)
  }, [handleSubmit, onSubmit])

  const onClear = useCallback(async (e) => {
    return await onSubmit({ classificationTagId: null })
  }, [onSubmit])

  return {
    submitter,
    processing,
    error,
    onClear
  }
}

const labelAccessor = ['classificationTagId', 'longName', 'classificationType', 'ordinal']

function ModifyDisplayNameForm ({ asset, classificationTagType, onCancel, onComplete }) {
  const classificationTag = asset.classificationTags?.find?.(x => x.classificationTagTypeId === classificationTagType.classificationTagTypeId)
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      assetId: asset.assetId,
      classificationTagTypeId: classificationTagType.classificationTagTypeId,
      classificationTagId: classificationTag?.classificationTagId
    }
  })

  const options = useMemo(() => {
    const result = [...(classificationTagType?.classificationTags || [])]
    result.sort((a, b) => a?.ordinal - b?.ordinal)
    return result
  }, [classificationTagType])

  const { submitter, processing, error, onClear } = useSubmitter(form, onComplete, asset, classificationTagType)

  if (!options.length) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EmptySection title='No Options for Classification' description='' />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      {classificationTag ? (
        <Grid item xs={12}>
          <SydLabel label='Current Classification'>
            <FlexRow>
              <SydInput disabled value={classificationTag.longName} />
              {classificationTagType.required ? null : (
                <SydButton
                  size='md'
                  processing={processing}
                  priority='warning'
                  onClick={onClear}
                  icon='close'
                >
                  Remove Classification
                </SydButton>
              )}
            </FlexRow>
          </SydLabel>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Controller
          name='classificationTagId' control={form.control}
          render={(f => (
            <SydLabel label='Select a Classification' error={hookFormFieldErrorAdapter(f.fieldState)} required={!!classificationTagType.required}>
              <SydSelectBox
                autoFocus
                options={options}
                valueAccessor='classificationTagId'
                labelAccessor={labelAccessor}
                {...f.field}
              />
            </SydLabel>
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <SydModalActions>
          {error ? (<div className='__error'>{error}</div>) : null}
          <SydButton disabled={processing} variant='ghost' size='md' onClick={onCancel}>Cancel</SydButton>
          <SydButton
            variant='primary'
            size='md'
            onClick={submitter}
            disabled={!form.formState?.isValid}
            processing={processing}
          >
            Save Classification
          </SydButton>
        </SydModalActions>
      </Grid>
    </Grid>
  )
}

ModifyDisplayNameForm.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number,
    classificationTags: PropTypes.array
  }),
  classificationTagType: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default ModifyDisplayNameForm
