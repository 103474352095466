import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useWealthJourney } from '../WealthJourneyProvider'
import FadeIn from '../../../../molecules/Transitions/FadeIn'
import EmptySection from '../../../../atoms/EmptySection'
import Card from '../../../../molecules/Card'
import GenericEntryContent from './GenericEntryContent'

const useStyles = makeStyles(theme => ({
  entryContent: {
    '& .__tbp10': {
      paddingBottom: theme.layout.padding.p10
    }
  }
}))

function EntryContent ({ className, style, entryTypeIds, entryTypes, emptyMessage1, emptyMessage2 }) {
  const { selectedEntry, noEntries } = useWealthJourney()
  const classes = useStyles()

  if (noEntries) {
    return (
      <FadeIn className={clsx(className, classes.entryContent)} style={style}>
        <Card>
          <EmptySection description={emptyMessage2} title={emptyMessage1} />
        </Card>
      </FadeIn>
    )
  }

  if (!selectedEntry) {
    return (
      <FadeIn className={clsx(className, classes.entryContent)} style={style}>
        <Card>
          <EmptySection description='' title='No Entry Selected' />
        </Card>
      </FadeIn>
    )
  }

  if (entryTypeIds?.length && !entryTypeIds.includes(selectedEntry.entryTypeId)) {
    return (
      <FadeIn className={clsx(className, classes.entryContent)} style={style}>
        <Card>
          <EmptySection description={emptyMessage2} title={emptyMessage1} />
        </Card>
      </FadeIn>
    )
  }
  if (entryTypes?.length && !entryTypes.includes(selectedEntry.entryType.entryTypeCode)) {
    return (
      <FadeIn className={clsx(className, classes.entryContent)} style={style}>
        <Card>
          <EmptySection description={emptyMessage2} title={emptyMessage1} />
        </Card>
      </FadeIn>
    )
  }

  return (
    <FadeIn key={selectedEntry?.entryId} className={clsx(className, classes.entryContent)} style={style}>
      <GenericEntryContent entry={selectedEntry} />
    </FadeIn>
  )
}

EntryContent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  entryTypeIds: PropTypes.arrayOf(PropTypes.number),
  entryTypes: PropTypes.arrayOf(PropTypes.string),
  emptyMessage1: PropTypes.string,
  emptyMessage2: PropTypes.string
}

EntryContent.defaultProps = {
  emptyMessage1: 'Nothing Here Yet!',
  emptyMessage2: 'Select or Add an entry to begin'
}

export default EntryContent
