import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import FadeIn from '../../molecules/Transitions/FadeIn'
import InlineDocumentVaultFileUpload from '../DocumentVault/VaultFileUpload/Inline'
import { useComments } from './CommentContext'
import Attachment from './Attachment'
import AddAttachment from './AddAttachment'
import DocumentSearch from './DocumentSearch'

const useStyles = makeStyles((theme) => ({
  panelBody: {
    marginTop: '5px',
    padding: '10px 1.5rem'
  },
  sparkRoot: {
    opacity: ({ isLoading }) => isLoading ? 1 : 0,
    transition: ({ isLoading }) => isLoading ? 'opacity .25s ease-in-out .25s' : 'opacity .1s ease-in-out',
    height: '2px'
  },
  noData: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px'
  }
}))

function AttachmentPanelBody () {
  const {
    attachmentDetails,
    attaching,
    clientId,
    stopAttaching,
    finishAttaching,
    levelTypeId,
    levelId
  } = useComments()
  const { attachments, isLoading } = attachmentDetails
  const classes = useStyles({ isLoading })

  if (isLoading) {
    return (
      <LinearProgress classes={{ root: classes.sparkRoot }} variant='indeterminate' />
    )
  }

  if (attaching === 'add') {
    return (
      <FadeIn className={classes.panelBody}>
        <InlineDocumentVaultFileUpload
          levelId={clientId}
          levelTypeId={201}
          onSave={(documents) => {
            return finishAttaching({
              levelTypeId,
              levelId,
              documents
            })
          }}
          onCancel={stopAttaching}
          saveText='Save & Attach'
        />
      </FadeIn>
    )
  }

  if (attaching === 'link') {
    return (
      <FadeIn className={classes.panelBody}>
        <DocumentSearch clientId={clientId} />
      </FadeIn>
    )
  }

  const data = attachments?.attachments
  if (!data?.length) {
    return (
      <>
        <FadeIn className={classes.panelBody}>
          <div className={classes.noData}>
            No Documents
          </div>
        </FadeIn>
        <AddAttachment />
      </>
    )
  }

  return (
    <>
      <FadeIn className={classes.panelBody}>
        {data.map(a => (
          <Attachment key={a.attachmentId} attachment={a} />
        ))}
      </FadeIn>
      <AddAttachment />
    </>
  )
}

AttachmentPanelBody.propTypes = {
}

export default AttachmentPanelBody
