import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  codeTag: {
    paddingTop: theme.layout.padding.p2,
    paddingRight: theme.layout.padding.p5,
    paddingBottom: theme.layout.padding.p2,
    paddingLeft: theme.layout.padding.p5,
    backgroundColor: theme.palette.gray.main,
    borderRadius: theme.layout.radius.tight,
    borderColor: theme.palette.gray.dark,
    fontSize: theme.typography.fontSizes.md,
    borderWidth: '1px',
    borderStyle: 'solid',
    userSelect: 'all'
  }
}))

function CodeTag ({ children, className, style }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.codeTag, className)} style={style}>{children}</div>
  )
}

CodeTag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default CodeTag
