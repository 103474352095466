import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    flex: 1
  },
  gridContainer: {
    display: 'flex',
    gridGap: '30px',
    width: '100%',
    flexWrap: 'wrap',
    margin: '10px 0 30px',
    '&.horizontalScroll': {
      flexWrap: 'nowrap',
      overflowX: 'auto'
    },
    '&.collapsed': {
      height: '230px',
      overflow: 'hidden'
    }
  },
  sectionTitle: {
    fontWeight: 600,
    padding: '2px 0 !important',
    fontSize: '1.65rem',
    textTransform: 'capitalize'
  },
  collapseButton: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '12px',
    '& svg': {
      marginRight: '6px',
      transition: 'transform 0.3s'
    }
  },
  collapseButtonActive: {
    '& svg': {
      transform: 'rotate(180deg)',
      transition: '0.3s'
    }
  },
  tableContainer: {
    width: '100%'
  },
  vaultListTable: {
    '& .__tr .__td': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .__tr.even .__td': {
      backgroundColor: '#ebebeb !important'
    }
  }
}))
